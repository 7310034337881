<template>
  <div class="relative max-w-6xl mx-auto md:px-8 xl:px-0">
    <div class="container mx-auto h-full flex flex-col pt-5">
      <div class="flex flex-row items-start justify-between">
        <div>
          <h3 class="font-semibold text-3xl text-branding">All Questions</h3>
          <p class="text-gray-800 font-medium">Configure all the questions here.</p>
        </div>
        <div class="flex flex-row items-start justify-end space-x-4">
          <button @click="addQuestion()" class="btn-primary">
            Add Question
          </button>
        </div>
      </div>

      <!--TABLE-->

      <div class="flex justify-end mt-8">
        <div class="relative flex flex-row items-center w-64">
          <input type="text" placeholder="Search question" v-model="q" class="p-2 pl-8 flex-grow focus:outline-none border-2 border-tertiary focus:border-branding px-3 py-2 rounded">
          <svg class="absolute left-0 ml-2 text-gray-600 w-5 h-5" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g fill="none">
              <path d="M0,0h24v24h-24Z"></path>
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15.7138,6.8382c2.45093,2.45093 2.45093,6.42467 0,8.8756c-2.45093,2.45093 -6.42467,2.45093 -8.8756,0c-2.45093,-2.45093 -2.45093,-6.42467 -8.88178e-16,-8.8756c2.45093,-2.45093 6.42467,-2.45093 8.8756,-8.88178e-16"></path>
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19,19l-3.29,-3.29"></path>
            </g>
          </svg>
        </div>
      </div>

      <div class=" h-full w-full">
        <div class="flex flex-col w-full">
          <div class="w-full">
            <div class="py-2 align-middle inline-block w-full">
              <div class="shadow-xl border-b border-gray-200 sm:rounded-xl mb-10">
                <div class="w-full divide-y">
                  <div class="bg-gray-50">
                    <div class="flex flex-row justify-start items-center">
                      <div class="px-6 py-3 text-left text-xs font-medium text-branding uppercase tracking-wider w-1/12">
                        #
                      </div>
                      <div class="px-6 py-3 text-left text-xs font-medium text-branding uppercase tracking-wider w-3/12">
                        Variable
                      </div>
                      <div class="px-6 py-3 text-left text-xs font-medium text-branding uppercase tracking-wider w-4/12">
                        Question
                      </div>
                      <div class="px-6 py-3 text-left text-xs font-medium text-branding uppercase tracking-wider w-2/12">
                        Type
                      </div>
                      <div class="relative px-6 py-3 w-2/12">
                        <span class="sr-only">Edit</span>
                      </div>
                    </div>
                  </div>
                  <div class="bg-white divide-y divide-gray-100">
                    <draggable v-model="questions" handle=".handle" v-bind="dragOptions" @end="endDragging">

                      <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                        <div class="hover:bg-gray-50 transition duration-200 flex flex-row justify-between items-center list-group-item relative" v-for="(qu, index) in questions" v-bind:key="qu._id" :class="{'bg-gray-50' : !(index%2 == 0)}">
                          <div class="flex flex-row items-center justify-start px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 overflow-hidden w-1/12">
                            #{{qu.rank}}
                          </div>
                          <div class="px-6 py-4 whitespace-nowrap text-xs text-gray-500 w-3/12">
                            {{qu.id}}
                          </div>
                          <div class="px-6 py-4 text-sm text-gray-600 font-medium w-4/12 ">
                            {{qu.textEN}}
                          </div>
                          <div class="px-6 py-4 whitespace-nowrap text-tiny text-gray-500 w-2/12">
                            <div class="capitalize">
                              {{qu.answerType}}
                            </div>
                          </div>
                          <div class="px-6 py-4 whitespace-nowrap text-right text-sm flex flex-row items-center justify-end font-medium w-2/12 space-x-2">

                            <button @click="editQuestion(qu)" type="button" class="bg-gray-100 rounded-full p-2 font-semibold text-gray-500 hover:bg-gray-200 group transition duration-500 flex flex-row items-center justify-start space-x-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                              <svg class="w-5 h-5 group-hover:text-gray-600 transition duration-500" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <!--Generated by IJSVG (https://github.com/iconjar/IJSVG)-->
                                <g fill="none">
                                  <path d="M0,0h24v24h-24Z"></path>
                                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17.54,10.12l-3.66,-3.66"></path>
                                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M6.251,21h-3.251v-3.251c0,-0.265 0.105,-0.52 0.293,-0.707l13.334,-13.335c0.391,-0.391 1.024,-0.391 1.414,-8.88178e-16l2.251,2.251c0.391,0.391 0.391,1.024 0,1.414l-13.334,13.335c-0.187,0.188 -0.442,0.293 -0.707,0.293Z"></path>
                                </g>
                              </svg>
                            </button>

                            <button @click="removeQuestion(qu, index)" type="button" class="bg-red-100 rounded-full p-2 font-semibold text-red-500 hover:bg-gray-200 group transition duration-500 flex flex-row items-center justify-start space-x-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                              <svg class="w-5 h-5 group-hover:text-red-600 transition duration-500" version="1.1" id="Layer_3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
                                <g>
                                  <rect fill="none" width="24" height="24" />
                                  <g>
                                    <path fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" d="M18,6v12.75 c0,1.243-1.027,2.25-2.269,2.25h-7.5C6.988,21,6,19.993,6,18.75V6" />
                                    <line fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" x1="19.5" y1="6" x2="4.5" y2="6" />
                                    <line fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" x1="10" y1="3" x2="14" y2="3" />
                                    <line fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" x1="14" y1="10" x2="14" y2="17" />
                                    <line fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" x1="10" y1="17" x2="10" y2="10" />
                                  </g>
                                </g>
                              </svg>
                            </button>

                            <button type="button" class="handle" style="cursor: grab">
                              <svg class="text-gray-400 w-6 h-6" id="Layer_3" data-name="Layer 3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <title>menu-burger</title>
                                <line x1="5.50034" y1="12" x2="18.5" y2="12" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                <line x1="5.50034" y1="16" x2="18.5" y2="16" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                <line x1="5.5" y1="8" x2="18.49966" y2="8" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                <rect width="24" height="24" fill="none" />
                              </svg>
                            </button>

                          </div>
                        </div>
                      </transition-group>
                    </draggable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--POPUP-->
    <div class="fixed top-0 left-0 w-screen h-full min-h-screen overflow-scroll bg-branding bg-opacity-50" style="z-index: 9999" v-if="popup == 'question'">
      <div class="absolute top-0 left-0 transition-opacity cursor-pointer w-full min-h-screen min-h-full" @click="closePopup()">
        <div class="absolute top-0 left-0 w-full h-full" @click="closePopup()"></div>
      </div>

      <div class="mt-20 flex items-center justify-center text-center sm:block sm:p-0 overflow-scroll w-full h-full">
        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-scroll shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-3xl w-full" role="dialog">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 w-full">
            <div class="sm:flex sm:items-start w-full">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">

                <div v-if="question._id">
                  <h3 class="text-4xl mt-8 leading-6 font-medium text-gray-900" id="modal-headline">Update question</h3>
                  <div class="mt-2">
                    <p class="text-sm leading-5 text-gray-500">Update a given question.</p>
                  </div>
                </div>

                <div v-else>
                  <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">Create new question</h3>
                  <div class="mt-2">
                    <p class="text-sm leading-5 text-gray-500">Give us some information so we can create a new question.</p>
                  </div>
                </div>

                <div class="mt-5 w-full">
                  <div>
                    <div class="mt-5 text-bold text-3xl">
                      General information
                    </div>
                    <div class="flex flex-row w-full space-x-2">
                      <div class="w-full">
                        <p class="text-sm leading-5 font-medium mb-1 text-gray-500">Type question</p>
                        <select v-model="question.answerType" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm">
                          <option value="text">Text (no question)</option>
                          <option value="boolean">Boolean (YES/NO)</option>
                          <option value="input">Input (single line)</option>
                          <option value="number">Number input (number)</option>
                          <option value="year">Year input (dropdown)</option>
                          <option value="percentage">Percentage input (%, min: 0, max: 100)</option>
                          <option value="money">Money input (dollar sign)</option>
                          <option value="email">Email input (email)</option>
                          <option value="textarea">Text input (multi line)</option>
                          <option value="dropdown">Dropdown</option>
                          <option value="7likert">7 point Likert Scale (legend top)</option>
                          <option value="5likert">5 point Likert scale (legend top)</option>
                          <option value="7likert_horizon">7 point Likert Scale (legend in question)</option>
                          <option value="5likert_horizon">5 point Likert scale (legend in question)</option>
                        </select>
                      </div>
                    </div>
                    <div class="flex flex-row w-full space-x-2">
                      <div class="w-full">
                        <p class="text-sm leading-5 font-medium mb-1 text-gray-500">Required</p>
                        <select v-model="question.isRequired" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm">
                          <option value="required">Required (you cannot continue)</option>
                          <option value="suggested">Strongly suggested (we notifity the user with "are you sure?")</option>
                          <option value="optional">No (You can easily skip this question)</option>
                        </select>
                      </div>
                    </div>
                    <div v-if="question.answerType !== 'text'">
                      <p class="text-sm leading-5 font-medium mb-1 text-gray-500">ID <br />
                        <small> (variable, used in the algorithms. LOWERCASE, NO SPACES eg. this_is_an_id)</small>
                      </p>
                      <input v-model="question.id" type="text" class="lowercase w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                    </div>
                  </div>

                  <div class="">
                    <div class="mt-5 text-bold text-3xl">
                      English
                    </div>
                    <div>
                      <p class="text-sm leading-5 font-medium mb-1 text-gray-500">Text (EN)</p>
                      <input v-model="question.textEN" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                    </div>

                    <div>
                      <p class="text-sm leading-5 font-medium mb-1 text-gray-500">Description (EN)</p>
                      <textarea v-model="question.descEN" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                    </div>
                    <div v-if="question.answerType !== 'text'">
                      <p class="text-sm leading-5 font-medium mb-1 text-gray-500">Placeholder EN</p>
                      <input v-model="question.placeholderEN" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                    </div>
                  </div>

                  <div class="">
                    <div class=" mt-5 text-bold text-3xl">
                      Nederlands
                    </div>
                    <div>
                      <p class="text-sm leading-5 font-medium mb-1 text-gray-500">Text (NL)</p>
                      <input v-model="question.textNL" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                    </div>
                    <div>
                      <p class="text-sm leading-5 font-medium mb-1 text-gray-500">Description (NL)</p>
                      <textarea v-model="question.descNL" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                    </div>
                    <div v-if="question.answerType !== 'text'">
                      <p class="text-sm leading-5 font-medium mb-1 text-gray-500">Placeholder (NL)</p>
                      <input v-model="question.placeholderNL" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                    </div>
                  </div>

                  <div class="">
                    <div class=" mt-5 text-bold text-3xl">
                      Français
                    </div>
                    <div>
                      <p class="text-sm leading-5 font-medium mb-1 text-gray-500">Text (FR)</p>
                      <input v-model="question.textFR" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                    </div>
                    <div>
                      <p class="text-sm leading-5 font-medium mb-1 text-gray-500">Description (FR)</p>
                      <textarea v-model="question.descFR" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                    </div>
                    <div v-if="question.answerType !== 'text'">
                      <p class="text-sm leading-5 font-medium mb-1 text-gray-500">Placeholder (FR)</p>
                      <input v-model="question.placeholderFR" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                    </div>
                  </div>

                  <div v-if="question.answerType == 'year' || question.answerType == 'number'">
                    <p class="text-sm leading-5 font-medium mb-1 text-gray-500">Value min <small>(if not set, there is no min)</small></p>
                    <input v-model="question.min" type="number" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                    <p class="text-sm leading-5 font-medium mb-1 text-gray-500">Value max <small>(if not set, there is no max)</small></p>
                    <input v-model="question.max" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                  </div>

                  <div v-if="question.answerType == 'dropdown'">
                    <p class="text-sm leading-5 font-medium mb-1 text-gray-500">Subquestions</p>
                    <span class="text-xs text-gray-400">IF the text field is empty, we remove the row.</span>
                    <table class="min-w-full divide-y divide-gray-200">
                      <thead class="bg-gray-50">
                        <tr>
                          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Text (NL)
                          </th>
                          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Text (EN)
                          </th>
                          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Text (FR)
                          </th>
                          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Value
                          </th>
                          <th scope="col" class="relative px-6 py-3">
                            <span class="sr-only">Delete</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <!-- Odd row -->
                        <tr v-for="(option, index) in question.options" v-bind:key="index" class="bg-white">
                          <td class="px-1 whitespace-nowrap text-sm font-medium text-gray-900" :class="index == 0 ? 'pt-4': ''">
                            <input v-model="option.textNL" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                          </td>
                          <td class="px-1 whitespace-nowrap text-sm text-gray-500" :class="index == 0 ? 'pt-4': ''">
                            <input v-model="option.textEN" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                          </td>
                          <td class="px-1 whitespace-nowrap text-sm text-gray-500" :class="index == 0 ? 'pt-4': ''">
                            <input v-model="option.textFR" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                          </td>
                          <td class="px-1 whitespace-nowrap text-sm text-gray-500" :class="index == 0 ? 'pt-4': ''">
                            <input v-model="option.value" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                          </td>
                          <td class="px-1 whitespace-nowrap text-right text-sm font-medium flex flex-row" :class="index == 0 ? 'pt-4': ''">
                            <button @click="removeSubQuestion(index)" class="rounded p-2 bg-red-600 text-white cursor-pointer">Delete</button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <button type="button" @click="question.options.push({})" class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-gray-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-gray-500 focus:outline-none focus:border-gray-700 focus:shadow-outline-gray transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                      Add option
                    </button>
                  </div>

                  <div v-if="question.answerType == '7anchored' || question.answerType == '7likert' || question.answerType == '5likert'  || question.answerType == '7likert_horizon' || question.answerType == '5likert_horizon'">
                    <p class="text-sm leading-5 font-medium mb-1 text-gray-500">Subquestions</p>
                    <span class="text-xs text-gray-400">IF the text field is empty, we remove the row. The ID field is the field that we use in the algorithm. It should be unique, lowercase and contains no space. An example is aaaa_1. An algorithm looks like total_age = aaa_1 + aaa_2. The value that we give to this variable is the value from the Likert scale.</span>
                    <table class="min-w-full divide-y divide-gray-200">
                      <thead class="bg-gray-50 ">
                        <tr>
                          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Id (see above)
                          </th>
                          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Text (NL)
                          </th>
                          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Text (EN)
                          </th>
                          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Text (FR)
                          </th>
                          <th scope="col" class="relative px-6 py-3">
                            <span class="sr-only">Delete</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody class="">
                        <!-- Odd row -->
                        <tr v-for="(option, index) in question.options" v-bind:key="index" class="bg-white">
                          <td class="px-1 whitespace-nowrap text-sm font-medium text-gray-900" :class="index == 0 ? 'pt-4': ''">
                            <input v-model="option.id" type="text" class="lowercase w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                          </td>
                          <td class="px-1 whitespace-nowrap text-sm font-medium text-gray-900" :class="index == 0 ? 'pt-4': ''">
                            <input v-model="option.textNL" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                          </td>
                          <td class="px-1 whitespace-nowrap text-sm text-gray-500" :class="index == 0 ? 'pt-4': ''">
                            <input v-model="option.textEN" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                          </td>
                          <td class="px-1 whitespace-nowrap text-sm text-gray-500" :class="index == 0 ? 'pt-4': ''">
                            <input v-model="option.textFR" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                          </td>
                          <td class="px-1 whitespace-nowrap text-right text-sm font-medium flex flex-row" :class="index == 0 ? 'pt-4': ''">
                            <button @click="removeSubQuestion(index)" class="rounded p-2 bg-red-600 text-white cursor-pointer">Delete</button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <button type="button" @click="question.options.push({})" class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-gray-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-gray-500 focus:outline-none focus:border-gray-700 focus:shadow-outline-gray transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                      Add option
                    </button>
                  </div>

                  <div v-if="question.answerType == '5likert' || question.answerType == '5likert_horizon'" class="mt-10">
                    <p class="text-sm leading-5 font-medium mb-1 text-gray-500">Legend (EN)</p>
                    <p class="text-xs text-gray-400">Define your legend here. Leave empty to NOT show anything.</p>
                    <div class="flex flex-col">
                      <div>
                        <div class="text-xs text-gray-400">Label 1 (eg. Very unsatisfied)</div>
                        <input v-model="question.label1" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                      </div>
                      <div>
                        <div class="text-xs text-gray-400">Label 2 (eg. Unsatisfied)</div>
                        <input v-model="question.label2" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                      </div>
                      <div>
                        <div class="text-xs text-gray-400">Label 3 (eg. Neutral)</div>
                        <input v-model="question.label3" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                      </div>
                      <div>
                        <div class="text-xs text-gray-400">Label 4 (eg. Satisfied)</div>
                        <input v-model="question.label4" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                      </div>
                      <div>
                        <div class="text-xs text-gray-400">Label 5 (eg. Very satisfied)</div>
                        <input v-model="question.label5" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                      </div>
                    </div>
                    <p class="text-sm leading-5 font-medium mb-1 text-gray-500">Legend (NL)</p>
                    <p class="text-xs text-gray-400">Define your legend here. Leave empty to NOT show anything.</p>
                    <div class="flex flex-col">
                      <div>
                        <div class="text-xs text-gray-400">Label 1 (eg. Very unsatisfied)</div>
                        <input v-model="question.label1NL" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                      </div>
                      <div>
                        <div class="text-xs text-gray-400">Label 2 (eg. Unsatisfied)</div>
                        <input v-model="question.label2NL" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                      </div>
                      <div>
                        <div class="text-xs text-gray-400">Label 3 (eg. Neutral)</div>
                        <input v-model="question.label3NL" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                      </div>
                      <div>
                        <div class="text-xs text-gray-400">Label 4 (eg. Satisfied)</div>
                        <input v-model="question.label4NL" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                      </div>
                      <div>
                        <div class="text-xs text-gray-400">Label 5 (eg. Very satisfied)</div>
                        <input v-model="question.label5NL" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                      </div>
                    </div>
                    <p class="text-sm leading-5 font-medium mb-1 text-gray-500">Legend (FR)</p>
                    <p class="text-xs text-gray-400">Define your legend here. Leave empty to NOT show anything.</p>
                    <div class="flex flex-col">
                      <div>
                        <div class="text-xs text-gray-400">Label 1 (eg. Very unsatisfied)</div>
                        <input v-model="question.label1FR" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                      </div>

                      <div>
                        <div class="text-xs text-gray-400">Label 2 (eg. Unsatisfied)</div>
                        <input v-model="question.label2FR" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                      </div>

                      <div>
                        <div class="text-xs text-gray-400">Label 3 (eg. Neutral)</div>
                        <input v-model="question.label3FR" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                      </div>

                      <div>
                        <div class="text-xs text-gray-400">Label 4 (eg. Satisfied)</div>
                        <input v-model="question.label4FR" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                      </div>

                      <div>
                        <div class="text-xs text-gray-400">Label 5 (eg. Very satisfied)</div>
                        <input v-model="question.label5FR" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                      </div>
                    </div>
                  </div>

                  <div v-if="question.answerType == '7likert_horizon'" class="mt-10">
                    <p class="text-sm leading-5 font-medium mb-1 text-gray-500">Legend</p>
                    <p class="text-xs text-gray-400">Define your legend here. Leave empty to NOT show anything.</p>
                    <div class="flex flex-col">
                      <div>
                        <div class="text-xs text-gray-400">Label 1 (left) (eg. Very unsatisfied)</div>
                        <input v-model="question.label1" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                      </div>
                      <div>
                        <div class="text-xs text-gray-400">Label 2 (right) (eg. Very satisfied)</div>
                        <input v-model="question.label2" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                      </div>
                    </div>
                    <p class="text-sm leading-5 font-medium mb-1 text-gray-500">Legend (NL)</p>
                    <p class="text-xs text-gray-400">Define your legend here. Leave empty to NOT show anything.</p>
                    <div class="flex flex-col">
                      <div>
                        <div class="text-xs text-gray-400">Label 1 (left) (eg. Very unsatisfied)</div>
                        <input v-model="question.label1NL" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                      </div>
                      <div>
                        <div class="text-xs text-gray-400">Label 2 (right) (eg. Very satisfied)</div>
                        <input v-model="question.label2NL" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                      </div>
                    </div>

                    <p class="text-sm leading-5 font-medium mb-1 text-gray-500">Legend (FR)</p>
                    <p class="text-xs text-gray-400">Define your legend here. Leave empty to NOT show anything.</p>
                    <div class="flex flex-col">
                      <div>
                        <div class="text-xs text-gray-400">Label 1 (left) (eg. Very unsatisfied)</div>
                        <input v-model="question.label1FR" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                      </div>
                      <div>
                        <div class="text-xs text-gray-400">Label 2 (right) (eg. Very satisfied)</div>
                        <input v-model="question.label2FR" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                      </div>
                    </div>
                  </div>

                  <div v-if="question.answerType == '7likert'" class="mt-10">
                    <p class="text-sm leading-5 font-medium mb-1 text-gray-500">Legend (EN)</p>
                    <p class="text-xs text-gray-400">Define your legend here. Leave empty to NOT show anything.</p>
                    <div class="flex flex-col">
                      <div>
                        <div class="text-xs text-gray-400">Label 1 (eg. Strongly disagree)</div>
                        <input v-model="question.label1" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                      </div>
                      <div>
                        <div class="text-xs text-gray-400">Label 2 (eg. Disagree)</div>
                        <input v-model="question.label2" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                      </div>
                      <div>
                        <div class="text-xs text-gray-400">Label 3 (eg. More or less disagree)</div>
                        <input v-model="question.label3" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                      </div>
                      <div>
                        <div class="text-xs text-gray-400">Label 4 (eg. Undecided)</div>
                        <input v-model="question.label4" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                      </div>
                      <div>
                        <div class="text-xs text-gray-400">Label 5 (eg. More or less agree)</div>
                        <input v-model="question.label5" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                      </div>
                      <div>
                        <div class="text-xs text-gray-400">Label 6 (eg. Agree)</div>
                        <input v-model="question.label6" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                      </div>
                      <div>
                        <div class="text-xs text-gray-400">Label 7 (eg. Strongly agree)</div>
                        <input v-model="question.label7" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                      </div>
                    </div>

                    <p class="text-sm leading-5 font-medium mb-1 text-gray-500">Legend (NL)</p>
                    <p class="text-xs text-gray-400">Define your legend here. Leave empty to NOT show anything.</p>
                    <div class="flex flex-col">
                      <div>
                        <div class="text-xs text-gray-400">Label 1 (eg. Strongly disagree)</div>
                        <input v-model="question.label1NL" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                      </div>

                      <div>
                        <div class="text-xs text-gray-400">Label 2 (eg. Disagree)</div>
                        <input v-model="question.label2NL" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                      </div>

                      <div>
                        <div class="text-xs text-gray-400">Label 3 (eg. More or less disagree)</div>
                        <input v-model="question.label3NL" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                      </div>

                      <div>
                        <div class="text-xs text-gray-400">Label 4 (eg. Undecided)</div>
                        <input v-model="question.label4NL" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                      </div>

                      <div>
                        <div class="text-xs text-gray-400">Label 5 (eg. More or less agree)</div>
                        <input v-model="question.label5NL" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                      </div>

                      <div>
                        <div class="text-xs text-gray-400">Label 6 (eg. Agree)</div>
                        <input v-model="question.label6NL" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                      </div>

                      <div>
                        <div class="text-xs text-gray-400">Label 7 (eg. Strongly agree)</div>
                        <input v-model="question.label7NL" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                      </div>
                    </div>

                    <p class="text-sm leading-5 font-medium mb-1 text-gray-500">Legend (FR)</p>
                    <p class="text-xs text-gray-400">Define your legend here. Leave empty to NOT show anything.</p>
                    <div class="flex flex-col">
                      <div>
                        <div class="text-xs text-gray-400">Label 1 (eg. Strongly disagree)</div>
                        <input v-model="question.label1FR" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                      </div>

                      <div>
                        <div class="text-xs text-gray-400">Label 2 (eg. Disagree)</div>
                        <input v-model="question.label2FR" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                      </div>

                      <div>
                        <div class="text-xs text-gray-400">Label 3 (eg. More or less disagree)</div>
                        <input v-model="question.label3FR" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                      </div>

                      <div>
                        <div class="text-xs text-gray-400">Label 4 (eg. Undecided)</div>
                        <input v-model="question.label4FR" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                      </div>

                      <div>
                        <div class="text-xs text-gray-400">Label 5 (eg. More or less agree)</div>
                        <input v-model="question.label5FR" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                      </div>

                      <div>
                        <div class="text-xs text-gray-400">Label 6 (eg. Agree)</div>
                        <input v-model="question.label6FR" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                      </div>

                      <div>
                        <div class="text-xs text-gray-400">Label 7 (eg. Strongly agree)</div>
                        <input v-model="question.label7FR" type="text" class="w-full border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <div class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
              <div v-if="question._id" @click="updateQuestion()" class="cursor-pointer inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-gray-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-gray-500 focus:outline-none focus:border-gray-700 focus:shadow-outline-gray transition ease-in-out duration-150 sm:text-sm sm:leading-5">Update
                record
              </div>
              <div v-else @click="createQuestion()" class="cursor-pointer inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-gray-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-gray-500 focus:outline-none focus:border-gray-700 focus:shadow-outline-gray transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                Create record
              </div>
            </div>
            <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
              <button @click="popup=false;" type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-gray-300 focus:shadow-outline-gray transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                Cancel
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import api from "@/helpers/api";
import draggable from "vuedraggable";

export default {
  name: "Questions",
  data() {
    return {
      survey: {},
      questions: [],
      q: "",
      drag: false,
      popup: false,
      dragOptions: {
        animation: 200,
        group: "description",
        disabled: false
      }
    };
  },
  components: {
    draggable
  },
  methods: {
    closePopup() {
      this.popup = false;
    },
    async load() {
      this.questions = await api.get(
        `/questions?survey=${this.$route.params.id}`
      );
      this.survey = await api.get(`/surveys/${this.$route.params.id}`);
      console.log(this.questions);
    },
    endDragging: async function() {
      // Save all questions
      for (const index in this.questions) {
        if (!this.questions[index].rank) {
          await api.put(`/surveys/${this.questions[index]._id}`, { rank: 99 });
          break;
        }
        if (this.questions[index].rank.toString() !== index.toString()) {
          this.questions[index].rank = index;
          await api.put(`/surveys/${this.questions[index]._id}`, {
            rank: index
          });
        }
      }
      await this.load();
    },
    removeSubQuestion: function(index) {
      this.question.options.splice(index, 1);
    },
    addQuestion: function() {
      this.question = {
        options: [],
        survey: this.survey._id
      };
      this.popup = "question";
    },
    async createQuestion() {
      this.descending = false;
      this.checked = false;

      if (
        this.question.answerType == "7likert" &&
        this.question.answerType == "5likert"
      ) {
        if (this.descending) {
          this.orderLetter = "D";
        } else {
          this.orderLetter = "A";
        }
        this.question.legend = `${this.legendSelected}${this.orderLetter}`;
      }
      if (this.textType != "") {
        this.question.textType = this.textType;
      }
      if (this.checked == false) {
        this.question.isChecked = this.checked;
      }

      // Cleanup empty questions
      if (this.question.options) {
        const options = [];
        for (var i = 0; i < this.question.options.length; i++) {
          if (this.question.options[i].textEN) {
            options.push(this.question.options[i]);
          }
        }
        this.question.options = options;
      }

      this.question.survey = this.survey._id;

      await api.post("/questions", this.question);
      this.popup = false;
      this.question = {};
      await this.load();
    },
    async updateQuestion() {
      this.question.survey = this.survey._id;
      try {
        await api.put(`/questions/${this.question._id}`, this.question);
        this.question = {};
        this.popup = false;
        await this.load();
      } catch (err) {
        alert(err.response.data.error);
      }
    },
    async removeQuestion(question) {
      if (confirm("Are you sure you want to delete this question?")) {
        await api.delete(`/questions/${question._id}`);
        this.question = {};
        this.popup = false;
        await this.load();
      }
    },
    editQuestion(question) {
      this.question = question;
      if (!this.question.descEN) {
        this.question.descEN = "";
      }
      if (this.question.legend != undefined) {
        if (this.question.legend.charAt(1) == "D") {
          this.descending = true;
        } else {
          this.descending = false;
        }
      }
      if (this.question.answerType != "text") {
        this.question.textType = undefined;
      }

      this.popup = "question";
    },

    async selectedQuestions(question) {
      this.question = question;
      await api.put(`/questions/${this.question._id}`, this.question);
      this.question = {};
      this.popup = false;
    }
  },
  async mounted() {
    await this.load();
  }
};
</script>
