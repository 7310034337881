<template>
  <div class="w-full min-h-screen z-0">
    <main class="flex-1 overflow-y-auto focus:outline-none mt-5" tabindex="0">
      <div class="relative max-w-4xl mx-auto md:px-8 xl:px-0">
        <div class="mt-10 mb-20">
          <div class="space-y-1 flex items-start justify-between">
            <div>
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                Company details
              </h3>
              <p class="max-w-2xl text-sm text-gray-500">
                Give us all the required information that we need for
                administrative purposes.
              </p>
            </div>
            <div @click="save()" class="btn-primary">
              Save
            </div>
          </div>

          <div class="divide-y divide-gray-200">
            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">VAT/BTW number</dt>
              <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <input type="text" v-model="company.vat" class="flex-grow focus:outline-none bg-white px-3 py-2 rounded-lg" placeholder="BE00 000 000 000" />
              </dd>
            </div>

            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">Street + number</dt>
              <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <input type="text" v-model="company.street" class="flex-grow focus:outline-none bg-white px-3 py-2 rounded-lg" placeholder="Street 1" />
              </dd>
            </div>

            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">Postcode + city</dt>
              <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <input type="text" v-model="company.city" class="flex-grow focus:outline-none bg-white px-3 py-2 rounded-lg" placeholder="2000 Antwerpen" />
              </dd>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import auth from "@/helpers/auth";
import api from "@/helpers/api";

export default {
  name: "Dashboard",
  data() {
    return {
      popup: false,
      team: false,
      member: false,
      company: {
        teams: [],
        users: []
      },
      page: "general"
    };
  },
  methods: {
    async save(showAlert = true) {
      await api.put(`/companies/${this.$route.params.id}`, this.company);
      if (showAlert) alert("Project saved");
    },
    async load() {
      const data = await api.get(`/companies/${this.$route.params.id}`);
      this.company = data;
      if (!this.company.teams) this.company.teams = [];
      if (!this.company.users) this.company.users = [];
    }
  },
  async mounted() {
    this.user = await auth.me().catch(() => {});
    this.load();
  }
};
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.4s ease;
}

.slide-fade-leave-active {
  transition: all 0.4s ease;
}

.slide-fade-enter,
  .slide-fade-leave-to

  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}
</style>
