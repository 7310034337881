<template>
  <div>

    <!-- This example requires Tailwind CSS v2.0+ -->
<div v-if="editAnswer" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="cursor-pointer fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 overflow-y-auto">
    <div @click.self="editAnswer = false" class="cursor-pointer flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div class="cursor-default relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
        <div>
          <div class="mt-3 text-center sm:mt-5">
            <h3 class="text-lg leading-6 font-medium text-gray-900">Edit record</h3>
            <div class="mt-2">
              <div class="mb-4 text-sm text-gray-500">Edit the email for this participant. It will ONLY update the email for this survey. Please also update it on company level for further surveys.</div>

              <input v-model="editAnswer.email" type="text" placeholder="Email" class="mt-2 p-2 border rounded-md outline-none" />
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-6">
          <button @click="updateAnswer()" type="button" class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm">Update</button>
        </div>
      </div>
    </div>
  </div>
</div>


<div v-if="editTest" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="cursor-pointer fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 overflow-y-auto">
    <div @click.self="editTest = false" class="cursor-pointer flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div class="cursor-default relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
        <div>
          <div class="mt-3 text-center sm:mt-5">
            <h3 class="text-lg leading-6 font-medium text-gray-900">Edit test</h3>
            <div class="mt-2">

              <div class="text-sm font-medium">Einddatum {{endDate}}</div>
              <div class="mt-1 flex text-sm flex flex-row">
                <datepicker v-model="endDate" class="p-1 w-2/3 z-30" />
              </div>

            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-6">
          <button @click="updateTest()" type="button" class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm">Update</button>
        </div>
      </div>
    </div>
  </div>
</div>


  <div class="container bg-background mx-auto mt-16">

    <div class="bg-background  flex items-center space-x-2 cursor-pointer mb-4">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
      </svg>
      <router-link to="/">Go back</router-link>
    </div>

    <div v-if="user && (user.isAdmin || user.isGod)">
      <div class="bg-background  flex flex-col justify-between">
        <h2 class="font-semibold text-5xl text-branding">
          Test results for:
          <span class="ml-4 text-gray-800 text-4xl">{{ data.company.name }} - {{ data.target }}</span>
        </h2>
        <p class="text-gray-800 ml-2">
          This page gives you an overview for this specific test.
        </p>
      </div>

      <section v-if="user && (user.isGod || user.isAdmin)" class=" mt-8 bg-background">
        <h3 class="font-bold text-3xl">Insights</h3>
        <div class="grid sm:grid-cols-2 lg:grid-cols-3 gap-5 mt-2">
          <div class="bg-white shadow-md rounded-md p-4">
            <h4 class="font-bold text-xl">Participants</h4>
            <span class="flex items-end justify-end space-x-1 w-full">
              <p class="text-right text-3xl">{{ data.participants.length }}</p>
              <p class="text-gray-800 w-1/3">participants</p>
            </span>
          </div>

          <div class="bg-white shadow-md rounded-md p-4">
            <div class="flex flex-row items-center justify-start space-x-2">
              <div :class="{
                'bg-green-500':
                  data.remindersSent &&
                  data.remindersSent.includes('start') &&
                  (data.endDate ? new Date(data.endDate) : new Date(1, 1, 1)) >
                    new Date(),
              }" class="w-4 h-4 rounded-full bg-red-500"></div>
              <h4 class="font-bold text-xl">Active</h4>

              <div class="ml-auto">
                <div @click="editTest = true" class="ml-auto cursor-pointer underline mr-4 flex items-center space-x-2">
                  <p class="">
                    Change dates
                  </p>
                </div>
              </div>

            </div>
            <p class="text-right mr-4 text-3xl">
              {{ moment(data.startDate || data.createdAt).format("DD/MM") }} -
              {{
              (data.endDate && moment(data.endDate).format("DD/MM")) || "--/--"
            }}
            </p>
          </div>

          <div class="bg-white shadow-md rounded-md p-4">
            <div class="flex justify-between items-center">
              <h4 class="font-bold text-xl">Last reminder</h4>
              <router-link :to="`/results/${$route.params.id}/remind`" class="underline mr-4 flex items-center space-x-2">
                <p class="">
                  send reminder
                </p>
              </router-link>
            </div>
            <p class="text-right mr-4 text-3xl">
              {{
              (lastReminder && moment(lastReminder).format("DD/MM - HH:mm")) ||
              "--/-- - --:--"
            }}
            </p>
          </div>
          <div class="bg-white shadow-md rounded-md p-4">
            <h4 class="font-bold text-xl">Progress</h4>
            <div class="flex flex-col items-end">
              <span class="flex items-end justify-end space-x-1 w-full">
                <p class="text-right text-3xl">
                  {{ ingevuld }}/{{ data.participants.length }}
                </p>
                <p class="text-gray-800 w-1/3">participants</p>
              </span>
              <span class="flex items-end justify-end space-x-1 w-full">
                <p class="text-3xl">{{ ingevuldPercentage }}%</p>
                <p class="text-gray-800 w-1/3">answers</p>
              </span>
            </div>
          </div>
        </div>
      </section>

      <pre v-if="false">{{results}}</pre>
      <!--SURVEY ANSWERS-->
      <section v-for="survey in data.surveys" :key="survey._id" class=" my-8">

        <h3 class="font-bold text-3xl">{{ survey.name }}</h3>

        <!--CHARTS-->
        <div v-if="survey.charts && survey.charts.length > 0" class="grid grid-cols-3 mt-5 bg-gray-100 p-3 rounded-md shadow-md">
          <div v-for="chart in survey.charts" :key="chart._id">
            <h3 class="text-gray-800 text-3xl mb-2">{{chart.name}}</h3>
            <chart v-if="loaded" :data="chartData(chart, results)" :colors="chart.colors" :type="chart.chartType"></chart>
          </div>
        </div>

        <!--RAW ANSWERS-->
        <div v-if="user.isGod" class="overflow-x-scroll mt-2">

          <div class="flex my-2 justify-between">
            <h3 class="text-3xl text-gray-800">Answers</h3>
            <button @click="exportCsv(survey)" class="btn-primary">export csv</button>
          </div>
          <table class="border-branding border-2">
            <tr class="border-branding border">
              <th v-if="user && user.isGod && !user.demo" class="p-1 border border-branding font-bold">
                Automated mails
              </th>
              <th v-if="user && user.isGod && !user.demo" class="p-1 border border-branding font-bold">
                Email
              </th>
              <th class="p-1 border border-branding font-bold">Token</th>
              <th class="p-1 border border-branding font-bold">Result</th>
              <th class="p-1 border border-branding font-bold">Answers</th>
              <th class="p-1 border border-branding font-bold" v-for="item in questions(survey)" :key="item">
                {{ item }}
              </th>
            </tr>

            <tr class="" v-for="answer in data.answers.filter(el => !el.isTest)" v-bind:key="answer._id">
              <td v-if="user && user.isGod && !user.demo" class="p-1 text-center items-center flex justify-center">
                <button @click="toggleAutomatedMails(answer)" :class="[answer.automatedMails ? 'bg-branding' : 'bg-tertiary', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-branding']" type="button" role="switch" aria-checked="false" aria-labelledby="availability-label">
                  <span class="sr-only">Use setting</span>
                  <span aria-hidden="true" :class="[answer.automatedMails ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" class="translate-x-0 pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
                </button>

              </td>
              <td v-if="user && user.isGod && !user.demo" class="p-1 border border-branding">
                {{ answer.email }}
                <span class="mx-1 text-blue-800 cursor-pointer" @click="editAnswer = answer">(edit)</span>
              </td>
              <td class="p-1 border border-branding">{{ answer.token }}</td>
              <td class="p-1 border border-branding">

                <button v-if="results && results[survey._id] && hasReport(results[survey._id][answer.token], survey)" @click="getReport((results[survey._id])[answer.token], survey)" class="text-blue-500 underline">
                  {{ answers(answer, survey).length == questions(survey).length ? 'download' : 'no report' }}
                </button>
                <p v-else class="text-center">/</p>

              </td>
              <!--<td class="p-1 border">{{(Object.keys(answer.answers || {})).length}} / {{Math.max(0, Object.keys(answer.answers || {}).length - (rows.length - questions(survey).length))}}</td>-->
              <td class="p-1 border border-branding">
                {{ answers(answer, survey).length }} / {{ questions(survey).length }}
              </td>
              <td class="p-1 border border-branding" v-for="item in questions(survey)" v-bind:key="item">
                <div v-if="answer.answers && answer.answers[item]">
                  {{ answer.answers[item] }}
                </div>
              </td>
            </tr>
          </table>
          <div v-if="false">
            <h3 class="font-bold text-xl text-gray-500">Calculated results</h3>
            <pre>{{ results[survey._id] }}</pre>
            <button @click="recalculate">Recalculate</button>
          </div>

        </div>

        <!--RAW TEST ANSWERS-->
        <div v-if="user.isGod && data.answers.filter(el => el.isTest).length > 0" class="overflow-x-scroll mt-2">
          <h3 class="text-3xl text-gray-800">Test answers</h3>
          <table class="border-branding border-2">
            <tr class="border-branding border">
              <th v-if="user && user.isGod && !user.demo" class="p-1 border border-branding font-bold">
                Email
              </th>
              <th class="p-1 border border-branding font-bold">Token</th>
              <th class="p-1 border border-branding font-bold">Result</th>
              <th class="p-1 border border-branding font-bold">Answers</th>
              <th class="p-1 border border-branding font-bold" v-for="item in questions(survey)" :key="item">
                {{ item }}
              </th>
            </tr>

            <tr class="" v-for="answer in data.answers.filter(el => el.isTest)" v-bind:key="answer._id">
              <td v-if="user && user.isGod && !user.demo" class="p-1 border border-branding">
                {{ answer.email }}
              </td>
              <td class="p-1 border border-branding">{{ answer.token }}</td>
              <td class="p-1 border border-branding">

                <button v-if="results && results[survey._id] && hasReport(results[survey._id][answer.token], survey)" @click="getReport((results[survey._id])[answer.token], survey)" class="text-blue-500 underline">
                  {{ answers(answer, survey).length == questions(survey).length ? 'download' : 'no report' }}
                </button>
                <p v-else class="text-center">/</p>

              </td>
              <!--<td class="p-1 border">{{(Object.keys(answer.answers || {})).length}} / {{Math.max(0, Object.keys(answer.answers || {}).length - (rows.length - questions(survey).length))}}</td>-->
              <td class="p-1 border border-branding">
                {{ answers(answer, survey).length }} / {{ questions(survey).length }}
              </td>
              <td class="p-1 border border-branding" v-for="item in questions(survey)" v-bind:key="item">
                <div v-if="answer.answers && answer.answers[item]">
                  {{ answer.answers[item] }}
                </div>
              </td>
            </tr>
          </table>
          <div v-if="false">
            <h3 class="font-bold text-xl text-gray-500">Calculated results</h3>
            <pre>{{ results[survey._id] }}</pre>
            <button @click="recalculate">Recalculate</button>
          </div>
        </div>
      </section>
    </div>

    <div v-else>
      <div class="bg-background  flex flex-col justify-between">
        <h2 class="font-semibold text-5xl text-branding">
          Welcome {{user.firstname}}
        </h2>
        <div class=" text-gray-800 ">
          these are your results for:
          <span class="text-branding font-bold">{{ data.company.name }} - {{ data.target }}</span>
        </div>
      </div>

      <section v-for="survey in data.surveys" :key="survey._id" class="flex items-center space-x-2 mt-3">
        <h3 class="text-3xl">{{ survey.name }}:</h3>

        <button v-if="results && results[survey._id] && hasReport(results[survey._id][answer.token], survey)" @click="getReport((results[survey._id])[answer.token], survey)" class="text-blue-500 underline">
          {{ answers(answer, survey).length == questions(survey).length ? 'download' : 'no report' }}
        </button>
        <p v-else class="text-center">/</p>

      </section>
    </div>
  </div>
</div>
</template>

<script>
import auth from "@/helpers/auth";
import api from "@/helpers/api";

import env from "../../../env";

import chart from "@/components/charts/chart.vue";

export default {
  name: "Dashboard",
  components: {
    chart
  },
  data() {
    return {
      loaded: false,
      user: {
        isGod: false,
        isAdmin: false
      },
      data: {
        answers: [],
        participants: [],
        remindersSent: [],
        company: {
          name: ""
        }
      },
      test: false,
      results: {},
      editAnswer: false,
      editTest: false,
      endDate: false,
      endHour: false,
    };
  },
  methods: {
    updateAnswer: async function(){
      await api.put(`/tests/${this.$route.params.id}/answers/${this.editAnswer._id}/update-mail`, {
        email: this.editAnswer.email,
      });
      this.editAnswer = false;
      alert('Answer updated!')
    },
    updateTest: async function(){
      console.log('endDate:', this.endDate)
      await api.put(`/tests/${this.$route.params.id}`, {
        endDate: this.endDate,
      });

      this.editTest = false;
      this.data.endDate = this.endDate;
      alert('Test updated!')
    },
    async exportCsv(survey) {
      // generate the fields to be parsed
      const fields = ["token", "answers"].concat(this.questions(survey));
      // if the user is god, add the emails
      if (this.user.isGod) fields.unshift("email");

      // gather the data
      const data = [];
      this.data.answers
        .filter(el => !el.isTest)
        .forEach(el => {
          const row = {
            email: el.email,
            token: el.token,
            answers: `${this.answers(el, survey).length} / ${
              this.questions(survey).length
            }`
          };
          if (el.answers) {
            this.questions(survey).forEach(qid => {
              row[qid] = el.answers[qid] || "";
            });
          }
          data.push(row);
        });

      // generate the csv
      const res = await api.post("csv", { fields, data });

      // download the csv as a file
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(new Blob([res]));
      link.setAttribute("download", "data.csv");
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
    async recalculate() {
      await this.load();
    },
    async load() {
      this.loaded = false;
      this.results = await api.get(`/tests/${this.$route.params.id}/calculate`);
      if (this.results.error) {
        alert(
          `Something went wrong while calculating the results:\n ${this.results.error}`
        );
        return this.$router.push("/results");
      }
      this.data = await api.get(`/tests/${this.$route.params.id}`);
      this.endDate = new Date(this.data.endDate);
      this.endHour = this.endDate.getHours()
      this.ids = this.createIdNameMap();
      this.loaded = true;
    },
    async remind() {
      await api.get(`/tests/${this.$route.params.id}/remind`);
    },
    /*
     * Map each question/calculation id to its name
     */
    createIdNameMap() {
      const map = {};
      // for each survey
      (this.data.surveys || []).forEach(survey => {
        // for each question
        (survey.questions || []).forEach(question => {
          if (!question.id) return;
          if (/likert/.test(question.answerType)) {
            // likert question
            (question.options || []).forEach(option => {
              map[option.id] =
                option[`text${this.user.language || "NL"}`] || option.id;
            });
          } else {
            // regular question
            map[question.id] =question[`text${this.user.language || "NL"}`] || question.id;
          }
        });
        (survey.algorithm || []).forEach(expression => {
          map[expression.id] = expression.name;
        });
      });
      return map;
    },
    questions(survey) {
      let questions = [];
      for (const question of survey.questions) {
        if (new RegExp("likert").test(question.answerType)) {
          questions = questions.concat(question.options.map(el => el.id));
        } else if (question.id) {
          questions.push(question.id);
        }
      }
      return questions;
    },
    answers(answer, survey) {
      const questionIds = this.questions(survey);
      return Object.keys(answer.answers || {}).filter(el =>
        questionIds.includes(el)
      );
    },
    /**
     * return the first report found in the results
     */
    async getReport(results, survey) {
      if (!results) return "";
      const reports = [];
      for (const result of Object.values(results)) {
        if (/report\(/.test(result)) {
          const reportId = result.replace(/("report\(|\)")/g, "");
          const pdf = await api.post(
            `/surveys/${survey._id}/reports/${reportId}/generate`,
            results
          );
          reports.push(pdf.url);
        }
      }
      if (results.length < 1) return "";
      const report = await api.post(env.pdfManager + "/merge", {
        pdfs: reports
      });
      window.open(report.url, "_blank");

      //const report = survey.reports.find(el => el._id === id);
      //return report["report" + (this.user.language || "NL")]; // translate it for the current user
    },
    hasReport(results, survey) {
      if (!results) return false;
      return Object.values(results).some(el => /report\(/.test(el));
    },
    /**
     * return a formatted data object for the charts
     * { name: value }
     */
    chartData(chart, results) {
      const obj = {};
      chart.fields.forEach(el => {
        el = el.replace("$", "");
        obj[this.ids[el]] = (results.globals || { el: undefined })[el];
      });
      return obj;
    },
    async toggleAutomatedMails(answer) {
      answer.automatedMails = !answer.automatedMails;
      await api.put(`/answers/${answer.token}`, answer);
    }
  },

  computed: {
    lastReminder() {
      const dates = this.data.remindersSent
        .map(el => new Date(el))
        .filter(el => el != "Invalid Date")
        .sort((a, b) => b - a);
      return dates[0];
    },
    rows: function() {
      for (var i = 0; i < this.data.answers.length; i++) {
        if (this.data.answers[i] && this.data.answers[i].answers) {
          let keys = Object.keys(this.data.answers[i].answers);
          keys.sort((a, b) => {
            // sort alphanumerically first, then by number
            const numbers = [
              parseInt(a.replace(/[^0-9]/gi, "")),
              parseInt(b.replace(/[^0-9]/gi, ""))
            ];
            const strings = [
              a.toLowerCase().replace(/[^a-z]/gi, ""),
              b.toLowerCase().replace(/[^a-z]/gi, "")
            ];
            if (strings[0] == strings[1]) return numbers[0] - numbers[1];
            return strings[1] - strings[0];
          });
          return keys;
        }
      }
      return [];
    },

    ingevuld() {
      let total = 0;
      let maxAmountOfAnswers = 1;

      // first find total amount of answers
      // (TODO: this should come from a survey, but hard part is subquestions)
      for (var i = 0; i < this.data.answers.length; i++) {
        if (this.data.answers[i] && this.data.answers[i].answers) {
          const amountOfAnswers = Object.keys(
            this.data.answers[i].answers || {}
          ).length;
          if (amountOfAnswers > maxAmountOfAnswers) {
            maxAmountOfAnswers = amountOfAnswers;
          }
        }
      }

      for (var x = 0; x < this.data.answers.length; x++) {
        if (this.data.answers[x] && this.data.answers[x]) {
          const amountOfAnswers = Object.keys(
            this.data.answers[x].answers || {}
          ).length;
          if (amountOfAnswers == maxAmountOfAnswers) {
            total += 1;
          }
        }
      }

      return total;
    },
    ingevuldPercentage: function() {
      return Math.round((100 / this.data.participants.length) * this.ingevuld);
    }
  },

  async mounted() {
    this.user = await auth.me().catch(() => {});

    this.session = auth.cookies.get("session");
    this.load();
  }
};
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.4s ease;
}

.slide-fade-leave-active {
  transition: all 0.4s ease;
}

.slide-fade-enter,
  .slide-fade-leave-to

  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}
</style>
