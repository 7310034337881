<template>
  <div v-if="datacollection && datacollection != null" class="w-full">
    <PieChart v-if="this.type == 'pie'" :chart-data="datacollection" :options="{...chartOptions.defaults, ...chartOptions.pie}" style=""></PieChart>
    <BarChart v-if="this.type == 'bar'" :chart-data="datacollection" :options="{...chartOptions.defaults, ...chartOptions.bar}" style=""></BarChart>
    <LineChart v-if="this.type == 'line'" :chart-data="datacollection" :options="{...chartOptions.defaults, ...chartOptions.line}" style=""></LineChart>
    <RadarChart v-if="this.type == 'radar'" :chart-data="datacollection" :options="{...chartOptions.defaults, ...chartOptions.radar}" style=""></RadarChart>
  </div>
</template>

<script>
import PieChart from "./pie.vue";
import RadarChart from "./radar.vue";
import BarChart from "./bar.vue";
import LineChart from "./line.vue";

export default {
  props: ["data", "colors", "type"],
  components: {
    PieChart,
    RadarChart,
    BarChart,
    LineChart
  },
  data() {
    return {
      datacollection: null,
      chartOptions: {
        defaults: {
          scales: {
            yAxes: [
              {
                display: true,
                stacked: true,
                ticks: {
                  min: 1,
                  max: 10
                }
              }
            ]
          }
        },
        line: {
          title: {
            display: false
          },
          legend: {
            display: false
          }
        },
        pie: {
          title: {
            display: false
          },
          legend: {
            display: false
          },

          tooltips: false,
          hover: { mode: null },
          legend: {
            position: "bottom"
          }
        },
        bar: {
          title: {
            display: false
          },
          legend: {
            display: false
          },

          responsive: true
        },
        radar: {
          title: {
            display: false
          },
          legend: {
            display: false
          },
          scale: {
            gridLines: {
              circular: true
            },
            ticks: {
              beginAtZero: true
            }
          }
        }
      }
    };
  },
  mounted() {
    this.fillData();
  },
  methods: {
    fillData() {
      if (this.type == "radar") {
        const zeroLine = [];
        for (var i = 0; i < Object.values(this.data).length; i++) {
          zeroLine.push(0);
        }
        this.datacollection = {
          labels: Object.keys(this.data),
          datasets: [
            {
              label: "Your data",
              data: Object.values(this.data),
              //fill: true,
              borderColor: "rgb(255, 99, 132)",
              pointBackgroundColor: "rgb(255, 99, 132)",
              pointBorderColor: "#fff",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "rgb(255, 99, 132)"
            }

            /*
              {
                label: '0-line',
                data: zeroLine,
                fill: false,
                lineTension: 0.25,
                borderColor: '#292c34bf',
              }
              */
          ]
        };
      } else {
        this.datacollection = {
          labels: Object.keys(this.data),
          datasets: [
            {
              backgroundColor: this.colors,
              data: Object.values(this.data),
              hoverOffset: 4
            }
          ]
        };
      }
    }
  }
};
/*
  Remove white line?
  Labels eronder?
*/
</script>

<style>
.small {
  max-width: 600px;
  margin: 150px auto;
}
</style>