<template>
  <div class="w-full min-h-full bg-background z-0 absolute inset-0">
    <div>
      <nav class="w-full bg-tertiary border-secondary border-b-8" style="z-index: 999">
        <div class="relative w-full z-50 flex flex-row justify-start items-center h-16 flex-shrink-0 bg-branding">
          <div class="container mx-auto h-16 flex flex-row items-center justify-between py-2 px-3 flex-shrink-0">
            <router-link to="/" class="px-4 py-4 flex items-center justify-center text-white font-semibold">
              <img class="w-32" src="@/assets/images/logo_oranje1.svg">
            </router-link>
            <div class="flex flex-row items-center justify-center gap-2">

            </div>
            <div class="flex flex-row items-center justify-end gap-2">

              <button class="flex flex-row items-center justify-end px-3 pl-5 py-2 rounded-full gap-3 transition duration-200 relative focus:outline-none focus:shadow-outline">
                <div>
                  <p id="username" class="text-sm text-white font-medium text-right leading-none">
                    Hi {{user.firstname}}
                  </p>
                </div>
                <!--SUB-->
              </button>
            </div>
          </div>
        </div>
      </nav>
    </div>

    <main class="flex-1 bg-background overflow-y-auto focus:outline-none mt-5 mb-20" tabindex="0">
      <div class="bg-background relative max-w-4xl mx-auto md:px-8 xl:px-0">
        <div class="mt-20 mb-10">
          <!--TITLES-->
          <div class="flex flex-col justify-between items-start">
            <h3 class="text-2xl leading-6 font-medium text-branding">
              Hello {{ user.firstname }},
            </h3>
            <p class="mt-2 max-w-2xl text-sm text-gray-800">
              Unsubscribing will remove your email from the mailing lists,
              you will no longer recieve emails from theforge.
            </p>
          </div>

          <div class="flex flex-col mt-4">
            <h4 v-if="status == 'done'">Unsubscribed!</h4>
            <button v-else :class="{'opacity-50': status == 'loading'}" @click="unsubscribe" class="btn-primary md:w-1/3 md:ml-auto">unsubscribe</button>
          </div>

        </div>
      </div>
    </main>
  </div>
</template>

<script>
import env from "../../env";
import axios from "axios";

export default {
  name: "Dashboard",
  data() {
    return {
      user: {},
      status: false
    };
  },
  methods: {
    async unsubscribe() {
      this.status = "loading";
      const { data } = await axios.put(
        `${env.apiURL}/users/unsubscribe/${this.$route.params.userId}`
      );
      if (data.success) this.status = "done";
      else this.status = false;
    }
  },
  async mounted() {
    this.user = (
      await axios.get(
        `${env.apiURL}/users/unsubscribe/${this.$route.params.userId}`
      )
    ).data;
    if (!this.user || !this.user.email || this.user.email == "email revoked")
      this.status = "done";
  }
};
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.4s ease;
}

.slide-fade-leave-active {
  transition: all 0.4s ease;
}

.slide-fade-enter,
  .slide-fade-leave-to

  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}
</style>
