<template>
  <nav class="w-full bg-tertiary border-secondary border-b-8" style="z-index: 999">
    <div class="relative w-full z-50 flex flex-row justify-start items-center h-16 flex-shrink-0 bg-branding">
      <div class="container mx-auto h-16 flex flex-row items-center justify-between py-2 px-3 flex-shrink-0">
        <router-link to="/" class="px-4 py-4 flex items-center justify-center text-white font-semibold">
          <img class="w-32" src="@/assets/images/logo_oranje1.svg">
        </router-link>
        <div class="flex flex-row items-center justify-center gap-2">

        </div>
        <div class="flex flex-row items-center justify-end gap-2">

          <router-link v-if="user && (user.isGod || user.isAdmin)" to="/start-test" class="ml-auto text-white cursor-pointer p-1 w-24 text-center rounded-lg">
            Start test
          </router-link>

          <!--ITEM-->

          <button @click="openProfile = !openProfile" class="flex flex-row items-center justify-end px-3 pl-5 py-2 rounded-full gap-3 transition duration-200 relative focus:outline-none focus:shadow-outline">
            <div>
              <p id="username" class="text-sm text-white font-medium text-right leading-none">
                Hi {{user.firstname}}
              </p>
            </div>
            <div class="relative w-8 h-8">
              <div v-if="user.avatar == undefined" class="w-8 h-8 bg-white rounded-full"></div>
              <img v-else class="h-8 w-8 rounded-full" :src="user.avatar" alt="">
            </div>
            <!--SUB-->
            <div v-if="openProfile == true" v-click-outside="closeAll" class="subnav absolute top-0 right-0 mt-12 flex flex-col items-start justify-start bg-gray-50 rounded-xl text-gray-800 text-sm divide-y divide-branding font-medium shadow-lg overflow-hidden">

              <router-link v-if="user && (user.isGod || user.isAdmin) " @click="closeAll()" to="/account" class="w-full text-left px-5 py-4 pr-16 flex flex-row items-center justify-start gap-3 transition duration-300 group hover:bg-gray-200">
                <svg class="w-5 h-5 text-branding" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <!--Generated by IJSVG (https://github.com/iconjar/IJSVG)-->
                  <g stroke-linecap="round" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linejoin="round">
                    <path d="M5,6v-3"></path>
                    <path d="M5,21v-11"></path>
                    <path d="M12,14v-11"></path>
                    <path d="M12,21v-3"></path>
                    <path d="M19,6v-3"></path>
                    <path d="M19,21v-11"></path>
                    <path d="M6.41421,6.58579c0.781049,0.781049 0.781049,2.04738 0,2.82843c-0.781049,0.781049 -2.04738,0.781049 -2.82843,0c-0.781049,-0.781049 -0.781049,-2.04738 -4.44089e-16,-2.82843c0.781049,-0.781049 2.04738,-0.781049 2.82843,-8.88178e-16"></path>
                    <path d="M13.4142,14.5858c0.781049,0.781049 0.781049,2.04738 0,2.82843c-0.781049,0.781049 -2.04738,0.781049 -2.82843,0c-0.781049,-0.781049 -0.781049,-2.04738 0,-2.82843c0.781049,-0.781049 2.04738,-0.781049 2.82843,0"></path>
                    <path d="M20.4142,6.58579c0.781049,0.781049 0.781049,2.04738 0,2.82843c-0.781049,0.781049 -2.04738,0.781049 -2.82843,0c-0.781049,-0.781049 -0.781049,-2.04738 0,-2.82843c0.781049,-0.781049 2.04738,-0.781049 2.82843,-8.88178e-16"></path>
                  </g>
                  <path fill="none" d="M0,0h24v24h-24Z"></path>
                </svg>
                <span class="">Account</span>
              </router-link>

              <router-link v-if="user && user.isGod" @click="closeAll()" to="/admin/surveys" class="w-full text-left px-5 py-4 pr-16 flex flex-row items-center justify-start gap-3 transition duration-300 group hover:bg-gray-200">
                <svg class="w-5 h-5 text-branding" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g stroke-linecap="round" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linejoin="round">
                    <path d="M11.835 15l5 5c.828.828 2.172.828 3 0v0c.828-.828.828-2.172 0-3l-5-5"></path>
                    <path d="M20.916 5.847c.024.023.042.053.051.085 .47 1.567.106 3.33-1.132 4.568 -1.251 1.251-3.038 1.609-4.617 1.117l-8.347 8.347c-.813.813-2.139.874-2.98.09 -.884-.823-.902-2.207-.056-3.054l8.383-8.383c-.492-1.579-.134-3.366 1.117-4.617 1.238-1.238 3.001-1.602 4.568-1.132 .032.01.062.027.085.051l.162.162c.078.078.078.205 0 .283l-2.635 2.636 2.32 2.32 2.636-2.636c.078-.078.205-.078.283 0l.162.163Z"></path>
                    <path d="M2.933 4.293l.674 2.023c.136.409.518.684.949.684h2.279v-2.279c0-.43-.275-.813-.684-.949l-2.023-.674c-.18-.06-.378-.013-.512.121l-.562.562c-.134.134-.181.332-.121.512Z"></path>
                    <path d="M6.84 7l3.5 3.5"></path>
                  </g>
                  <path fill="none" d="M0 0h24v24h-24Z"></path>
                </svg>
                <span class="">Admin</span>
              </router-link>

              <button @click="logout()" class="w-full text-left px-5 py-4 pr-16 hover:bg-red-500 text-red-500 hover:text-white flex flex-row items-center justify-start gap-3 transition duration-300 group">
                <svg class="w-5 h-5 text-secondary group-hover:text-white" id="Layer_3" data-name="Layer 3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <title>login-logout-arrow</title>
                  <line x1="3" y1="12" x2="14" y2="12" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                  <polyline points="11 9 14 12 11 15" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                  <path d="M6,9.137V7.4A2,2,0,0,1,7.608,5.44l11-2.406A2.146,2.146,0,0,1,21,5.2V19a2,2,0,0,1-2.339,1.972l-11-1.892A2,2,0,0,1,6,17.108V14.971" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                  <path d="M0,0H24V24H0Z" fill="none" />
                </svg>
                <span class="group-hover:text-white">Logout</span>
              </button>
            </div>
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import auth from "@/helpers/auth";

export default {
  name: "Overview",
  data: function() {
    return {
      openAdd: false,
      openProfile: false,
      user: {}
    };
  },
  methods: {
    logout: function() {
      this.$store.commit("logout");
    },
    closeAll() {
      this.openProfile = false;
      console.log("thisis ooooo");
    }
  },
  async mounted() {
    this.user = await auth.me().catch(() => {});
  }
};
</script>

<style>
p#username {
  @apply text-white !important;
}
</style>
