<template>
  <div class="w-full min-h-screen z-0">
    <div class="border-b border-tertiary relative h-14 bg-tertiary flex flex-row items-center px-20">
      <div class="w-10/12 h-14 flex flex-row mx-auto">
        <router-link :to="`/admin/surveys/${$route.params.id}/charts`" class="text-sm h-full flex flex-row items-center justify-center w-24 cursor-pointer border-b-2 border-transparent text-center" :class="page == '/admin/surveys' ? 'border-branding' : ''">
          <svg class="h-4 w-4" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <!--Generated by IJSVG (https://github.com/iconjar/IJSVG)-->
            <g stroke-linecap="round" stroke-width="1.5" stroke="#323232" fill="none" stroke-linejoin="round">
              <path d="M5,12h14"></path>
              <path d="M10,7l-5,5"></path>
              <path d="M10,17l-5,-5"></path>
            </g>
            <path fill="none" d="M0,0h24v24h-24Z"></path>
          </svg>
          <div>Back</div>
        </router-link>
        <div @click="page = 'general'" class="text-sm h-full flex flex-row items-center justify-center w-24 cursor-pointer border-b-2 border-transparent text-center" :class="page == 'general' ? 'border-branding' : ''">
          <div>General</div>
        </div>
        <div @click="page = 'fields'" class="text-sm h-full flex flex-row items-center justify-center w-24 cursor-pointer border-b-2 border-transparent text-center" :class="page == 'fields' ? 'border-branding' : ''">
          <div>Fields</div>
        </div>
        <div @click="page = 'preview'" class="text-sm h-full flex flex-row items-center justify-center w-24 cursor-pointer border-b-2 border-transparent text-center" :class="page == 'preview' ? 'border-branding' : ''">
          <div>Preview</div>
        </div>
      </div>
    </div>

    <main class="flex-1 overflow-y-auto focus:outline-none mt-5" tabindex="0">
      <div class="relative max-w-6xl mx-auto md:px-8 xl:px-0">
        <div class="">
          <div class="flex justify-end w-full">
            <div v-if="page == 'general'" class="flex flex-row">
              <div @click="save()" class="p-2 px-4 bg-branding rounded m-1 text-sm text-white text-center cursor-pointer">
                Save
              </div>
              <div @click="remove()" class="p-2 px-4 bg-red-600 rounded m-1 text-sm text-white text-center cursor-pointer">
                Delete
              </div>
            </div>
            <div v-if="page == 'fields'" class="flex flex-row">
              <div @click="addField()" class="p-2 px-4 bg-branding rounded m-1 text-sm text-white text-center cursor-pointer">
                Add field
              </div>
            </div>

          </div>

          <div v-if="page == 'general'" class="divide-y divide-branding">
            <div>
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                General settings
              </h3>
              <p class="max-w-2xl text-sm text-gray-500">
                General settings for this chart.
              </p>
            </div>

            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">Id</dt>
              <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2 font-bold">
                ${{ object.id }}
              </dd>
            </div>

            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">Name</dt>
              <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <input type="text" v-model="object.name" class="flex-grow focus:outline-none bg-white px-3 py-2 rounded-lg" placeholder="Text name" />
              </dd>
            </div>

            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">
                Chart type <br />
              </dt>
              <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <select v-model="object.chartType" type="text" class="p-2 ring-indigo-500 shadow-sm focus:border-branding block w-full sm:text-sm border-gray-200 rounded-md border-2">
                  <option value="" disabled>Pick a type!</option>
                  <option value="bar">Bar chart</option>
                  <option value="line">Line chart</option>
                  <option value="pie">Pie chart</option>
                </select>
              </dd>
            </div>

            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">
                Description <br />
                <small>(internal)</small>
              </dt>
              <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <textarea rows="4" v-model="object.desc" type="text" class="flex-grow focus:outline-none bg-white px-3 py-2 rounded-lg" placeholder="Text description"></textarea>
              </dd>
            </div>
          </div>

          <div v-if="page == 'fields'" class="">
            <div>
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                Fields
              </h3>
              <p class="max-w-2xl text-sm text-gray-500">
                Configure the fields your chart will include
              </p>
            </div>

            <!-- This example requires Tailwind CSS v2.0+ -->
            <div class="flex flex-col">

              <div class="mt-5 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200">
                      <thead class="bg-gray-50">
                        <tr>
                          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            ID
                          </th>
                          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                            Color
                          </th>
                          <th scop="col" class=""></th>
                        </tr>
                      </thead>
                      <tbody class="bg-white divide-y divide-gray-200">
                        <tr class="cursor-pointer hover:bg-gray-200" v-for="(field, index) in object.fields" :key="field._id">
                          <td class="px-6 py-3 whitespace-nowrap text-sm text-gray-500">
                            <select @change="save(false)" v-model="object.fields[index]">
                              <option value="" disabled>Pick a datapoint</option>
                              <option v-for="id of ids" :value="id" :key="id">{{id}}</option>
                            </select>
                          </td>
                          <td class="px-6 py-3 whitespace-nowrap text-sm text-gray-500">
                            <input @change="save(false)" v-model="object.colors[index]" type="color">
                          </td>
                          <td class="p-3 flex justify-end items-center h-full">
                            <button @click.stop="removeField(field)" class="btn-danger">
                              Remove
                            </button>
                          </td>

                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div v-if="page == 'preview'" class="divide-y divide-branding">
            <div>
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                Preview
              </h3>
              <p class="max-w-2xl text-sm text-gray-500">
                This is what yout chart will look like
              </p>
            </div>

            <div class="w-full">
              <div class="flex items-end justify-center w-2/3 m-auto">
                <chart v-if="loaded" :data="chartData" :colors="object.colors" :type="object.chartType"></chart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import auth from "@/helpers/auth";
import api from "@/helpers/api";

import chart from "@/components/charts/chart.vue";

export default {
  name: "Dashboard",
  components: {
    chart
  },
  data() {
    return {
      page: "fields",
      user: {},
      loaded: false,
      ids: [],
      object: {
        id: "",
        name: "",
        desc: "",
        chartType: "",
        fields: [],
        colors: []
      }
    };
  },
  methods: {
    async remove() {
      if (confirm("Are you sure you want to delete this entire template?")) {
        await api.delete(
          `/surveys/${this.$route.params.id}/charts/${this.$route.params.chartId}`
        );
        this.$router.push({
          path: `/admin/surveys/${this.$route.params.id}/charts`
        });
      }
    },
    async save(showAlert = true) {
      console.log("save", this.object);
      await api.put(
        `/surveys/${this.$route.params.id}/charts/${this.$route.params.chartId}`,
        this.object
      );
      await this.load();
      if (showAlert) alert("chart saved!");
    },
    async load() {
      this.loaded = false;
      // get the survey
      this.object = await api.get(
        `/surveys/${this.$route.params.id}/charts/${this.$route.params.chartId}`
      );
      // gather all the ids
      this.ids = (await api.get(`/surveys/${this.$route.params.id}`)).algorithm
        .map(el => `$${el.id}`)
        .concat(
          (await api.get(`/questions?survey=${this.$route.params.id}`)).reduce(
            (ids, el) => {
              if (/likert/.test(el.answerType))
                ids = ids.concat(el.options.map(el => `$${el.id}`));
              else ids.push(el.id);
              return ids;
            },
            []
          )
        );
      this.loaded = true;
    },
    async addField() {
      this.object.fields.push("test");
      await this.save(false);
    },
    async removeField(field) {
      this.object.fields = this.object.fields.filter(el => el !== field);
      await this.save(false);
    }
  },
  computed: {
    chartData() {
      const obj = {};
      this.object.fields.forEach(el => {
        obj[el] = Math.random() * 5;
      });
      return obj;
    }
  },
  watch: {
    "object.name"() {
      this.object.id = this.object.name
        .toUpperCase()
        .replace(/ +/g, "_")
        .replace(/[^A-Z_]/g, "");
    }
  },
  async mounted() {
    this.user = await auth.me().catch(() => {});
    await this.load();
  }
};
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.4s ease;
}

.slide-fade-leave-active {
  transition: all 0.4s ease;
}

.slide-fade-enter,
  .slide-fade-leave-to

  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}
</style>
