<template>
  <div class="w-full min-h-screen z-0 overflow-x-hidden">
    <div class="border-b border-tertiary relative h-14 bg-tertiary flex flex-row items-center px-20">
      <div class="w-10/12 h-14 flex flex-row mx-auto">
        <router-link :to="`/admin/surveys/${$route.params.id}/reports`" class="text-sm h-full flex flex-row items-center justify-center w-24 cursor-pointer border-b-2 border-transparent text-center" :class="page == '/admin/surveys' ? 'border-branding' : ''">
          <svg class="h-4 w-4" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <!--Generated by IJSVG (https://github.com/iconjar/IJSVG)-->
            <g stroke-linecap="round" stroke-width="1.5" stroke="#323232" fill="none" stroke-linejoin="round">
              <path d="M5,12h14"></path>
              <path d="M10,7l-5,5"></path>
              <path d="M10,17l-5,-5"></path>
            </g>
            <path fill="none" d="M0,0h24v24h-24Z"></path>
          </svg>
          <div>Back</div>
        </router-link>
        <div @click="page = 'general'" class="text-sm h-full flex flex-row items-center justify-center w-24 cursor-pointer border-b-2 border-transparent text-center" :class="page == 'general' ? 'border-branding' : ''">
          <div>General</div>
        </div>
        <div @click="page = 'content'" class="text-sm h-full flex flex-row items-center justify-center w-24 cursor-pointer border-b-2 border-transparent text-center" :class="page == 'content' ? 'border-branding' : ''">
          <div>Content</div>
        </div>
      </div>
    </div>

    <main class="flex-1 overflow-y-auto focus:outline-none mt-5" tabindex="0">
      <div class="relative max-w-6xl mx-auto md:px-8 xl:px-0">
        <div class="">
          <div class="flex justify-end w-full">
            <div class="flex flex-row space-x-2">
              <button @click="test()" class="btn-primary" :disabled="testing" :class="{'opacity-50': testing}">
                Test
              </button>
              <button @click="save()" class="btn-primary">
                Save
              </button>
              <button @click="remove()" class="btn-danger">
                Delete
              </button>
            </div>
          </div>

          <div v-if="page == 'general'" class="divide-y divide-branding">
            <div>
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                General settings
              </h3>
              <p class="max-w-2xl text-sm text-gray-500">
                General settings for this report.
              </p>
            </div>

            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">ID</dt>
              <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2 font-bold">
                ${{ report.id }}
              </dd>
            </div>

            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">
                PDF Template <br>
                <small>Contact support to add more templates</small>
              </dt>
              <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2 font-bold">
                <select v-model="report.templateId" name="" id="">
                  <option :value="undefined" disabled>Select a template</option>
                  <option v-if="templates" value="60b0e83754b1091d60c6f6f2">{{(templates.find(el => el._id.toString() == '60b0e83754b1091d60c6f6f2') || { name: '' }).name}}</option>
                  <option v-if="templates" value="60b0e84354b1091d60c6f6f3">{{(templates.find(el => el._id.toString() == '60b0e84354b1091d60c6f6f3') || { name: '' }).name}}</option>
                </select>
              </dd>
            </div>

            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">Name</dt>
              <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <input type="text" v-model="report.name" class="flex-grow focus:outline-none bg-white px-3 py-2 rounded-lg" placeholder="Text name" />
              </dd>
            </div>

            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">
                Description <br />
                <small>(internal)</small>
              </dt>
              <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <textarea rows="4" v-model="report.desc" type="text" class="flex-grow focus:outline-none bg-white px-3 py-2 rounded-lg" placeholder="Text description"></textarea>
              </dd>
            </div>

            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">
                Notes <br />
                <small>(internal)</small>
              </dt>
              <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <textarea rows="4" v-model="report.notes" type="text" class="flex-grow focus:outline-none bg-white px-3 py-2 rounded-lg" placeholder="Internal notes (eg. page)"></textarea>
              </dd>
            </div>
          </div>

          <div v-if="page == 'content'" class="">
            <div>
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                Fields
              </h3>
              <p class="max-w-2xl text-sm text-gray-500">
                Manage the content of the report here.
                The name field refers to the name of the dynamic field in the pdf.
                The value field is the data that will be filled in. For the data field you can also use any calculated value from the algorithm, these are also autocompleted.
                When testing, a random value between 0 and 10 will be chosen for the values from the algorithm.
              </p>
            </div>

            <datalist id="autocomplete">
              <option :value="option" v-for="option in autocompleteOptions" :key="option"></option>
            </datalist>

            <!-- This example requires Tailwind CSS v2.0+ -->
            <div class="flex flex-col mt-4">
              <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div class="shadow border-b border-gray-200 sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200">
                      <thead class="bg-gray-50">
                        <tr>
                          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Name
                          </th>
                          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Value
                          </th>
                          <th scope="col" class="pl-6 pr-1 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider flex justify-end">
                            <button @click="addField()" class="btn-primary">+</button>
                          </th>
                        </tr>
                      </thead>
                      <tbody class="bg-white divide-y divide-gray-200">
                        <tr v-for="(field, index) in report.fields" v-bind:key="index" class="items-center w-full">
                          <td class="px-6 pb-1 whitespace-nowrap text-sm font-medium text-gray-900 align-top">
                            <div class="flex space-x-2 items-center text-branding">
                              <label for="">Path</label>
                              <input type="text" v-model="field.path">
                            </div>

                          </td>
                          <td class="px-6 whitespace-nowrap text-sm text-gray-500">
                            <div class="flex flex-col">
                              <div v-for="lang in ['NL', 'EN', 'FR']" :key="lang" class="flex space-x-2 items-center text-branding">
                                <label for="">{{lang}}</label>
                                <input list="autocomplete" type="text" v-model="field[lang]">
                              </div>
                            </div>
                          </td>
                          <td class="pr-1 pt-2 whitespace-nowrap text-sm text-gray-500 justify-end flex">
                            <button @click="removeField(field)" class="btn-danger">x</button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import auth from "@/helpers/auth";
import api from "@/helpers/api";

import env from "../../../../env";

export default {
  name: "Dashboard",
  data() {
    return {
      testing: false,
      survey: {},
      questions: {},
      templates: [],
      page: "general",
      user: {},
      report: {
        id: "",
        name: "",
        desc: "",
        notes: "",
        templateId: "",
        fields: [
          {
            path: "",
            NL: "",
            FR: "",
            EN: ""
          }
        ]
      },
      language: "NL"
    };
  },
  methods: {
    async test() {
      this.testing = true;
      try {
        const pdf = await api.get(
          `/surveys/${this.$route.params.id}/reports/${this.$route.params.reportId}/test`
        );
        window.open(pdf.url, "_blank");
      } catch (error) {
        alert(
          "An error occured while testing this report. Are you sure all fields are set correctly?"
        );
      }
      this.testing = false;
    },
    async addField() {
      this.report.fields.push({ path: "", NL: "", FR: "", EN: "" });
      await this.save(false);
    },
    async removeField(field) {
      this.report.fields = this.report.fields.filter(el => el._id != field._id);
      await this.save(false);
    },
    async remove() {
      if (confirm("Are you sure you want to delete this entire template?")) {
        await api.delete(
          `/surveys/${this.$route.params.id}/reports/${this.$route.params.reportId}`
        );
        this.$router.push({
          path: `/admin/surveys/${this.$route.params.id}/reports`
        });
      }
    },
    async save(showAlert = true) {
      console.log("save", this.report);
      await api.put(
        `/surveys/${this.$route.params.id}/reports/${this.$route.params.reportId}`,
        this.report
      );
      await this.load();
      if (showAlert) alert("report saved!");
    },
    async load() {
      // survey, questions
      this.survey = await api.get(`/surveys/${this.$route.params.id}`);
      this.survey.algorithm = this.survey.algorithm.map(el => {
        el.parts = el.expression.split("$$");
        return el;
      });
      this.questions = await api.get(`/questions?survey=${this.survey._id}`);

      this.report = await api.get(
        `/surveys/${this.$route.params.id}/reports/${this.$route.params.reportId}`
      );
      if (!this.report.fields) this.report.fields = [];
      console.log(this.report);
      this.templates = await api.get(env.pdfManager + "/templates");
    }
  },
  computed: {
    autocompleteOptions() {
      // autocomplete options for all vars, questionIds, textids and operations
      // TODO autocomplete for multiple values!!
      return this.survey.algorithm
        .map(el => `$${el.id}`)
        .concat(this.survey.reports.map(el => `$${el.id}`))
        .concat(
          this.questions.reduce((ids, el) => {
            if (/likert/.test(el.answerType))
              ids = ids.concat(el.options.map(el => `$${el.id}`));
            else ids.push(el.id);
            return ids;
          }, [])
        );
    }
  },
  watch: {
    "report.name"() {
      this.report.id = this.report.name
        .toUpperCase()
        .replace(/ +/g, "_")
        .replace(/[^A-Z_]/g, "");
    },
    report: {
      handler() {},
      deep: true
    }
  },
  async mounted() {
    this.user = await auth.me().catch(() => {});
    await this.load();
  }
};
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.4s ease;
}

.slide-fade-leave-active {
  transition: all 0.4s ease;
}

.slide-fade-enter,
  .slide-fade-leave-to

  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}
</style>
