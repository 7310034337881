import Vue from 'vue';
import Router from 'vue-router';

// auth
import login from '@/pages/auth/login.vue';
import magicLink from '@/pages/auth/magic-link.vue';

// account
import accountSettings from '@/pages/account/settings.vue';

// admin
import Admin from '@/pages/admin/index.vue';

import Companies from '@/pages/admin/companies.vue';
import Texts from '@/pages/admin/texts.vue';
import Text from '@/pages/admin/text.vue';
import Emails from '@/pages/admin/emails.vue';
import Email from '@/pages/admin/email.vue';
import GDPR from '@/pages/admin/gdpr.vue';

// admin / survey
import Surveys from '@/pages/admin/surveys.vue';
import Survey from '@/pages/admin/survey/index.vue';
import SurveyGeneral from '@/pages/admin/survey/general.vue';
import SurveyQuestions from '@/pages/admin/survey/questions.vue';
import SurveyAlgorithms from '@/pages/admin/survey/algorithm.vue';
import SurveyCharts from '@/pages/admin/survey/charts.vue';
import SurveyChart from '@/pages/admin/survey/chart.vue';
import SurveyReports from '@/pages/admin/survey/reports.vue';
import SurveyReport from '@/pages/admin/survey/report.vue';
import SurveyScripts from '@/pages/admin/survey/scripts.vue';
import SurveyScript from '@/pages/admin/survey/script.vue';

// admin / company
// import Company from '@/pages/admin/company.vue'
import Company from '@/pages/admin/company/index.vue';
import CompanyGeneral from '@/pages/admin/company/general.vue';
//import CompanyPlan from '@/pages/admin/company/plan.vue';
import CompanyBilling from '@/pages/admin/company/billing.vue';
import CompanyUsers from '@/pages/admin/company/users.vue';
import CompanyTeams from '@/pages/admin/company/teams.vue';

// results
import results from '@/pages/results/index.vue';
import Report from '@/pages/results/detail.vue';
import Remind from '@/pages/results/remind.vue';

import startTest from '@/pages/startTest.vue';

import unsubscribe from '@/pages/unsubscribe.vue';

Vue.use(Router);

window.routes = [
  { path: '/login', name: 'login', component: login },
  { path: '/account', name: 'account', component: accountSettings },
  { path: '/start-test', name: 'startTest', component: startTest },

  { path: '/unsubscribe/:userId', name: 'unsubscribe', component: unsubscribe },

  { path: '/magic-link/:token', name: 'magic-link', component: magicLink },

  { path: '/', redirect: 'results' },
  { path: '/results', name: 'results', component: results },
  { path: '/results/:id', name: 'report', component: Report },
  { path: '/results/:id/remind', name: 'remind', component: Remind },

  {
    path: '/admin',
    component: Admin,
    children: [
      { path: '/', redirect: 'surveys' },
      { path: 'texts', component: Texts },
      { path: 'texts/:id', component: Text },

      { path: 'emails', component: Emails },
      { path: 'emails/:id', component: Email },

      { path: 'gdpr', component: GDPR },

      { path: 'surveys', component: Surveys },
      {
        path: 'surveys/:id',
        component: Survey,
        children: [
          { path: '', redirect: 'general' },
          { path: 'general', component: SurveyGeneral },
          { path: 'questions', component: SurveyQuestions },
          { path: 'algorithm', component: SurveyAlgorithms },
          { path: 'charts', component: SurveyCharts },
          { path: 'charts/:chartId', component: SurveyChart },
          { path: 'reports', component: SurveyReports },
          { path: 'reports/:reportId', component: SurveyReport },
          { path: 'scripts', component: SurveyScripts },
          { path: 'scripts/:scriptId', component: SurveyScript },
        ],
      },

      { path: 'companies', component: Companies },
      // { path: 'companies/:id', component: Company },
      {
        path: 'companies/:id',
        component: Company,
        children: [
          { path: '', redirect: 'general' },
          { path: 'general', component: CompanyGeneral },
          // { path: 'plan', component: CompanyPlan },
          { path: 'billing', component: CompanyBilling },
          { path: 'users', component: CompanyUsers },
          { path: 'teams', component: CompanyTeams },
        ],
      },
    ],
  },

  // { path: '/workspace/expertises', name: 'workspaceExpertises', component: workspaceExpertises },
];

const router = new Router({
  scrollBehavior() {
    return { x: 0, y: 0 }; // open another page => go to top!
  },
  routes: window.routes,
});

export default router;
