<template>
  <div class="w-full min-h-screen z-0 overflow-y-hidden">
    <div v-if="!/(reports|charts|scripts)\//i.test(page)" class="border-b border-tertiary relative h-14 bg-tertiary flex flex-row items-center px-20">
      <div class="w-10/12 h-14 flex flex-row mx-auto">
        <router-link to="/admin/surveys" class="text-sm h-full flex flex-row items-center justify-center w-24 cursor-pointer border-b-2 border-transparent text-center">
          <svg class="h-4 w-4" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <!--Generated by IJSVG (https://github.com/iconjar/IJSVG)-->
            <g stroke-linecap="round" stroke-width="1.5" stroke="#323232" fill="none" stroke-linejoin="round">
              <path d="M5,12h14"></path>
              <path d="M10,7l-5,5"></path>
              <path d="M10,17l-5,-5"></path>
            </g>
            <path fill="none" d="M0,0h24v24h-24Z"></path>
          </svg>
          <div>Back</div>
        </router-link>

        <router-link v-if="user.isGod" :to="`/admin/surveys/${$route.params.id}/general`" :class="{ 'border-branding': /general/.test(page) }" class="text-sm h-full flex flex-col justify-center w-24 cursor-pointer border-b-2 border-transparent text-center">
          <div>General</div>
        </router-link>

        <router-link v-if="user.isGod" :to="`/admin/surveys/${$route.params.id}/questions`" :class="{ 'border-branding': /questions/.test(page) }" class="text-sm h-full flex flex-col justify-center w-24 cursor-pointer border-b-2 border-transparent text-center">
          <div>Questions</div>
        </router-link>

        <router-link v-if="user.isGod" :to="`/admin/surveys/${$route.params.id}/algorithm`" :class="{ 'border-branding': /algorithm/.test(page) }" class="text-sm h-full flex flex-col justify-center w-24 cursor-pointer border-b-2 border-transparent text-center">
          <div>Algorithm</div>
        </router-link>

        <router-link v-if="user.isGod" :to="`/admin/surveys/${$route.params.id}/charts`" :class="{ 'border-branding': /charts/.test(page) }" class="text-sm h-full flex flex-col justify-center w-24 cursor-pointer border-b-2 border-transparent text-center">
          <div>Charts</div>
        </router-link>

        <router-link v-if="user.isGod" :to="`/admin/surveys/${$route.params.id}/reports`" :class="{ 'border-branding': /reports/.test(page) }" class="text-sm h-full flex flex-col justify-center w-24 cursor-pointer border-b-2 border-transparent text-center">
          <div>Reports</div>
        </router-link>

        <router-link v-if="false && user.isGod" :to="`/admin/surveys/${$route.params.id}/scripts`" :class="{ 'border-branding': /scripts/.test(page) }" class="text-sm h-full flex flex-col justify-center w-24 cursor-pointer border-b-2 border-transparent text-center">
          <div>Scripts</div>
        </router-link>
      </div>
    </div>
    <router-view class="flex-1 overflow-y-auto focus:outline-none" tabindex="0">
    </router-view>
  </div>
</template>

<script>
import auth from "@/helpers/auth";

export default {
  name: "Dashboard",
  data() {
    return {
      page: "",
      user: {}
    };
  },
  watch: {
    $route: function(prev, current) {
      console.log(current);
      this.page = this.$route.path;
    }
  },
  async mounted() {
    this.user = await auth.me().catch(() => {});
    if (!this.user.isAdmin && !this.user.isGod) this.$router.push("/results");
    this.page = this.$route.path;
  }
};
</script>
