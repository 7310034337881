<template>
  <div class="w-full min-h-full bg-background z-0">
    <main class="flex-1 bg-background overflow-y-auto focus:outline-none mt-5 mb-20" tabindex="0">
      <div class="bg-background relative max-w-4xl mx-auto md:px-8 xl:px-0">
        <div class="mt-20 mb-10">
          <!--TITLES-->
          <div class="flex justify-between items-start">
            <div v-if="step == 1">
              <h3 class="text-2xl leading-6 font-medium text-branding">
                Hello {{ user.firstname }},
              </h3>
              <p class="mt-2 max-w-2xl text-sm text-gray-800">
                To start a new test, we would like to ask you some questions.
              </p>
            </div>
            <div v-if="step == 2" class="w-full">
              <h3 class="text-2xl leading-6 font-medium">Email</h3>
              <p class="mt-2 max-w-2xl text-sm text-gray-800">
                Edit the mail templates here. We'll automatically replaces
                newlines (enters) by enters in the email.
                <br />
                You can also use brackets ([ AND ]) to identicate the useage of
                dynamic texts. Current supported variables are: [FIRSTNAME],
                [TOKEN], [DEADLINE].
              </p>
            </div>
            <div v-if="step == 3">
              <h3 class="text-2xl leading-6 font-medium">Validation</h3>
              <p class="mt-2 max-w-2xl text-sm text-gray-800">
                Before sending out this survey to each participant, we recommend validating whether
                all your emails are configured correctly.
              </p>
            </div>
            <div v-if="step == 4"></div>
            <h3 class="text-4xl opacity-50 font-medium flex-shrink-0 w-3/12 text-right">Step {{step}}/4</h3>
          </div>

          <!--WHO WHAT WHEN-->
          <div v-if="step == 1" class="flex flex-col mt-4">
            <!--COMPANY-->
            <div class="py-2 flex flex-col h-full">
              <div class="text-sm font-medium h-full">
                Which company <br />
                <small v-if="user.isGod" class="text-gray-800">Can't find the right company? Create one
                  <router-link to="/admin/companies" class="underline">here</router-link>
                </small>
              </div>
              <div class="mt-1 flex text-sm h-full">
                <select v-model="test.company" class="w-full border-transparent rounded">
                  <option value="">Select a company</option>
                  <option v-for="company in companies" v-bind:key="company._id" :value="company">
                    {{ company.name }}
                  </option>
                </select>
              </div>
            </div>

            <!--TEAM-->
            <div class="py-2 flex flex-col h-full">
              <div class="text-sm font-medium">
                For who <br />
                <small v-if="user.isGod" class="text-gray-800">Can't find the right team? Create one
                  <router-link to="/admin/companies" class="underline">here</router-link>
                </small>
              </div>
              <div class="mt-1 flex text-sm text-gray-900 h-full">
                <select v-model="test.target" class="w-full border-transparent rounded">
                  <option value="everyone">Everyone</option>
                  <option v-for="team in test.company.teams" v-bind:key="team.id" :value="team.id">
                    {{ team.name }}
                  </option>
                </select>
              </div>
            </div>

            <!--SURVEYS-->
            <div class="py-2 flex flex-col h-full">
              <div class="text-sm font-medium">
                What do you want to test? <br />
                <small v-if="user.isGod" class="text-gray-800">Can't find the right survey? Create one
                  <router-link to="/admin/surveys" class="underline">here</router-link>
                </small>
              </div>
              <div class="mt-1 flex text-sm text-gray-900">
                <div v-if="test.surveys.length > 0" class="w-full flex-col space-y-2">
                  <div v-for="(item, index) in test.surveys" v-bind:key="item" class="w-full flex flex-row items-center space-x-3">
                    <select v-model="test.surveys[index]" class="w-full border-transparent rounded">
                      <option disabled :value="undefined">
                        Select a survey
                      </option>
                      <option v-for="survey in surveys" v-bind:key="survey._id" :value="survey._id">
                        {{ survey.name }}
                      </option>
                    </select>
                    <svg @click="removeSurvey(index)" xmlns="http://www.w3.org/2000/svg" class="flex flex-row justify-center items-center text-center cursor-pointer h-6 w-6 text-branding" :class="{
                        'text-tertiary':
                          test.surveys.length == 1 && !test.surveys[0],
                      }" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                  </div>
                </div>
                <div v-else class="h-24 w-full flex flex-row items-center justify-center text-center text-gray-800">
                  <div>
                    No surveys added yet <br />
                    <span @click="addSurvey()" class="cursor-pointer underline text-branding">Add your first one</span>.
                  </div>
                </div>
              </div>
              <small @click="addSurvey()" class="mt-3 text-xs cursor-pointer text-gray-800 flex flex-row items-center text-center">
                <svg class="h-3 mr-1" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g fill="none">
                    <path d="M0 0h24v24h-24Z"></path>
                    <path stroke="#323232" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 8v8"></path>
                    <path stroke="#323232" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M16 12h-8"></path>
                    <path stroke="#323232" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 21v0c-4.971 0-9-4.029-9-9v0c0-4.971 4.029-9 9-9v0c4.971 0 9 4.029 9 9v0c0 4.971-4.029 9-9 9Z"></path>
                  </g>
                </svg>
                <div class="">Add another survey</div>
              </small>
            </div>

            <!--TIMING-->
            <div class="grid grid-cols-2 gap-4">
              <!--START DATE-->
              <div class="py-2 flex flex-col w-full">
                <div class="text-sm font-medium">Startdatum <br /></div>
                <div class="mt-1 flex text-sm">
                  <datepicker v-model="test.startDate" class="w-full border-transparent rounded" />
                </div>
              </div>

              <div class="py-2 flex flex-col w-full">
                <div class="text-sm font-medium">Einddatum <br /></div>
                <div class="mt-1 flex text-sm">
                  <datepicker v-model="test.endDate" class="w-full border-transparent rounded" />
                </div>
              </div>

              <!--DURATION
              <div class="py-2 flex flex-col w-full">
                <div class="text-sm font-medium">Timeframe <br /></div>
                <div class="mt-1 flex text-sm">
                  <select v-model="test.timeframe" class="w-full border-transparent rounded">
                    <option value="7days">7 days</option>
                    <option value="14days">14 days</option>
                  </select>
                </div>
              </div>
              -->
            </div>


          </div>

          <!--EMAILS-->
          <div v-if="step == 2" class="mb-2">
            <!--EMAILS-->
            <div class="flex flex-col h-full mt-2">
              <div class="mt-3 text-sm font-medium capitalize">
                <div>Origin email</div>
                <small class="text-gray-800">
                  All mail for this survey will be sent from here.
                </small>
              </div>
              <select v-model="test.mails.origin" class="w-full border-transparent rounded mt-1">
                <option disabled value="">Select an email</option>
                <option value="michael.van.damme@theforge.be">
                  michael.van.damme@theforge.be
                </option>
                <option value="survey@theforge.be">survey@theforge.be</option>
              </select>

              <div v-for="type in ['start', 'reminder', 'deadline']" :key="type" class="flex flex-col">
                <!--TITLE-->
                <div class="mt-3 text-sm font-medium capitalize">
                  <div>{{ type }} mail</div>
                  <small v-if="type == 'start'" class="text-gray-800">
                    Will automatically be sent on the start date.
                  </small>
                  <small v-if="type == 'deadline'" class="text-gray-800">
                    Will automatically be sent one day before the deadline.
                  </small>
                  <small v-if="type == 'reminder'" class="text-gray-800">
                    Will be sent when a reminder is triggered.
                  </small>
                </div>

                <div class="mt-1 flex items-center space-x-2 text-sm h-full">
                  <select v-model="templates[type]" class="w-full border-transparent rounded mt-1">
                    <option disabled value="unset">Select a template</option>
                    <option value="blank">Blank template</option>
                    <option v-for="item in emails" :key="item.id" :value="item.id">
                      {{ item.name }}
                    </option>
                  </select>

                  <button :disabled="templates[type] == 'unset'" @click="editTemplate('')" v-if="editMails[type]">
                    <svg xmlns="http://www.w3.org/2000/svg" class="text-branding text-sm flex flex-row cursor-pointer justify-center items-center rounded h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <title>Edit template</title>
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                    </svg>
                  </button>
                  <button :disabled="templates[type] == 'unset'" v-else @click="editTemplate(type)">
                    <svg :class="{ 'text-tertiary': templates[type] == 'unset' }" xmlns="http://www.w3.org/2000/svg" class="text-branding text-sm flex flex-row cursor-pointer justify-center items-center rounded h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <title>Edit template</title>
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                    </svg>
                  </button>
                </div>

                <div v-if="editMails[type]">
                  <div v-for="language in ['NL', 'EN', 'FR']" :key="language">
                    <div class="py-2 flex flex-col">
                      <div class="text-sm font-medium text-branding">
                        Email subject {{ language }} <br />
                      </div>
                      <div class="mt-3 flex text-sm">
                        <input v-model="test.mails[type]['subject' + language]" type="text" class="w-full border-transparent rounded mt-1" />
                      </div>
                    </div>

                    <div class="py-2 flex flex-col">
                      <div class="text-sm font-medium text-branding">
                        Email body {{ language }} <br />
                      </div>
                      <div class="mt-1 flex text-sm">
                        <textarea class="w-full border-transparent rounded mt-1" v-model="test.mails[type]['message' + language]" rows="15"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--TEST-->
          <div v-if="step == 3" class="flex flex-col mt-4">
            <!--TESTER-->
            <div class="py-2 flex flex-col h-full">
              <div class="text-sm font-medium h-full">
                Who <br />
                <small class="text-gray-800">The email of the tester</small>
              </div>
              <div class="mt-1 flex text-sm h-full">
                <input type="text" v-model="test.mails.tester" class="w-full border-transparent rounded mt-1" />
              </div>
            </div>

            <div class="flex flex-col sm:flex-row sm:space-x-6 mt-2">
              <!--TYPES-->
              <div class="py-2 flex flex-1 flex-col h-full">
                <div class="text-sm font-medium h-full">
                  What <br />
                  <small class="text-gray-800">The mails you would like to test</small>
                </div>
                <div v-for="type in Object.keys(testOptions.types)" :key="type" class=" mt-4 flex space-x-4 items-center justify-between">
                  <button @click="testOptions.types[type] = !testOptions.types[type]" :class="[testOptions.types[type] ? 'bg-branding' : 'bg-tertiary', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-branding']" type="button" role="switch" aria-checked="false" aria-labelledby="availability-label">
                    <span class="sr-only">Use setting</span>
                    <span aria-hidden="true" :class="[testOptions.types[type] ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" class="translate-x-0 pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
                  </button>
                  <span class="flex-grow flex flex-col" id="availability-label">
                    <span class="text-sm font-medium text-gray-900 capitalize">{{type}}</span>
                  </span>
                </div>
              </div>

              <!--LANGUAGES-->
              <div class="flex-1 py-2 flex flex-col h-full">
                <div class="text-sm font-medium h-full">
                  Languages <br />
                  <small class="text-gray-800">What languages do you want to test?</small>
                </div>
                <div v-for="lang in Object.keys(testOptions.languages)" :key="lang" class=" mt-4 flex space-x-4 items-center justify-between">
                  <button @click="testOptions.languages[lang] = !testOptions.languages[lang]" :class="[testOptions.languages[lang] ? 'bg-branding' : 'bg-tertiary', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-branding']" type="button" role="switch" aria-checked="false" aria-labelledby="availability-label">
                    <span class="sr-only">Use setting</span>
                    <span aria-hidden="true" :class="[testOptions.languages[lang] ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" class="translate-x-0 pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
                  </button>
                  <span class="flex-grow flex flex-col" id="availability-label">
                    <span class="text-sm font-medium text-gray-900 capitalize">{{lang}}</span>
                  </span>
                </div>
              </div>
            </div>

          </div>

          <!--START-->
          <div v-if="step == 4" class="flex flex-col mt-4">
            <div v-if="launchButtonVisible" class="h-56 my-20 flex flex-col justify-center items-center">
              <div class="">Ready? Press that button!</div>
              <div @click="submit" class="flex flex-row rounded-md bg-secondary p-5 px-10 cursor-pointer mt-5 text-xl text-white">
                <svg class="h-8 w-8 mr-3" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <g stroke-linecap="round" stroke-width="1.5" stroke="white" fill="none" stroke-linejoin="round">
                    <path d="M3 12l0 0c-.01-4.98 4.02-9.01 8.99-9.01 4.97-.01 9 4.02 9 8.99 0 4.97-4.03 9-9 9" />
                    <path d="M9.845 15.491l-.82-2.68 -2.33-.73 -.01-.01c-.16-.05-.25-.22-.2-.37 .02-.09.09-.16.17-.19l8.85-3.42 0-.01c.22-.09.48.02.57.25 .02.07.03.16.02.24l-1.487 7 0-.01c-.07.31-.38.51-.69.44 -.09-.02-.16-.06-.23-.11l-2.112-1.56 -1.25 1.21 0-.01c-.13.12-.34.12-.46-.01 -.04-.04-.07-.08-.08-.13Z" />
                    <path d="M4 20l3-3" />
                    <path d="M8 21l1-1" />
                    <path d="M3 16l1-1" />
                  </g>
                  <path fill="none" d="M0 0h24v24H0Z" />
                </svg>
                <div>Launch that survey!</div>
              </div>
            </div>

            <div v-else>
              We're currently processing your request. <br />
              This can take a few seconds and we'll redirect you once we're
              done.
            </div>
          </div>

          <!--NEXT PREV-->
          <div class="w-full flex flex-row">
            <button @click="runTest" v-if="step == 3" :disabled="!validForm || !Object.values(testOptions.types).some(el => el)" :class="{'bg-tertiary': !validForm || !Object.values(testOptions.types).some(el => el) }" class="text-sm cursor-pointer justify-center bg-branding text-white rounded p-2 px-4 mt-4">

              Run test!
            </button>

            <div class="w-3/12 ml-auto flex flex-row">
              <div v-if="launchButtonVisible && step > 1" @click="step -= 1" class="ml-auto mt-3 flex flex-row w-full">
                <div class="text-sm flex flex-row cursor-pointer justify-center items-center ml-auto bg-branding text-white rounded p-2">
                  <svg class="mr-2 h-3 w-3 text-white" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <!--Generated by IJSVG (https://github.com/iconjar/IJSVG)-->
                    <g stroke-linecap="round" stroke-width="1.5" stroke="white" fill="none" stroke-linejoin="round">
                      <path d="M5,12h14"></path>
                      <path d="M10,7l-5,5"></path>
                      <path d="M10,17l-5,-5"></path>
                    </g>
                    <path fill="none" d="M0,0h24v24h-24Z"></path>
                  </svg>
                  <div>Go back</div>
                </div>
              </div>

              <div v-if="step < 4" @click="nextStep()" class="mt-3 flex flex-row w-full">
                <button :class="{
                    'bg-tertiary': !validForm,
                    'bg-branding': validForm,
                    'text-white': validForm,
                    'text-branding': !validForm,
                  }" class="text-sm flex flex-row cursor-pointer justify-center items-center ml-auto rounded p-2">
                  <div>Next step</div>
                  <svg class="ml-2 h-3 w-3" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <!--Generated by IJSVG (https://github.com/iconjar/IJSVG)-->
                    <g stroke-linecap="round" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linejoin="round">
                      <path d="M19,12h-14"></path>
                      <path d="M14,17l5,-5"></path>
                      <path d="M14,7l5,5"></path>
                    </g>
                    <path fill="none" d="M0,0h24v24h-24Z"></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import auth from "@/helpers/auth";
import api from "@/helpers/api";

export default {
  name: "Dashboard",
  data() {
    return {
      user: {},
      companies: [],
      surveys: [],
      emails: [],

      launchButtonVisible: true,

      editMails: {
        start: false,
        deadline: false,
        reminder: false
      },

      templates: {
        start: "unset",
        deadline: "unset",
        reminder: "unset"
      },

      testOptions: {
        types: {
          start: true,
          deadline: true,
          reminder: true
        },
        languages: {
          NL: true,
          FR: true,
          EN: true
        }
      },
      // are we running a test?
      testing: false,

      test: {
        company: "",
        startDate: this.formatDate(new Date()),
        endDate: this.formatDate(
          new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
        ),
        timeframe: "7days",
        target: "everyone",
        surveys: [undefined],
        participantsCount: 5,
        participants: [],
        mails: {
          origin: "",
          tester: "",
          start: {
            subjectNL: "",
            messageNL: "",
            subjectEN: "",
            messageEN: "",
            subjectFR: "",
            messageFR: ""
          },
          deadline: {
            subjectNL: "",
            messageNL: "",
            subjectEN: "",
            messageEN: "",
            subjectFR: "",
            messageFR: ""
          },
          reminder: {
            subjectNL: "",
            messageNL: "",
            subjectEN: "",
            messageEN: "",
            subjectFR: "",
            messageFR: ""
          }
        }
      },
      step: 1
    };
  },
  methods: {
    formatDate: date => new Date(date).toISOString().substring(0, 10),
    editTemplate(type) {
      Object.keys(this.editMails).forEach(key => {
        this.editMails[key] = false;
      });
      if (type) this.editMails[type] = true;
    },
    nextStep() {
      if (!this.validForm) return;
      if (this.test.participantsCount > this.test.participants.length) {
        const diff =
          this.test.participantsCount - this.test.participants.length;
        for (let i = 0; i < diff; i++) {
          this.test.participants.push({ firstname: "", email: "" });
        }
      }
      if (this.test.participantsCount < this.test.participants.length) {
        const diff =
          this.test.participants.length - this.test.participantsCount;
        this.test.participants.splice(this.test.participantsCount, diff);
      }
      this.step += 1;
    },
    addSurvey() {
      this.test.surveys.push(undefined);
    },
    removeSurvey(index) {
      this.test.surveys.splice(index, 1);
    },
    async submit() {
      this.launchButtonVisible = false;
      await api.post("/tests", this.test);
      alert(`Campaign will start on ${this.test.startDate}!`);
      this.$router.push({ path: "/results" });
    },
    async runTest() {
      this.testing = true;
      const options = {
        tester: this.test.mails.tester,
        test: this.test,
        options: {
          types: Object.keys(this.testOptions.types).filter(
            el => this.testOptions.types[el]
          ),
          languages: Object.keys(this.testOptions.languages).filter(
            el => this.testOptions.languages[el]
          )
        }
      };
      if (this.test._id) options.test = this.test._id;
      this.test._id = await api.post("/tests/test", options);
      this.testing = false;
      alert(`Test mails sent to ${this.test.mails.tester}`);
    }
  },
  computed: {
    validForm() {
      if (this.step == 1) {
        return (
          this.test.company != "" &&
          this.test.company.name != "" &&
          this.test.surveys.length > 0 &&
          this.test.surveys[0] != undefined
        );
      }
      if (this.step == 2) {
        return (
          // do we have a mail origin?
          this.test.mails.origin.length != "" &&
          // do we have a template for each mail type?
          ["start", "deadline", "reminder"].every(
            type => this.templates[type] !== "unset"
          )
        );
      }
      if (this.step == 3) {
        console.log(this.testOptions);
        return (
          this.test.mails.tester != "" &&
          // are we currently running a test?
          !this.testing
        );
      }
      return false;
    }
  },
  watch: {
    templates: {
      handler() {
        if (this.step !== 2) return;
        this.editTemplate("");
        // set the template for each mail type
        ["start", "deadline", "reminder"].forEach(type => {
          if (this.templates[type] == "unset") return; // skip this

          // find the right template content
          const template = {};
          if (this.templates[type] == "blank") {
            // set a blank template
            ["NL", "EN", "FR"].forEach(lang => {
              template[`subject${lang}`] = "";
              template[`message${lang}`] = "";
            });
            this.editTemplate(type);
          } else {
            // look through the known mails to find this template
            const email = this.emails.find(el => el.id == this.templates[type]);
            if (!email) {
              // template does not exist, set back to 'unset'
              this.templates[type] = "unset";
              return;
            }
            ["NL", "EN", "FR"].forEach(lang => {
              template[`subject${lang}`] = email[`head${lang}`];
              template[`message${lang}`] = email[`body${lang}`];
            });
          }
          // set this template content
          this.test.mails[type] = template;
        });
      },
      deep: true
    }
  },
  async mounted() {
    this.user = await auth.me();
    this.companies = (await api.get("/companies")).data;
    this.surveys = (await api.get("/surveys")).data;
    this.emails = (await api.get("/emails")).data;
    // this.test.mails = this.templates;
    // this.test.mails.origin = "";
    if (!this.test.mails.tester) this.test.mails.tester = this.user.email;
  }
};
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.4s ease;
}

.slide-fade-leave-active {
  transition: all 0.4s ease;
}

.slide-fade-enter,
  .slide-fade-leave-to

  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}
</style>
