<template>
  <div class="w-full min-h-screen z-0 ">
    <nav class="border-b border-tertiary relative h-14 bg-tertiary flex flex-row items-center px-20">
      <div class="w-10/12 h-14 flex flex-row mx-auto">
        <router-link to="/admin/texts" class="text-sm h-full flex flex-row items-center justify-center w-24 cursor-pointer border-b-2 border-transparent text-center" :class="page == '/admin/surveys' ? 'border-branding' : ''">
          <svg class="h-4 w-4" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <!--Generated by IJSVG (https://github.com/iconjar/IJSVG)-->
            <g stroke-linecap="round" stroke-width="1.5" stroke="#323232" fill="none" stroke-linejoin="round">
              <path d="M5,12h14"></path>
              <path d="M10,7l-5,5"></path>
              <path d="M10,17l-5,-5"></path>
            </g>
            <path fill="none" d="M0,0h24v24h-24Z"></path>
          </svg>
          <div>Back</div>
        </router-link>

        <div @click="page = 'general'" class="text-sm h-full flex flex-row items-center justify-center w-24 cursor-pointer border-b-2 border-transparent text-center" :class="page == 'general' ? 'border-branding' : ''">
          <div>General</div>
        </div>

        <div @click="page = 'content'" class="text-sm h-full flex flex-row items-center justify-center w-24 cursor-pointer border-b-2 border-transparent text-center" :class="page == 'content' ? 'border-branding' : ''">
          <div>Content</div>
        </div>

      </div>
    </nav>

    <main class="flex-1 overflow-y-auto focus:outline-none mt-5" tabindex="0">
      <div class="relative max-w-4xl mx-auto md:px-8 xl:px-0">
        <div class="mt-10">
          <div class="space-y-1 flex items-start justify-between">
            <div v-if="page == 'general'">
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                General settings
              </h3>
              <p class="max-w-2xl text-sm text-gray-500">
                General settings for this text.
              </p>
            </div>

            <div v-if="page == 'content'">
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                Your content
              </h3>
              <p class="max-w-2xl text-sm text-gray-500">
                Change here your content in order to change it on the website.
              </p>
            </div>

            <div class="flex flex-row space-x-2">
              <button @click="save()" class="btn-primary">
                Save
              </button>
              <button @click="remove()" class="btn-danger">
                Delete
              </button>
            </div>
          </div>

          <div v-if="page == 'general'" class="divide-y divide-branding w-full">
            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">Name</dt>
              <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <input type="text" v-model="text.name" class="flex-grow focus:outline-none bg-white px-3 py-2 rounded-lg" placeholder="Text name" />
              </dd>
            </div>

            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">ID</dt>
              <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <input type="text" v-model="text.id" class="flex-grow focus:outline-none bg-white px-3 py-2 rounded-lg" placeholder="Text id" />
              </dd>
            </div>

            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">
                Description <br />
                <small>(internal)</small>
              </dt>
              <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <textarea rows="4" v-model="text.desc" type="text" class="flex-grow focus:outline-none bg-white px-3 py-2 rounded-lg" placeholder="Text description"></textarea>
              </dd>
            </div>

            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">
                Notes <br />
                <small>(internal)</small>
              </dt>
              <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <textarea rows="4" v-model="text.notes" type="text" class="flex-grow focus:outline-none bg-white px-3 py-2 rounded-lg" placeholder="Internal notes (eg. page)"></textarea>
              </dd>
            </div>
          </div>

          <div v-if="page == 'content'" class="divide-y divide-branding mt-10 mb-10">
            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">TEXT NL</dt>
              <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <textarea rows="4" v-model="text.textNL" type="text" class="flex-grow focus:outline-none bg-white px-3 py-2 rounded-lg" placeholder="Text Dutch"></textarea>
              </dd>
            </div>

            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">Text EN <br /></dt>
              <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <textarea rows="4" v-model="text.textEN" type="text" class="flex-grow focus:outline-none bg-white px-3 py-2 rounded-lg" placeholder="Text English"></textarea>
              </dd>
            </div>

            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">Text FR <br /></dt>
              <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <textarea rows="4" v-model="text.textFR" type="text" class="flex-grow focus:outline-none bg-white px-3 py-2 rounded-lg" placeholder="Text French"></textarea>
              </dd>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import auth from "@/helpers/auth";
import api from "@/helpers/api";

export default {
  name: "Dashboard",
  data() {
    return {
      text: {},
      page: "general"
    };
  },
  methods: {
    async remove() {
      if (!confirm("Are you sure you want to delete this entire text?")) return;
      await api.delete("/texts/" + this.text._id);
      this.$router.push({ path: "/admin/texts" });
    },

    async save(showAlert = true) {
      await api.put("/texts/" + this.$route.params.id, this.text);
      if (showAlert) document.location.href = "/#/admin/texts";
    },

    async load() {
      this.text = await api.get("/texts/" + this.$route.params.id);
    }
  },
  async mounted() {
    this.user = await auth.me().catch(() => {});
    this.load();
  }
};
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.4s ease;
}

.slide-fade-leave-active {
  transition: all 0.4s ease;
}

.slide-fade-enter,
  .slide-fade-leave-to

  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}
</style>
