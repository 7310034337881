<template>
  <div id="app" class="relative antialiased bg-background text-branding">
    <div class="flex flex-col items-start justify-start h-screen" v-if="user && user._id && showMenuBar">
      <headerNav />
      <router-view class="body w-screen"></router-view>
    </div>
    <router-view v-else class="body"></router-view>
  </div>
</template>

<script>
import auth from "@/helpers/auth";
import headerNav from "@/components/headerNav.vue";

export default {
  name: "app",
  data() {
    return {
      loading: true,
      user: {},
      href: ""
    };
  },
  components: {
    headerNav
  },
  methods: {
    logout() {
      this.$store.commit("logout");
    },
    getURL() {
      return document.location.href;
    }
  },
  watch: {
    async $route() {
      this.href = document.location.href;

      // Test to login a user
      this.user = await auth.me().catch(e => {
        console.log("NOT LOGGEDIN", e);
      });

      const allowed = ["login", "magic-link", "results", "ubsubscribe"];
      const allowedAdmin = ["start-test", "account", "reports"];

      console.log(this.href);

      // the user is allowed through for these conditions
      if (allowed.some(el => new RegExp(el).test(this.href))) return;
      if (!this.user) return this.$router.replace({ path: "/login" });
      if (this.user.isGod) return;
      if (
        this.user.isAdmin &&
        allowedAdmin.some(el => new RegExp(el).test(this.href))
      ) {
        return;
      }

      console.log("redirect user");

      // otherwise redirect him to results
      if (!/results/.test(this.href)) {
        return this.$router.replace({ path: "/results" });
      }
    }
  },
  computed: {
    showMenuBar() {
      console.log("THIS.HREF:", this.href);
      if (this.href.indexOf("raw") > -1) {
        return false;
      }
      return true;
    }
  },
  async mounted() {
    this.href = document.location.href;

    // dont check when setting cookie
    if (/magic-link/.test(this.href)) return;

    // Test to login a user
    this.user = await auth.me().catch(e => {
      console.log("NOT LOGGEDIN", e);
    });

    if (!this.user || !this.user._id) {
      // Is this user trying to reset his/her password? Else, redirect to login page.
      if (
        document.location.href.indexOf("password") == -1 &&
        document.location.href.indexOf("magic-link") == -1 &&
        document.location.href.indexOf("unsubscribe") == -1 &&
        document.location.href.indexOf("invite") == -1 &&
        document.location.href.indexOf("login") == -1 &&
        document.location.href.indexOf("raw") == -1
      ) {
        this.$router.replace({ path: "/login" });
        this.loading = false;
      } else {
        this.loading = false;
      }
    } else {
      this.$store.commit("setUser", this.user);
      this.loading = false;
    }
  }
};
</script>

<style>
.svg-bg {
  background-image: url("data:image/svg+xml,%3Csvg width='64' height='64' viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 16c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm0-2c3.314 0 6-2.686 6-6s-2.686-6-6-6-6 2.686-6 6 2.686 6 6 6zm33.414-6l5.95-5.95L45.95.636 40 6.586 34.05.636 32.636 2.05 38.586 8l-5.95 5.95 1.414 1.414L40 9.414l5.95 5.95 1.414-1.414L41.414 8zM40 48c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm0-2c3.314 0 6-2.686 6-6s-2.686-6-6-6-6 2.686-6 6 2.686 6 6 6zM9.414 40l5.95-5.95-1.414-1.414L8 38.586l-5.95-5.95L.636 34.05 6.586 40l-5.95 5.95 1.414 1.414L8 41.414l5.95 5.95 1.414-1.414L9.414 40z' fill='%23eaeaea' fill-opacity='0.4' fill-rule='evenodd'/%3E%3C/svg%3E");
}

.taskani-item {
  transition: all 0.5s;
  display: inline-block;
}
.taskani-leave-active {
  position: absolute;
}
.taskani-enter, .taskani-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateX(20px);
}
</style>
