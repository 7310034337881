import axios from 'axios';
import env from '@/../env';
import cookies from './cookies';

const client = axios.create({
  baseURL: `${env.apiURL}`, // contains the version
  json: true,
  headers: {
    authorization: `Bearer ${cookies.get('session')}`
  },
})

const execute = async (method, resource, data) => new Promise(((resolve, reject) => {
  client.defaults.headers.authorization = `Bearer ${cookies.get('session')}`;

  if (env.apiURL.indexOf('https') == -1 && env.apiURL.indexOf('localhost') == -1) {
    alert('YOU CANNOT MAKE A CONNECTION WITHOUT HTTP. THIS CALL IS NOT SECURE!');
    return reject('YOU CANNOT MAKE A CONNECTION WITHOUT SSL');
  }

  return client({ method, url: resource, data }).then((req) => resolve(req.data)).catch((err) =>
  // console.log(err)
    reject(err));
}));


const get = async (endpoint) => {
  return new Promise((resolve, reject) => {
    execute('get', endpoint).then(function(data){
      return resolve(data);
    }).catch(function(e){
      return reject(e);
    });
  })
}


const post = async (endpoint, data) => {
  return new Promise((resolve, reject) => {
    //console.log(endpoint, data)
    execute('post', endpoint, data).then(function(user){
      return resolve(user);
    }).catch(function(e){
      return reject(e);
    });
  })
}

const put = async (endpoint, data) => {
  return new Promise((resolve, reject) => {
    execute('put', endpoint, data).then(function(user){
      return resolve(user);
    }).catch(function(e){
      return reject(e);
    });
  })
}


const del = async (endpoint) => {
  return new Promise((resolve, reject) => {
    execute('delete', endpoint).then(function(user){
      return resolve(user);
    }).catch(function(e){
      return reject(e);
    });
  })
}


export default {
  post,
  get,
  delete: del,
  put,
}

/*
import api from '@/helpers/api';

const API = await api.init({name: 'restaurants'});

API.findOne()

*/
