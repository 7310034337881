<template>
  <div class="w-full min-h-screen z-0">
    <div class="border-b border-tertiary relative h-14 bg-tertiary flex flex-row items-center px-20">
      <div class="w-10/12 h-14 flex flex-row mx-auto">
        <router-link :to="`/admin/surveys/${$route.params.id}/scripts`" class="text-sm h-full flex flex-row items-center justify-center w-24 cursor-pointer border-b-2 border-transparent text-center" :class="page == '/admin/surveys' ? 'border-branding' : ''">
          <svg class="h-4 w-4" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <!--Generated by IJSVG (https://github.com/iconjar/IJSVG)-->
            <g stroke-linecap="round" stroke-width="1.5" stroke="#323232" fill="none" stroke-linejoin="round">
              <path d="M5,12h14"></path>
              <path d="M10,7l-5,5"></path>
              <path d="M10,17l-5,-5"></path>
            </g>
            <path fill="none" d="M0,0h24v24h-24Z"></path>
          </svg>
          <div>Back</div>
        </router-link>
        <div @click="page = 'general'" class="text-sm h-full flex flex-row items-center justify-center w-24 cursor-pointer border-b-2 border-transparent text-center" :class="page == 'general' ? 'border-branding' : ''">
          <div>General</div>
        </div>
      </div>
    </div>

    <main class="flex-1 overflow-y-auto focus:outline-none mt-5" tabindex="0">
      <div class="relative max-w-6xl mx-auto md:px-8 xl:px-0">
        <div class="">
          <div class="flex justify-end w-full">
            <div class=" flex flex-row">
              <div @click="save()" class="p-2 px-4 bg-branding rounded m-1 text-sm text-white text-center cursor-pointer">
                Save
              </div>
              <div @click="remove()" class="p-2 px-4 bg-red-600 rounded m-1 text-sm text-white text-center cursor-pointer">
                Delete
              </div>
            </div>
          </div>

          <div v-if="page == 'general'" class="divide-y divide-branding">
            <div>
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                General settings
              </h3>
              <p class="max-w-2xl text-sm text-gray-500">
                General settings for this script.
              </p>
            </div>

            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">Name</dt>
              <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <input type="text" v-model="report.name" class="flex-grow focus:outline-none bg-white px-3 py-2 rounded-lg" placeholder="name" />
              </dd>
            </div>

            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">
                Description <br />
                <small>(internal)</small>
              </dt>
              <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <textarea rows="4" v-model="report.desc" type="text" class="flex-grow focus:outline-none bg-white px-3 py-2 rounded-lg" placeholder="description"></textarea>
              </dd>
            </div>

            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">Script</dt>
              <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div class="flex justify-between w-full">
                  <input type="file" ref="upload" @change="uploadScript" class="hidden" />
                  <a :href="report.src" target="_blank" class="underline text-blue-400">{{ report.src }}</a>
                  <svg @click="$refs.upload.click()" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 cursor-pointer hover:text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                  </svg>
                </div>
              </dd>
            </div>

          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import auth from "@/helpers/auth";
import api from "@/helpers/api";
import env from "../../../../env";

export default {
  name: "Dashboard",
  data() {
    return {
      page: "general",
      user: {},
      report: {
        name: "",
        desc: "",
        src: "",
        output: ""
      },
      language: "NL"
    };
  },
  methods: {
    async remove() {
      if (confirm("Are you sure you want to delete this entire template?")) {
        await api.delete(
          `/surveys/${this.$route.params.id}/scripts/${this.$route.params.scriptId}`
        );
        this.$router.push({
          path: `/admin/surveys/${this.$route.params.id}/scripts`
        });
      }
    },
    async save(showAlert = true) {
      console.log("save", this.report);
      await api.put(
        `/surveys/${this.$route.params.id}/scripts/${this.$route.params.scriptId}`,
        this.report
      );
      await this.load();
      if (showAlert) alert("script saved!");
    },
    async load() {
      this.report = await api.get(
        `/surveys/${this.$route.params.id}/scripts/${this.$route.params.scriptId}`
      );
      console.log("loaded", this.report);
    },
    async uploadScript(e) {
      let droppedFiles = e.target.files;
      if (!droppedFiles) return;
      if (droppedFiles.length !== 1) return;
      console.log(droppedFiles[0].type);
      let formData = new FormData();
      formData.append("file", droppedFiles[0]);
      fetch(
        env.apiURL + "/surveys/" + this.$route.params.id + "/scripts/upload",
        {
          method: "POST",
          body: formData,
          headers: {
            authorization: "Bearer " + auth.cookies.get("session")
          }
        }
      )
        .then(res => res.json())
        .then(res => {
          console.log(res.url);
          this.$set(this.report, "src", res.url);
          this.save(false);
        })
        .catch(e => {
          console.error(JSON.stringify(e.message));
        });
    }
  },
  async mounted() {
    this.$router.push("/");
    this.user = await auth.me().catch(() => {});
    await this.load();
  }
};
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.4s ease;
}

.slide-fade-leave-active {
  transition: all 0.4s ease;
}

.slide-fade-enter,
  .slide-fade-leave-to

  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}
</style>
