<template>
  <div class="h-screen flex flex-col md:flex-row">

    <div class="md:w-4/12 border-secondary border-b-8 md:border-r-8 md:border-b-0  h-screen relative overflow-hidden bg-no-repeat bg-center bg-cover" :style="'background-image: url('+bg+');'">
      <div class="absolute top-0 bottom-0 w-full flex flex-row items-center justify-center">
        <img :src="logo" alt="" class="w-8/12 md:w-10/12">
      </div>
    </div>
    <div class="w-full md:w-8/12 h-screen flex flex-col items-center justify-between py-20">
      <div class="w-8/12 flex flex-row items-center justify-start">
      </div>
      <div class="w-8/12">
        <h2 class="md:text-5xl tracking-tighter font-semibold sm:text-4xl text-secondary">The Forge</h2>
        <p class="text-gray-800">Access all your answers, reports and insights.</p>

        <div v-if="!mail_sent">
          <input type="text" class="px-4 text-sm py-3 border border-gray-300 w-full rounded-lg mt-10" placeholder="Email address" v-model="input.email">
          <button @click="login()" class="w-full px-3 mt-8 bg-branding text-white py-4 rounded-lg shadow-lg hover:shadow-xl transition duration-200">Send login link</button>
          <div class="text-xs mt-3 text-gray-800">
            We'll send you an email with a magic link which you can click to login.
          </div>
        </div>
        <div v-else class="mt-14">
          Mail is on it's way. Please check your inbox.
        </div>

        <div class="relative py-3">
          <p v-if="error" class="absolute text-red-500 mt-5 font-medium text-sm outline-none">{{error}}</p>
        </div>
      </div>
      <div class="w-8/12">
        <a href="https://theforge.be" class="text-gray-800 text-sm">&#169; 2021 - 2022 TheForge.be </a>
      </div>
    </div>
  </div>
</template>

<script>
import auth from "@/helpers/auth";

export default {
  name: "login",
  data() {
    return {
      //bg: require("@/assets/images/background.svg"),
      bg: "https://cdn.theforge.be/branding/groenpatroon.svg",
      logo: require("@/assets/images/logo_oranje.svg"),
      error: false,
      error_animation: false,
      mail_sent: false,
      input: {
        email: ""
      }
    };
  },
  methods: {
    async login() {
      const user = await auth.login(this.input.email);
      console.log(user);

      // Check if the user is set (which should, otherwise we should have an error)
      if (user && user.success) {
        //alert('Mail is on its way');
        this.mail_sent = true;
        this.error = "";
      } else {
        this.error = user.error;
      }
    }
  },
  async mounted() {
    this.user = await auth.me().catch(() => {});
    console.log(this.user);
    if (this.user && this.user._id) {
      console.log("Already loggedin... you can't do this twice");
      this.$store.commit("setUser", this.user);
      this.$router.push("/results");
    }
  },
  created() {
    window.addEventListener("keydown", e => {
      if (e.key == "Enter") {
        this.login();
      }
    });
  }
};
</script>
