<template>
  <div class="relative max-w-6xl mx-auto md:px-8 xl:px-0">
    <div class="pt-5">
      <div class="space-x-1 flex items-start justify-between">
        <div>
          <h3 class="text-lg leading-6 font-medium text-branding">
            1. General settings
          </h3>
          <p class="max-w-2xl text-sm text-gray-800">
            General settings for the entire survey.
          </p>
        </div>
        <div class="flex space-x-3">
          <button @click="save()" type="button" class="btn-primary">
            Save
          </button>
          <button @click="remove()" type="button" class="btn-danger">
            Delete
          </button>
        </div>
      </div>
      <div class="divide-y divide-tertiary">
        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
          <dt class="text-sm font-medium text-gray-800">Name</dt>
          <dd class="mt-1 flex text-sm text-branding sm:mt-0 sm:col-span-2">
            <input type="text" v-model="object.name" class="flex-grow focus:outline-none border-2 border-tertiary focus:border-branding px-3 py-2 rounded-lg" placeholder="Survey name" />
          </dd>
        </div>
        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
          <dt class="text-sm font-medium text-gray-800">Type</dt>
          <dd class="mt-1 flex text-sm text-branding sm:mt-0 sm:col-span-2">
            <select v-model="object.surveyType" class="flex-grow focus:outline-none border-2 border-tertiary focus:border-branding px-3 py-2 rounded-lg">
              <option value="team">Team level</option>
              <option value="organisation">Organizational level</option>
              <option value="individual">Individual level</option>
            </select>
          </dd>
        </div>
        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
          <dt class="text-sm font-medium text-gray-800">Visual</dt>
          <dd class="mt-1 flex text-sm text-branding sm:mt-0 sm:col-span-2">
            <select v-model="object.visual" class="flex-grow focus:outline-none border-2 border-tertiary focus:border-branding px-3 py-2 rounded-lg">
              <option value="flow">Flow (slideshow)</option>
              <option value="single">
                Single page (all question on 1 page)
              </option>
            </select>
          </dd>
        </div>
        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
          <dt class="text-sm font-medium text-gray-800">
            Description <br />
            <small>(internal)</small>
          </dt>
          <dd class="mt-1 flex text-sm text-branding sm:mt-0 sm:col-span-2">
            <textarea rows="4" v-model="object.desc" type="text" class="flex-grow focus:outline-none border-2 border-tertiary focus:border-branding px-3 py-2 rounded-lg" placeholder="Survey website"></textarea>
          </dd>
        </div>
      </div>
    </div>

    <div class="mt-20 mb-20">
      <div class="space-y-1 flex items-start justify-between">
        <div>
          <h3 class="text-lg leading-6 font-medium text-branding">
            2. Introduction
          </h3>
          <p class="max-w-2xl text-sm text-gray-800">
            Configure the texts that people see when they open this survey.
          </p>
        </div>
      </div>

      <div class="divide-y divide-tertiary">
        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
          <dt class="text-sm font-medium text-gray-800">
            Introduction Dutch (NL) <br />
            <small>You can use HTML</small>
          </dt>
          <dd class="mt-1 flex text-sm text-branding sm:mt-0 sm:col-span-2">
            <textarea rows="4" v-model="object.introNL" type="text" class="flex-grow focus:outline-none border-2 border-tertiary focus:border-branding px-3 py-2 rounded-lg" placeholder="Introduction NL"></textarea>
          </dd>
        </div>

        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
          <dt class="text-sm font-medium text-gray-800">
            Introduction English (EN) <br />
            <small>You can use HTML</small>
          </dt>
          <dd class="mt-1 flex text-sm text-branding sm:mt-0 sm:col-span-2">
            <textarea rows="4" v-model="object.introEN" type="text" class="flex-grow focus:outline-none border-2 border-tertiary focus:border-branding px-3 py-2 rounded-lg" placeholder="Introduction EN"></textarea>
          </dd>
        </div>

        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
          <dt class="text-sm font-medium text-gray-800">
            Introduction French (FR) <br />
            <small>You can use HTML</small>
          </dt>
          <dd class="mt-1 flex text-sm text-branding sm:mt-0 sm:col-span-2">
            <textarea rows="4" v-model="object.introFR" type="text" class="flex-grow focus:outline-none border-2 border-tertiary focus:border-branding px-3 py-2 rounded-lg" placeholder="Introduction FR"></textarea>
          </dd>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/helpers/api";

export default {
  name: "General",
  data() {
    return {
      object: {}
    };
  },
  methods: {
    async save(showAlert = true) {
      await api.put(`/surveys/${this.$route.params.id}`, this.object);
      if (showAlert) alert("survey saved!");
    },
    async load() {
      this.object = await api.get(`/surveys/${this.$route.params.id}`);
    },
    async remove() {
      if (confirm("Are you sure you want to delete this entire survey?")) {
        await api.delete(`/surveys/${this.project._id}`);
        this.$router.push({ path: "/admin/surveys" });
      }
    }
  },
  async mounted() {
    await this.load();
  }
};
</script>
