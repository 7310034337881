<template>
  <div class="w-full min-h-screen z-0 ">
    <div v-if="member" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity cursor-pointer" aria-hidden="true" @click="member = false"></div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <div>
            <div class="mx-auto flex items-center justify-center h-14 w-14 rounded-full bg-green-100">
              <svg class="h-10 w-10 text-green-600" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <!--Generated by IJSVG (https://github.com/iconjar/IJSVG)-->
                <g stroke-linecap="round" stroke-width="1.5" stroke="#323232" fill="none" stroke-linejoin="round">
                  <path d="M11.378,15.62l5.003,5.003c0.413,0.413 1.083,0.413 1.496,0l2.747,-2.747c0.413,-0.413 0.413,-1.083 0,-1.496l-5.003,-5.003"></path>
                  <path d="M18.67,8.32l-3,-3"></path>
                  <path d="M12.62,8.378l-5.001,-5.001c-0.413,-0.413 -1.083,-0.413 -1.496,0l-2.746,2.746c-0.413,0.413 -0.413,1.083 0,1.496l5.001,5.001"></path>
                  <path d="M3.308,19.43l0.717,-2.157c0.098,-0.295 0.264,-0.564 0.484,-0.784l13.283,-13.283c0.391,-0.391 1.024,-0.391 1.414,4.44089e-16l1.586,1.586c0.391,0.391 0.391,1.024 0,1.414l-13.285,13.285c-0.219,0.219 -0.486,0.384 -0.78,0.483l-2.153,0.72c-0.781,0.261 -1.526,-0.482 -1.266,-1.264Z"></path>
                </g>
                <path fill="none" d="M0,0h24v24h-24Z"></path>
              </svg>
            </div>
            <div class="mt-3 text-center sm:mt-5">
              <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                Manage a team member
              </h3>
              <div class="mt-2">
                <p class="text-sm text-gray-500">
                  On this page you can manage a team member.
                </p>
              </div>
            </div>

            <div class="mt-10 mb-20">
              <div>
                <label for="email" class="block text-sm font-medium text-gray-700">Firstname</label>
                <div class="mt-1">
                  <input v-model="member.firstname" type="text" name="firstname" id="email" class="p-2 ring-indigo-500 shadow-sm focus:border-branding block w-full sm:text-sm border-gray-200 rounded-md border-2" placeholder="John" />
                </div>
              </div>

              <div class="mt-4">
                <label for="email" class="block text-sm font-medium text-gray-700">Lastname</label>
                <div class="mt-1">
                  <input v-model="member.lastname" type="text" name="firstname" id="email" class="p-2 ring-indigo-500 shadow-sm focus:border-branding block w-full sm:text-sm border-gray-200 rounded-md border-2" placeholder="Wick" />
                </div>
              </div>

              <div class="mt-4">
                <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
                <div class="mt-1">
                  <input v-model="member.email" type="text" name="email" id="email" class="p-2 ring-indigo-500 shadow-sm focus:border-branding block w-full sm:text-sm border-gray-200 rounded-md border-2" placeholder="John@example.com" aria-describedby="email-description" />
                </div>
              </div>

              <div class="mt-4">
                <label for="email" class="block text-sm font-medium text-gray-700">Function</label>
                <div class="mt-1">
                  <input v-model="member.function" type="text" name="email" id="email" class="p-2 ring-indigo-500 shadow-sm focus:border-branding block w-full sm:text-sm border-gray-200 rounded-md border-2" placeholder="Zaakvoerder" />
                </div>
              </div>

              <div class="mt-4">
                <label for="email" class="block text-sm font-medium text-gray-700">Default Language</label>
                <div class="mt-1">
                  <select v-model="member.language" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-200 rounded-md border-2 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                    <option value="NL">Nederlands</option>
                    <option value="EN">English</option>
                    <option value="FR">Français</option>
                  </select>
                </div>
              </div>

              <div class="mt-4">
                <label for="email" class="block text-sm font-medium text-gray-700">Access to dashboard?</label>
                <div class="mt-1">
                  <select v-model="member.isAdmin" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-200 rounded-md border-2 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                    <option :value="true">True (has access)</option>
                    <option :value="false">False (has no access)</option>
                  </select>
                </div>
                <p class="mt-2 text-sm text-gray-500" id="email-description">
                  Is this user allowed to login?
                </p>
              </div>
              <div class="mt-4">
                <label for="email" class="block text-sm font-medium text-gray-700">Teams</label>
                <div class="mt-1">
                  <fieldset>
                    <div class="bg-white rounded-md space-y-px">
                      <label v-for="team in company.teams" v-bind:key="team.name" class="border-gray-200 rounded-tl-md rounded-tr-md relative border p-4 flex cursor-pointer space-x-2 items-center">
                        <input id="inTeam" v-model="member.teams" type="checkbox" :value="team.id" class="flex-1 p-2 flex-grow-0 cursor-pointer " />
                        <div class="flex flex-col">
                          <span id="privacy-setting-0-label" class="text-gray-900 text-sm font-medium flex-1">
                            {{ team.name }}
                          </span>
                          <span id="privacy-setting-0-description" class="text-gray-500 block text-sm">
                            {{ team.desc }}
                          </span>
                        </div>
                      </label>
                    </div>
                  </fieldset>
                </div>
                <p v-if="false" class="mt-2 text-sm text-gray-500" id="email-description">
                  Give this company a name. You can change it later.
                </p>
              </div>
            </div>
          </div>
          <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
            <button @click="saveMember()" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-branding text-base font-medium text-white focus:outline-none sm:col-start-2 sm:text-sm">
              Save
            </button>
            <button @click="member = false" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:col-start-1 sm:text-sm">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>

    <main class="flex-1 overflow-y-auto focus:outline-none mt-5" tabindex="0">
      <div class="relative max-w-4xl mx-auto md:px-8 xl:px-0">
        <div class="mt-10 mb-20">
          <div class="space-y-1 flex items-start justify-between">
            <div class="w-6/12">
              <h3 class="text-lg leading-6 font-medium text-gray-900">Users</h3>
              <p class="max-w-2xl text-sm text-gray-500">
                Configure everyone who is in this team and those who has access
                to the dashboard. Make sure you add the teams first. When
                uploading csv, please make sure the first row of the csv is the
                header describing each user field. The headers are: <br />
                firstname, lastname, email, language, function, team
              </p>
            </div>

            <form style="display: none" enctype="multipart/form-data">
              <input ref="input" type="file" @change="onFileChange" />
            </form>

            <div class="w-3/12 ml-auto flex flex-row justify-end space-x-2">
              <div @click="$refs.input.click()" class="btn-primary cursor-pointer">
                From CSV
              </div>
              <div @click="addUser()" class="btn-primary cursor-pointer">
                Manually
              </div>
            </div>
          </div>

          <div class="flex flex-col mt-10">
            <div class="-my-2 overflow-x-auto sm:-mx-6">
              <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50">
                      <tr>
                        <th scope="col" class="w-3/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Firstname
                        </th>
                        <th scope="col" class="w-3/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Lastname
                        </th>
                        <th scope="col" class="w-4/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Email
                        </th>
                        <th scope="col" class="w-4/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Language
                        </th>
                        <th scope="col" class="w-4/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Function
                        </th>
                        <th scope="col" class="w-3/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Teams
                        </th>
                        <th scope="col" class="w-2/12 relative px-6 py-3">
                          <span class="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                      <tr v-for="member in company.users" v-bind:key="member._id" @click="selectMember(member)" class="cursor-pointer hover:bg-gray-200">
                        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {{ member.firstname }}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {{ member.lastname }}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {{ member.email }}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {{ member.language }}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {{ member.function }}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {{ (member.teams || []).length }}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium flex flex-row w-full">
                          <div @click.stop="removeMember(member)" class="btn-danger">
                            Remove
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import auth from "@/helpers/auth";
import api from "@/helpers/api";

export default {
  name: "Dashboard",
  data() {
    return {
      popup: false,
      member: false,
      company: {
        teams: [],
        users: []
      }
    };
  },
  methods: {
    generateToken(count) {
      const chars = "acdefhiklmnoqrstuvwxyz0123456789".split("");
      let result = "";
      for (let i = 0; i < count; i += 1) {
        const x = Math.floor(Math.random() * chars.length);
        result += chars[x];
      }
      return result;
    },

    parseLanguage: function(input) {
      if (!input) {
        return "NL";
      } else {
        if (input.toLowerCase().indexOf("nederlands") > -1) {
          return "NL";
        } else if (input.toLowerCase().indexOf("engels") > -1) {
          return "EN";
        } else if (input.toLowerCase().indexOf("english") > -1) {
          return "EN";
        } else if (input.toLowerCase().indexOf("en") > -1) {
          return "EN";
        } else if (input.toLowerCase().indexOf("nl") > -1) {
          return "NL";
        }
      }
    },

    validEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    parseHead(line, delimiter = ",") {
      // parse the line
      const cols = line
        .split(delimiter)
        .map(el => el.toLowerCase().replace(/[^a-z]/g, ""));
      // create a map of the header name to the index of the col
      const map = {};
      const expected = [
        "firstname",
        "lastname",
        "email",
        "language",
        "team",
        "function"
      ];
      expected.forEach(el => (map[el] = cols.indexOf(el)));
      // check if all headers are present
      const missing = expected.find(
        el => !Object.keys(map).includes(el) || map[el] < 0
      );
      if (missing) return alert(`Invalid csv. ${missing} col is missing!`);
      return map;
    },
    parseLine(line, headerMap, delimiter = ",") {
      // get the fields from the line
      const data = line.split(delimiter).map(el => el.replace(/["']/g, ""));
      const user = {};
      // get each data item into the user object
      Object.keys(headerMap).forEach(key => {
        if (key == "email" && !this.validEmail(data[headerMap[key]]))
          user.error = `Invalid email found: "${data[headerMap[key]]}".`;
        user[key] = data[headerMap[key]];
      });
      // check if the user has all required fields
      const missing = [
        "firstname",
        "lastname",
        "email",
        "language",
        "team",
        "function"
      ].find(el => !user[el] || user[el].length <= 0);
      if (missing)
        return {
          error: `Invalid csv. some rows are missing the ${missing} col!`
        };
      return user;
    },
    async addUsers(users) {
      console.log("done", users);
      for (let user of users) {
        // check if their team exists -> otherwise create it
        let team = this.company.teams.find(el => el.name === user.team);
        if (!team) {
          team = { name: user.team, id: this.generateToken(7) };
          this.company.teams.push(team);
        }
        user.teams = [team.id];
        // check if a user by this email exists -> update info
        const index = this.company.users.findIndex(
          el => el.email == user.email
        );
        if (index > -1) this.company.users[index] = user;
        // otherwise create a new one
        else this.company.users.push(user);
      }
      await this.save(false);
      alert("Done loading csv!");
    },
    async onFileChange(e) {
      const users = [];
      const reader = new FileReader();
      let lines = [];
      reader.onload = e => {
        lines = e.target.result.split("\n");
        const delimiter =
          lines[0][Math.max(lines[0].indexOf(","), lines[0].indexOf(";"))];
        // create a map of headers to their col index
        const headerMap = this.parseHead(lines.shift(), delimiter);
        for (const line of lines) {
          // use this map to parse a line into a user
          const user = this.parseLine(line, headerMap, delimiter);
          // is this a valid user?

          if(['NL', 'FR', 'EN'].indexOf(user.language) == -1){
            alert('"'+user.language + '" is not a valid Language (NL, FR, EN). Upload is dus niet mogelijk. Pas deze waarde aan en probeer opnieuw.')
            break;
          }

          if (user.error) {
            alert(user.error);
            break;
          }
          users.push(user);
        }
        // did we manage to parse all the lines?
        if (users.length != lines.length)
          return alert(
            "Something went wrong loading your csv. Please contact support"
          );
        // save the parsed data
        this.addUsers(users);
      };
      reader.readAsText(e.target.files[0]);
    },

    addUser() {
      this.member = { firstname: "", lastname: "", email: "", teams: [] };
      this.company.users.push(this.member);
    },
    async saveMember() {
      this.save(false);
      this.member = false;
      await this.save();
    },
    selectMember(member) {
      this.member = member;
    },
    async removeMember(member) {
      if (
        confirm(
          "Are you sure you want to remove this team member? This action cannot be undone"
        )
      ) {
        this.company.users.splice(this.company.users.indexOf(member), 1);
        return this.save(false);
      }
    },
    async save(showAlert = true) {
      await api.put(`/companies/${this.$route.params.id}`, this.company);
      if (showAlert) alert("Project saved");
    },
    async load() {
      const data = await api.get(`/companies/${this.$route.params.id}`);
      this.company = data;
      if (!this.company.teams) this.company.teams = [];
      if (!this.company.users) this.company.users = [];
    }
  },
  async mounted() {
    this.user = await auth.me().catch(() => {});
    this.load();
  }
};
</script>

<style scoped>
#inTeam {
  @apply bg-gray-500 !important;
}
</style>
