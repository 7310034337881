<template>
  <div class="w-full min-h-screen z-0 ">
    <div v-if="team" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity cursor-pointer" aria-hidden="true" @click="team = false"></div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <div>
            <div class="mx-auto flex items-center justify-center h-14 w-14 rounded-full bg-green-100">
              <svg class="h-10 w-10 text-green-600" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <!--Generated by IJSVG (https://github.com/iconjar/IJSVG)-->
                <g stroke-linecap="round" stroke-width="1.5" stroke="#323232" fill="none" stroke-linejoin="round">
                  <path d="M11.378,15.62l5.003,5.003c0.413,0.413 1.083,0.413 1.496,0l2.747,-2.747c0.413,-0.413 0.413,-1.083 0,-1.496l-5.003,-5.003"></path>
                  <path d="M18.67,8.32l-3,-3"></path>
                  <path d="M12.62,8.378l-5.001,-5.001c-0.413,-0.413 -1.083,-0.413 -1.496,0l-2.746,2.746c-0.413,0.413 -0.413,1.083 0,1.496l5.001,5.001"></path>
                  <path d="M3.308,19.43l0.717,-2.157c0.098,-0.295 0.264,-0.564 0.484,-0.784l13.283,-13.283c0.391,-0.391 1.024,-0.391 1.414,4.44089e-16l1.586,1.586c0.391,0.391 0.391,1.024 0,1.414l-13.285,13.285c-0.219,0.219 -0.486,0.384 -0.78,0.483l-2.153,0.72c-0.781,0.261 -1.526,-0.482 -1.266,-1.264Z"></path>
                </g>
                <path fill="none" d="M0,0h24v24h-24Z"></path>
              </svg>
            </div>
            <div class="mt-3 text-center sm:mt-5">
              <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                Manage a team
              </h3>
              <div class="mt-2">
                <p class="text-sm text-gray-500">
                  On this page you can manage a team.
                </p>
              </div>
            </div>

            <div class="mt-10 mb-20">
              <div>
                <label for="email" class="block text-sm font-medium text-gray-700">Team name</label>
                <div class="mt-1">
                  <input v-model="team.name" type="text" name="email" id="email" class="p-2 ring-indigo-500 shadow-sm focus:border-branding block w-full sm:text-sm border-gray-200 rounded-md border-2" placeholder="Marketing team" aria-describedby="email-description" />
                </div>
                <p v-if="false" class="mt-2 text-sm text-gray-500" id="email-description">
                  Give this company a name. You can change it later.
                </p>
              </div>

              <div class="mt-3">
                <label for="email" class="block text-sm font-medium text-gray-700">Team desc</label>
                <div class="mt-1">
                  <input v-model="team.desc" type="text" name="email" id="email" class="p-2 ring-indigo-500 shadow-sm focus:border-branding block w-full sm:text-sm border-gray-200 rounded-md border-2" placeholder="Everyone from the marketing team" aria-describedby="email-description" />
                </div>
                <p v-if="false" class="mt-2 text-sm text-gray-500" id="email-description">
                  Give this company a name. You can change it later.
                </p>
              </div>
            </div>
          </div>
          <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
            <button @click="saveTeam()" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-branding text-base font-medium text-white focus:outline-none sm:col-start-2 sm:text-sm">
              Save
            </button>
            <button @click="team = false" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:col-start-1 sm:text-sm">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>

    <main class="flex-1 overflow-y-auto focus:outline-none mt-5" tabindex="0">
      <div class="relative max-w-4xl mx-auto md:px-8 xl:px-0">
        <div class="mt-10 mb-20">
          <div class="space-y-1 flex items-start justify-between">
            <div class="w-8/12">
              <h3 class="text-lg leading-6 font-medium text-gray-900">Teams</h3>
              <p class="max-w-2xl text-sm text-gray-500">
                Combine some users and let they be a team. This way, you can
                easily see insights per team.
              </p>
            </div>
            <div @click="addTeam()" class="btn-primary">
              Add team
            </div>
          </div>

          <!-- This example requires Tailwind CSS v2.0+ -->
          <div class="flex flex-col mt-5">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50">
                      <tr>
                        <th scope="col" class="w-3/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Team name
                        </th>
                        <th scope="col" class="w-7/12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Team name
                        </th>
                        <th scope="col" class="w-2/12 relative px-6 py-3">
                          <span class="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                      <tr v-for="(team, index) in company.teams" v-bind:key="index" @click="selectTeam(team)" class="cursor-pointer hover:bg-gray-200">
                        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {{ team.name }}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
                          {{ team.desc }}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium flex flex-row w-full">
                          <div @click.stop="removeTeam(team)" class="btn-danger">
                            Remove
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import auth from "@/helpers/auth";
import api from "@/helpers/api";

export default {
  name: "Dashboard",
  data() {
    return {
      popup: false,
      team: false,
      company: {
        teams: [],
        users: []
      }
    };
  },
  methods: {
    generateToken(count) {
      const chars = "acdefhiklmnoqrstuvwxyz0123456789".split("");
      let result = "";
      for (let i = 0; i < count; i += 1) {
        const x = Math.floor(Math.random() * chars.length);
        result += chars[x];
      }
      return result;
    },
    async save(showAlert = true) {
      await api.put(`/companies/${this.$route.params.id}`, this.company);
      if (showAlert) alert("Project saved");
    },
    async load() {
      const data = await api.get(`/companies/${this.$route.params.id}`);
      this.company = data;
      if (!this.company.teams) this.company.teams = [];
      if (!this.company.users) this.company.users = [];
    },
    async removeTeam(team) {
      if (
        confirm(
          "Are you sure you want to remove this team? This action cannot be undone"
        )
      ) {
        this.company.teams.splice(this.company.teams.indexOf(team), 1);
        await this.save(false);
      }
    },
    async addTeam() {
      this.team = JSON.parse(
        JSON.stringify({ name: "", desc: "", id: this.generateToken(7) })
      );
      this.company.teams.push(this.team);
      await this.save(false);
    },
    selectTeam(team) {
      this.team = team;
    },
    async saveTeam() {
      await this.save(false);
      this.team = false;
    }
  },
  async mounted() {
    this.user = await auth.me().catch(() => {});
    this.load();
  }
};
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.4s ease;
}

.slide-fade-leave-active {
  transition: all 0.4s ease;
}

.slide-fade-enter,
  .slide-fade-leave-to

  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}
</style>
