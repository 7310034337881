<template>
  <div class="w-full">
    <div v-if="false">res: {{value}}</div>

  <div class="w-full flex flex-row space-x-2">


    <select @change="update" v-model="day" class="w-3/12 mt-2 p-2 border rounded-md outline-none">
      <option v-for="d in days" :value="d">{{d}}</option>
    </select>

    <select @change="update" v-model="month" class="w-3/12 mt-2 p-2 border rounded-md outline-none">
      <option v-for="(label, m) in monthLabels" :value="m">{{label}}</option>
    </select>

    <select @change="update" v-model="year" class="w-3/12 mt-2 p-2 border rounded-md outline-none">
      <option v-for="y in years" :value="y">{{y}}</option>
    </select>


    <select @change="update" v-model="hours" class="w-3/12 mt-2 p-2 border rounded-md outline-none">
      <option :value="0">00:00</option>
      <option :value="1">01:00</option>
      <option :value="2">02:00</option>
      <option :value="3">03:00</option>
      <option :value="4">04:00</option>
      <option :value="5">05:00</option>
      <option :value="6">06:00</option>
      <option :value="7">07:00</option>
      <option :value="8">08:00</option>
      <option :value="9">09:00</option>
      <option :value="10">10:00</option>
      <option :value="11">11:00</option>
      <option :value="12">12:00</option>
      <option :value="13">13:00</option>
      <option :value="14">14:00</option>
      <option :value="15">15:00</option>
      <option :value="16">16:00</option>
      <option :value="17">17:00</option>
      <option :value="18">18:00</option>
      <option :value="19">19:00</option>
      <option :value="20">20:00</option>
      <option :value="21">21:00</option>
      <option :value="22">22:00</option>
      <option :value="23">23:00</option>
    </select>


  </div>
</div>
</template>

<script>
export default {
  name: "datepicker",
  props: ['value'],
  data: function() {
    return {
      monthLabels: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      month: 0,
      years: [],
      year: 0,
      hours: 0,
      days: [],
      day: 0,
      date: new Date(),
    };
  },
  watch: {
  },
  methods: {
    update: function(){
      this.days = this.getDaysInMonth(this.month, this.year)
      this.date = new Date(this.year, this.month, this.day, this.hours, 0, 0, 0);
      this.$emit('input', this.date)
    },
    changed: function(){
      this.$emit('input', this.value);
    },
    getDaysInMonth(month, year) {
      var date = new Date(year, month, 1);
      var days = [];
      while (date.getMonth() === month) {
        days.push(date.getDate());
        date.setDate(date.getDate() + 1);
      }
      return days;
    }
  },
  async mounted() {

    console.log('value 1:', this.value)

    if(this.value.toString().indexOf('(') > -1){
      this.date = new Date(this.value.toString().split('(')[0].trim())
      console.log('Updated value:', this.value)
    } else {
      this.date = new Date(this.value);
    }

    this.month = this.date.getMonth();
    this.year = this.date.getFullYear();
    this.hours = this.date.getHours();
    this.day = this.date.getDate();

    this.years = [];
    var startYear = 2022; // todo: make this a prop
    var endYear = 2025; // todo: make this a prop!
    for (var i = startYear; i <= endYear; i++) {
      this.years.push(i)
    }

    this.update();

  }
};
</script>

<style>
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}
</style>
