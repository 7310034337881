<script>
import { Radar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Radar,
  mixins: [reactiveProp],
  props: ["options"],
  mounted() {
    this.renderChart(
      {
        labels: ["Running", "Swimming", "Eating", "Cycling"],
        datasets: [
          {
            data: [20, 10, 4, 2]
          }
        ]
      },
      this.options
    );
  }
};
</script>
