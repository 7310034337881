<template>
  <div class="w-full min-h-screen z-0">
    <main class="flex-1 overflow-y-auto focus:outline-none mt-5" tabindex="0">
      <div class="relative max-w-4xl mx-auto md:px-8 xl:px-0">
        <div class="mt-10">
          <div class="space-y-1 flex items-start justify-between">
            <div>
              <h3 class="text-lg leading-6 font-medium text-branding">
                General settings
              </h3>
              <p class="max-w-2xl text-sm text-gray-800">
                General settings for this company.
              </p>
            </div>

            <div class=" flex flex-row space-x-2">
              <div @click="save()" class="btn-primary">
                Save
              </div>
              <div v-if="user && user.isGod" @click="remove()" class="btn-danger">
                Delete
              </div>
            </div>
          </div>

          <div class="divide-y divide-tertiary">

            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-800">Logo</dt>
              <dd class="mt-1 flex text-sm text-branding sm:mt-0 sm:col-span-2">

                <div @click="$refs.file.click()" ref="avatarPreview" class="ml-auto mr-auto flex flex-col items-center my-4">
                  <div class="w-40 h-40 cursor-pointer bg-white rounded-full">
                    <img class="w-full h-full rounded-full object-cover" :src="company.logo" alt="" />
                  </div>
                  <div class="text-sm mt-2 text-blue-500 cursor-pointer">
                    Change company logo
                  </div>
                  <input @change="setLogo" type="file" ref="file" style="display: none" />
                </div>

              </dd>
            </div>

            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-800">Name</dt>
              <dd class="mt-1 flex text-sm text-branding sm:mt-0 sm:col-span-2">
                <input type="text" v-model="company.name" class="block w-full pl-3 pr-10 py-2 text-base border-tertiary h-full border-2 focus:outline-none focus:ring-indigo-500 focus:border-branding sm:text-sm rounded-md" placeholder="Company name" />
              </dd>
            </div>

            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-800">Website</dt>
              <dd class="mt-1 flex text-sm text-branding sm:mt-0 sm:col-span-2">
                <input type="text" v-model="company.website" class="block w-full pl-3 pr-10 py-2 text-base border-tertiary h-full border-2 focus:outline-none focus:ring-indigo-500 focus:border-branding sm:text-sm rounded-md" placeholder="https://theforge.be" />
              </dd>
            </div>

            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-800">SPOC Name</dt>
              <dd class="mt-1 flex text-sm text-branding sm:mt-0 sm:col-span-2">
                <input type="text" v-model="company.spocName" class="block w-full pl-3 pr-10 py-2 text-base border-tertiary h-full border-2 focus:outline-none focus:ring-indigo-500 focus:border-branding sm:text-sm rounded-md" placeholder="John Lennon" />
              </dd>
            </div>

            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-800">SPOC Email</dt>
              <dd class="mt-1 flex text-sm text-branding sm:mt-0 sm:col-span-2">
                <input type="text" v-model="company.spocEmail" class="block w-full pl-3 pr-10 py-2 text-base border-tertiary h-full border-2 focus:outline-none focus:ring-indigo-500 focus:border-branding sm:text-sm rounded-md" placeholder="John@lennon.com" />
              </dd>
            </div>

          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import auth from "@/helpers/auth";
import api from "@/helpers/api";

import env from "../../../../env";

export default {
  name: "Dashboard",
  data() {
    return {
      popup: false,
      team: false,
      member: false,
      company: {
        teams: [],
        users: []
      },
      user: {},
      page: "general"
    };
  },
  methods: {
    setLogo: function(e) {
      let droppedFiles = e.target.files;
      if (!droppedFiles) return;
      if (droppedFiles.length !== 1) return;
      let formData = new FormData();
      formData.append("file", droppedFiles[0]);
      fetch(env.apiURL + "/companies/" + this.company._id + "/logo", {
        method: "PUT",
        body: formData,
        headers: {
          authorization: "Bearer " + auth.cookies.get("session")
        }
      })
        .then(() => {
          document.location.reload();
        })
        .catch(e => {
          console.error(JSON.stringify(e.message));
        });
    },
    async remove() {
      if (confirm("Are you sure you want to delete this entire company?")) {
        await api.delete(`/companies/${this.company._id}`);
        this.$router.push({ path: "/admin/companies" });
      }
    },
    async save(showAlert = true) {
      await api.put(`/companies/${this.$route.params.id}`, this.company);
      if (showAlert) alert("Project saved");
    },
    async load() {
      const data = await api.get(`/companies/${this.$route.params.id}`);
      this.company = data;
      if (!this.company.teams) this.company.teams = [];
      if (!this.company.users) this.company.users = [];
    }
  },
  async mounted() {
    this.user = await auth.me().catch(() => {});
    await this.load();
  }
};
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.4s ease;
}

.slide-fade-leave-active {
  transition: all 0.4s ease;
}

.slide-fade-enter,
  .slide-fade-leave-to

  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}
</style>
