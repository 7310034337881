<template>
  <div class="w-full min-h-screen z-0">

    <div class="border-b border-tertiary relative h-14 bg-tertiary flex flex-row items-center px-20">
      <div class="w-10/12 h-14 flex flex-row mx-auto">
        <router-link to="/admin/emails" class="text-sm h-full flex flex-row items-center justify-center w-24 cursor-pointer border-b-2 border-transparent text-center" :class="page == '/admin/surveys' ? 'border-branding' : ''">
          <svg class="h-4 w-4" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <!--Generated by IJSVG (https://github.com/iconjar/IJSVG)-->
            <g stroke-linecap="round" stroke-width="1.5" stroke="#323232" fill="none" stroke-linejoin="round">
              <path d="M5,12h14"></path>
              <path d="M10,7l-5,5"></path>
              <path d="M10,17l-5,-5"></path>
            </g>
            <path fill="none" d="M0,0h24v24h-24Z"></path>
          </svg>
          <div>Back</div>
        </router-link>
        <div @click="page = 'general'" class="text-sm h-full flex flex-row items-center justify-center w-24 cursor-pointer border-b-2 border-transparent text-center" :class="page == 'general' ? 'border-branding' : ''">
          <div>General</div>
        </div>
        <div @click="page = 'template'" class="text-sm h-full flex flex-row items-center justify-center w-24 cursor-pointer border-b-2 border-transparent text-center" :class="page == 'template' ? 'border-branding' : ''">
          <div>Template</div>
        </div>
      </div>
    </div>

    <main class="flex-1 overflow-y-auto focus:outline-none mt-5" tabindex="0">
      <div class="relative max-w-4xl mx-auto md:px-8 xl:px-0">
        <div class="mt-10">
          <div class="flex justify-between">
            <div v-if="page == 'general'">
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                General settings
              </h3>
              <p class="max-w-2xl text-sm text-gray-500">
                General settings for this text.
              </p>
            </div>

            <div v-if="page == 'template'">
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                Your template
              </h3>
              <p class="max-w-2xl text-sm text-gray-500">
                Change here your template here.
              </p>
            </div>

            <div class="flex flex-row">
              <div @click="save()" class="p-2 px-4 bg-branding rounded m-1 text-sm text-white text-center cursor-pointer">
                Save
              </div>
              <div @click="remove()" class="p-2 px-4 bg-red-600 rounded m-1 text-sm text-white text-center cursor-pointer">
                Delete
              </div>
            </div>
          </div>

          <div v-if="page == 'general'" class="divide-y divide-branding">
            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">Id</dt>
              <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {{ template.id }}
              </dd>
            </div>

            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">Name</dt>
              <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <input type="text" v-model="template.name" class="flex-grow focus:outline-none bg-white px-3 py-2 rounded-lg" placeholder="Text name" />
              </dd>
            </div>

            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">
                Description <br />
                <small>(internal)</small>
              </dt>
              <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <textarea rows="4" v-model="template.desc" type="text" class="flex-grow focus:outline-none bg-white px-3 py-2 rounded-lg" placeholder="Text description"></textarea>
              </dd>
            </div>

            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">
                Notes <br />
                <small>(internal)</small>
              </dt>
              <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <textarea rows="4" v-model="template.notes" type="text" class="flex-grow focus:outline-none bg-white px-3 py-2 rounded-lg" placeholder="Internal notes (eg. page)"></textarea>
              </dd>
            </div>
          </div>

          <div v-if="page == 'template'" class="divide-y divide-branding">
            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">Head NL</dt>
              <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <input type="text" v-model="template.headNL" class="flex-grow focus:outline-none bg-white px-3 py-2 rounded-lg" placeholder="Text name" />
              </dd>
            </div>

            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">Body NL</dt>
              <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <textarea rows="4" v-model="template.bodyNL" type="text" class="flex-grow focus:outline-none bg-white px-3 py-2 rounded-lg" placeholder="Text Dutch"></textarea>
              </dd>
            </div>

            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">Head EN</dt>
              <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <input type="text" v-model="template.headEN" class="flex-grow focus:outline-none bg-white px-3 py-2 rounded-lg" placeholder="Text name" />
              </dd>
            </div>

            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">Body EN <br /></dt>
              <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <textarea rows="4" v-model="template.bodyEN" type="text" class="flex-grow focus:outline-none bg-white px-3 py-2 rounded-lg" placeholder="Text English"></textarea>
              </dd>
            </div>

            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">Head FR</dt>
              <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <input type="text" v-model="template.headFR" class="flex-grow focus:outline-none bg-white px-3 py-2 rounded-lg" placeholder="Text name" />
              </dd>
            </div>

            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt class="text-sm font-medium text-gray-500">Body FR <br /></dt>
              <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <textarea rows="4" v-model="template.bodyFR" type="text" class="flex-grow focus:outline-none bg-white px-3 py-2 rounded-lg" placeholder="Text French"></textarea>
              </dd>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import auth from "@/helpers/auth";
import api from "@/helpers/api";

export default {
  name: "Dashboard",
  data() {
    return {
      user: {},
      template: {
        id: "", // homepage_1
        name: "",
        desc: "",
        notes: "",
        headNL: "",
        headFR: "",
        headEN: "",
        bodyNL: "",
        bodyFR: "",
        bodyEN: ""
      },
      page: "general"
    };
  },
  methods: {
    async remove() {
      if (confirm("Are you sure you want to delete this entire template?")) {
        await api.delete("/emails/" + this.template._id);
        this.$router.push({ path: "/admin/emails" });
      }
    },

    async save(showAlert = true) {
      await api.put("/emails/" + this.$route.params.id, this.template);
      await this.load();
      if (showAlert) alert("Project saved");
    },

    async load() {
      this.template = await api.get("/emails/" + this.$route.params.id);
      console.log(this.template);
    }
  },
  watch: {
    "template.name"() {
      this.template.id = this.template.name
        .toLowerCase()
        .replace(/ +/g, "_")
        .replace(/[^a-z_]/g, "");
    }
  },
  async mounted() {
    this.user = await auth.me().catch(() => {});
    this.load();
  }
};
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.4s ease;
}

.slide-fade-leave-active {
  transition: all 0.4s ease;
}

.slide-fade-enter,
  .slide-fade-leave-to

  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}
</style>
