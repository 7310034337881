<template>
  <div class="h-screen flex flex-row">
    <div
      class="sm:hidden md:block md:w-4/12 bg-white h-screen relative overflow-hidden bg-no-repeat bg-center bg-cover"
      :style="'background-image: url(' + bg + ');'"
    >
      <div
        class="absolute top-0 bottom-0 w-full flex flex-row items-center justify-center"
      >
        <img :src="logo" alt="" class="w-10/12" />
      </div>
    </div>
    <div
      class="w-8/12 sm:w-full md:w-8/12 bg-white h-screen flex flex-col items-center justify-between py-20"
    >
      <div class="w-8/12 flex flex-row items-center justify-start"></div>
      <div class="w-8/12">
        <h2 class="lg:text-5xl tracking-tighter font-semibold sm:text-4xl">
          TheForge.be
        </h2>
        <p class="text-gray-600">
          Access all your answers, reports and insights.
        </p>

        <div class="mt-14">Loading...</div>

        <div class="relative py-3">
          <p
            v-if="error"
            class="absolute text-red-500 mt-5 font-medium text-sm outline-none"
          >
            {{ error }}
          </p>
        </div>
      </div>
      <div class="w-8/12">
        <a href="https://theForge.be" class="text-gray-400 text-sm"
          >&#169; 2020 - 2022 TheForge.be
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import auth from "@/helpers/auth";

export default {
  name: "login",
  data() {
    return {
      bg: require("@/assets/images/background.jpeg"),
      logo: require("@/assets/images/logo_oranje1.svg"),
      error: false,
    };
  },
  methods: {},
  async mounted() {
    //alert(this.$route.params.token);
    auth.cookies.set("session", this.$route.params.token);
    this.$router.push("/results");
  },
};
</script>
