<template>
  <div class="mx-auto md:px-8 xl:px-0 min-h-screen z-0">
    <div class="container xl:max-w-screen mx-auto h-full flex flex-col pt-5">
      <div class="w-full flex flex-row items-start justify-between">
        <div>
          <h3 class="font-semibold text-3xl text-branding">Algorithm</h3>
          <p class="text-gray-800 font-medium">
            Configure all the expressions here.
          </p>
          <br />
          <p class="text-gray-800 font-medium">
            To refrence a value in a calculation, prefix it with $.
            <br />"$bf_q3" will refer to the third answer of the big five survey (These id's are manually editable, so can be really different).
          </p>
          <p class="text-gray-800 font-medium">
            The autocomplete on a field will give you all available variables to
            refrence
          </p>
          <p class="text-gray-800 font-medium">
            You can also wrap a part of an expression with 'sum()'.
            <br> This will replace all answers with the sum of all answers for that question.
          </p>
          <p class="text-gray-800 font-medium">
            <br>
            When writing a logical expression (IF), you can use the following keywords: AND, OR, NOT.
            <br> These are logical operators, so (a AND b) will only evaluate true if both a and b are true.
            <br> Likewise, (a OR b) will evaluate true if just one or both of a or b is true.
            <br> NOT will negate anything that comes after it (NOT a) will evaluate to the opposite of a.
          </p>
          <p>
            <br>
            These expressions will be evaluated by a computer, so using a lot of brackets '(' and ')' to enforce the order of operations is advisable.
          </p>
        </div>
        <div class="flex flex-row items-center justify-end space-x-4">
          <div v-if="false" class="relative flex flex-row items-center w-64">
            <input type="text" placeholder="Search expression" v-model="q" class="bg-white rounded-md text-sm px-3 py-2 pl-8 w-full" />
            <svg class="absolute left-0 ml-2 text-gray-600 w-5 h-5" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <g fill="none">
                <path d="M0,0h24v24h-24Z"></path>
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15.7138,6.8382c2.45093,2.45093 2.45093,6.42467 0,8.8756c-2.45093,2.45093 -6.42467,2.45093 -8.8756,0c-2.45093,-2.45093 -2.45093,-6.42467 -8.88178e-16,-8.8756c2.45093,-2.45093 6.42467,-2.45093 8.8756,-8.88178e-16"></path>
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19,19l-3.29,-3.29"></path>
              </g>
            </svg>
          </div>
          <button @click="addExpression()" class="btn-primary">
            Add Expression
          </button>
        </div>
      </div>

      <!--CUSTOM AUTOCOMPLETE ENTRIES -> all vars + all questions id's + all text id's -->
      <!--TODO -> make items dynamic: prefix with current value to allow multiple options from list-->
      <datalist id="autocomplete">
        <option :value="option" v-for="option in autocompleteOptions" :key="option"></option>
      </datalist>

      <!--TABLE-->
      <div class="mt-10 h-full w-full rounded-md">
        <div class="flex flex-col w-full">
          <div class="w-full">
            <div class="py-2 align-middle inline-block w-full">
              <div class="shadow-xl border-b border-gray-200 sm:rounded-xl mb-10">
                <div class="w-full divide-y">
                  <div class="bg-gray-50">
                    <div class="flex flex-row justify-start items-center rounded-md">
                      <div class="px-6 py-3 text-left text-xs font-medium text-branding uppercase tracking-wider w-2/12">
                        Name
                      </div>
                      <div class="px-6 py-3 text-left text-xs font-medium text-branding uppercase tracking-wider w-2/12">
                        Type
                      </div>
                      <div class="px-6 py-3 text-left text-xs font-medium text-branding uppercase tracking-wider w-8/12">
                        Expression
                      </div>
                      <div class="relative px-6 py-3 w-1/12">
                        <span class="sr-only">Edit</span>
                      </div>
                    </div>
                  </div>
                  <div class="bg-white divide-y divide-gray-100">
                    <div v-for="(expression, index) in survey.algorithm" :key="index" :class="{ 'bg-gray-50': !(index % 2 == 0) }" class="transition duration-200 flex flex-row justify-between items-center list-group-item relative">
                      <div class="px-6 py-4 whitespace-nowrap text-m w-2/12">
                        <input @keyup="updateExpressionId(index)" @change="updateExpressionId(index)" v-model="survey.algorithm[index].name" type="text" class="w-full text-gray-600" />
                      </div>
                      <div class="px-6 py-4 whitespace-nowrap text-m w-2/12">
                        <select @keyup="save" @change="save" v-model="expression.operation" class="bg-white font-bold flex-grow-0">
                          <option disabled value="">TYPE</option>
                          <option value="logic">IF</option>
                          <option value="math">MATH</option>
                        </select>
                      </div>
                      <div class="px-6 py-4 text-md font-bold w-8/12 flex items-center space-x-2">
                        <p class="text-gray-600">
                          ${{ expression.id }}
                        </p>
                        <p>=</p>
                        <!--LOGIC-->
                        <div v-if="expression.operation == 'logic'" class="flex items-center w-full space-x-2">
                          <p>(</p>
                          <input v-model="expression.parts[0]" @keyup="buildExpression(index)" @change="buildExpression(index)" list="autocomplete" type="text" placeholder="$A > $B" class="w-full px-2" />
                          <p>)</p>
                          <p>THEN</p>
                          <input list="autocomplete" v-model="expression.parts[1]" @keyup="buildExpression(index)" @change="buildExpression(index)" type="text" placeholder="$C" class="w-full px-2" />
                          <p>ELSE</p>
                          <input list="autocomplete" v-model="expression.parts[2]" @keyup="buildExpression(index)" @change="buildExpression(index)" type="text" placeholder="$D" class="w-full px-2" />
                        </div>
                        <!--MATH-->
                        <div v-if="expression.operation == 'math'" class="flex items-center w-full">
                          <p>(</p>
                          <input v-model="expression.expression" @keyup="save" @change="save" list="autocomplete" type="text" placeholder="$A + $B" class="w-full px-2" />
                          <p>)</p>
                        </div>
                      </div>
                      <div class="px-6 py-4 whitespace-nowrap text-right text-sm flex flex-row items-center justify-end font-medium w-1/12 space-x-2">
                        <button @click="removeExpression(index)" type="button" class="bg-red-100 rounded-full p-2 font-semibold text-red-500 hover:bg-gray-200 group transition duration-500 flex flex-row items-center justify-start space-x-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                          <svg class="w-5 h-5 group-hover:text-red-600 transition duration-500" version="1.1" id="Layer_3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
                            <g>
                              <rect fill="none" width="24" height="24" />
                              <g>
                                <path fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" d="M18,6v12.75 c0,1.243-1.027,2.25-2.269,2.25h-7.5C6.988,21,6,19.993,6,18.75V6" />
                                <line fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" x1="19.5" y1="6" x2="4.5" y2="6" />
                                <line fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" x1="10" y1="3" x2="14" y2="3" />
                                <line fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" x1="14" y1="10" x2="14" y2="17" />
                                <line fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" x1="10" y1="17" x2="10" y2="10" />
                              </g>
                            </g>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/helpers/api";

export default {
  name: "Algorithm",
  data() {
    return {
      survey: {
        algorithm: [],
        reports: []
      },
      texts: [],
      questions: []
    };
  },
  methods: {
    async addExpression() {
      this.survey.algorithm.push({
        name: "",
        id: "",
        expression: "",
        operation: "math",
        parts: []
      });
      console.log(this.survey.algorithm);
      await this.save();
    },
    async buildExpression(index) {
      this.survey.algorithm[index].expression = this.survey.algorithm[
        index
      ].parts.join("$$");
      await this.save();
    },
    async updateExpressionId(index) {
      this.survey.algorithm[index].id = this.survey.algorithm[index].name
        .toUpperCase()
        .replace(/ +/g, "_")
        .replace(/[^0-9A-Z_]/g, "")
        .replace(/^[0-9]+/, ""); // no leading numbers
      await this.save();
    },
    async removeExpression(index) {
      this.survey.algorithm = this.survey.algorithm.filter(
        (el, i) => i != index
      );
      await this.save();
    },
    async save() {
      await api.put(`/surveys/${this.$route.params.id}`, this.survey);
    },
    async load() {
      this.survey = await api.get(`/surveys/${this.$route.params.id}`);
      this.survey.algorithm = this.survey.algorithm.map(el => {
        el.parts = el.expression.split("$$");
        return el;
      });
      this.texts = (await api.get("/texts")).data;
      this.questions = await api.get(`/questions?survey=${this.survey._id}`);
    }
  },
  computed: {
    autocompleteOptions() {
      // autocomplete options for all vars, questionIds, textids and operations
      // TODO autocomplete for multiple values!!
      return ["+", "-", "*", "/", "ABS"]
        .concat(this.survey.algorithm.map(el => `$${el.id}`))
        .concat(this.texts.map(el => `$${el.id}`))
        .concat(this.survey.reports.map(el => `$${el.id}`))
        .concat(
          this.questions.reduce((ids, el) => {
            if (/likert/.test(el.answerType))
              ids = ids.concat(el.options.map(el => `$${el.id}`));
            else ids.push(el.id);
            return ids;
          }, [])
        );
    }
  },
  async mounted() {
    await this.load();
  }
};
</script>
