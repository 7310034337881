<template>

  <div>

    <!-- This example requires Tailwind CSS v2.0+ -->
    <div v-if="popup" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity cursor-pointer" aria-hidden="true" @click="popup=false"></div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <div>
            <div class="mx-auto flex items-center justify-center h-14 w-14 rounded-full bg-green-100">
              <!-- Heroicon name: outline/check -->

              <svg class="h-10 w-10 text-green-600" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <!--Generated by IJSVG (https://github.com/iconjar/IJSVG)-->
                <g stroke-linecap="round" stroke-width="1.5" stroke="#323232" fill="none" stroke-linejoin="round">
                  <path d="M11.378,15.62l5.003,5.003c0.413,0.413 1.083,0.413 1.496,0l2.747,-2.747c0.413,-0.413 0.413,-1.083 0,-1.496l-5.003,-5.003"></path>
                  <path d="M18.67,8.32l-3,-3"></path>
                  <path d="M12.62,8.378l-5.001,-5.001c-0.413,-0.413 -1.083,-0.413 -1.496,0l-2.746,2.746c-0.413,0.413 -0.413,1.083 0,1.496l5.001,5.001"></path>
                  <path d="M3.308,19.43l0.717,-2.157c0.098,-0.295 0.264,-0.564 0.484,-0.784l13.283,-13.283c0.391,-0.391 1.024,-0.391 1.414,4.44089e-16l1.586,1.586c0.391,0.391 0.391,1.024 0,1.414l-13.285,13.285c-0.219,0.219 -0.486,0.384 -0.78,0.483l-2.153,0.72c-0.781,0.261 -1.526,-0.482 -1.266,-1.264Z"></path>
                </g>
                <path fill="none" d="M0,0h24v24h-24Z"></path>
              </svg>

            </div>
            <div class="mt-3 text-center sm:mt-5">
              <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                Create new company
              </h3>
              <div class="mt-2">
                <p class="text-sm text-gray-500">
                  To create a new company, you should give us some information like the name of this company. You can add users and other information later on.
                </p>
              </div>
            </div>

            <div class="mt-10 mb-20">
              <div>
                <label for="email" class="block text-sm font-medium text-gray-700">Company name</label>
                <div class="mt-1">
                  <input v-model="company.name" type="text" name="email" id="email" class="p-2 ring-indigo-500 shadow-sm focus:border-branding block w-full sm:text-sm border-gray-200 rounded-md border-2" placeholder="The Forge BVBA" aria-describedby="email-description">
                </div>
                <p class="mt-2 text-sm text-gray-500" id="email-description">Give this company a name. You can change it later.</p>
              </div>

            </div>

          </div>
          <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
            <button @click="create" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-branding text-base font-medium text-white focus:outline-none sm:col-start-2 sm:text-sm">
              Create company
            </button>
            <button @click="popup=false" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none  sm:mt-0 sm:col-start-1 sm:text-sm">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full min-h-screen z-0">

      <div class="container mx-auto flex flex-col p-10 z-10 h-full pb-32">
        <div class="flex flex-row items-center justify-between">
          <div>
            <h3 class="font-semibold text-3xl text-gray-800">{{data.length}} Companies</h3>
          </div>

          <div v-if="user && user.isGod" class="ml-auto">
            <button @click="popup=true" type="button" class="btn-primary">
              Create new company
            </button>
          </div>
        </div>

        <div class="w-full flex justify-end mt-8">
          <div class="max-w-lg w-full lg:max-w-xs">
            <label for="search" class="sr-only">Search</label>
            <div class="relative">
              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                </svg>
              </div>
              <input v-model="query.q" class="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="Search" type="search">
            </div>
          </div>
        </div>

        <div class="mt-4">
          <!-- This example requires Tailwind CSS v2.0+ -->
          <div class="flex flex-col">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50">
                      <tr>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          #
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Name
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Description
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          VAT
                        </th>
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                      <tr @click="select(res)" class="cursor-pointer hover:bg-gray-200" v-for="(res, index) in data" v-bind:key="res._id">
                        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {{index+1}}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {{res.name}}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {{res.desc}}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 capitalize">
                          {{res.vat}}
                        </td>
                      </tr>

                      <!-- More rows... -->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
import auth from "@/helpers/auth";
import api from "@/helpers/api";

export default {
  name: "Dashboard",
  data: () => ({
    user: {},
    data: [],
    popup: false,
    company: {},
    query: {
      q: ""
    },
    debounceTimeout: undefined
  }),
  methods: {
    select(row) {
      this.$router.push({ path: "/admin/companies/" + row._id });
    },
    async load() {
      const query = Object.keys(this.query).reduce(
        (query, key) => (query = `${query}&${key}=${this.query[key]}`),
        "?"
      );
      this.data = (await api.get(`/companies${query}`)).data;
    },
    async create() {
      await api.post("/companies", this.company);
      this.popup = false;
      await this.load();
    }
  },
  watch: {
    "query.q"() {
      if (this.debounceTimeout) clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => this.load(), 500);
    }
  },
  async mounted() {
    this.user = await auth.me().catch(function(e) {
      console.log("NOT LOGGEDIN", e);
    });

    this.load();
  }
};
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.4s ease;
}

.slide-fade-leave-active {
  transition: all 0.4s ease;
}

.slide-fade-enter,
  .slide-fade-leave-to

  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}
</style>
