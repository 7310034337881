<template>
  <div class="w-full min-h-screen min-h-full z-0 ">
    <main class="flex-1 overflow-y-auto focus:outline-none mt-5 mb-20" tabindex="0">
      <div class="relative max-w-4xl mx-auto md:px-8 xl:px-0">
        <div class="mt-20 mb-10">
          <div class="bg-background flex items-center space-x-2 cursor-pointer mb-4">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
            </svg>
            <router-link :to="`/results/${$route.params.id}`">Go back</router-link>
          </div>

          <!--TITLE-->
          <div class="space-y-1 mt-8 flex items-start justify-between">
            <div v-if="step == 1">
              <h3 class="text-2xl leading-6 font-medium text-branding">
                Hello {{user.firstname}},
              </h3>
              <p class="mt-2 max-w-2xl text-sm text-gray-800">
                Edit the reminder templates, or send them out as is!
              </p>
            </div>
            <div v-if="step == 2">
              <h3 class="text-2xl leading-6 font-medium text-branding">
                Who needs reminding?
              </h3>
              <p class="mt-2 max-w-2xl text-sm text-gray-800">
                Select the users that need a quick reminder about the survey!
              </p>
            </div>

            <h3 class="text-4xl opacity-50 font-medium flex-shrink-0 w-3/12 text-right">Step {{step}}/2</h3>

          </div>

          <!--EMAILS-->
          <div v-if="step == 1" class="flex flex-col mt-4 divide-y divide-tertiary">
            <div class="text-gray-800 text-sm"></div>
            <div v-for="language in ['NL', 'EN', 'FR']" :key="language" class="mt-4">
              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 ">
                <dt class="text-sm font-medium text-gray-800">Head {{language}}</dt>
                <dd class="mt-1 flex text-sm text-branding sm:mt-0 sm:col-span-2">
                  <input type="text" v-model="test.mails.reminder['subject'+language]" class="border-2 border-tertiary focus:border-branding flex-grow focus:outline-none bg-white px-3 py-2 rounded-lg" placeholder="Text name" />
                </dd>
              </div>

              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt class="text-sm font-medium text-gray-800">Body {{language}}</dt>
                <dd class="mt-1 flex text-sm text-branding sm:mt-0 sm:col-span-2">
                  <textarea rows="4" v-model="test.mails.reminder['message'+language]" type="text" class="border-2 border-tertiary focus:border-branding flex-grow focus:outline-none bg-white px-3 py-2 rounded-lg" placeholder="Text Dutch"></textarea>
                </dd>
              </div>
            </div>
          </div>

          <!--WHO-->
          <div v-if="step == 2" class="flex flex-col mt-4 divide-y divide-tertiary">

            <table class="border-branding border-2">
              <tr class="border-branding border">
                <th class="p-1 border border-branding font-bold">Send reminder</th>
                <th class="p-1 border border-branding font-bold">Survey complete</th>
                <th class="p-1 border border-branding font-bold">Email</th>
              </tr>

              <tr class="" v-for="answer in answers" v-bind:key="answer._id" class="border-t border-branding">
                <td class="p-1 border-r border-branding text-center items-center flex justify-center">
                  <button @click="toggleReminder(answer)" :class="[reminders.includes(answer.email) ? 'bg-branding' : 'bg-tertiary', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-branding']" type="button" role="switch" aria-checked="false" aria-labelledby="availability-label">
                    <span class="sr-only">Use setting</span>
                    <span aria-hidden="true" :class="[reminders.includes(answer.email) ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" class="translate-x-0 pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
                  </button>

                </td>
                <td class="p-1 border-r border-branding">{{ answer.complete ? "Yes" : "No" }}</td>
                <td class="p-1">
                  {{ answer.email }}
                </td>
              </tr>
            </table>
          </div>

          <!--NAVIGATION-->
          <div class="w-full flex flex-row">
            <div v-if="step == 1" class="w-3/12 ml-auto flex flex-row">

              <div class="mt-3 flex flex-row w-full">
                <div @click="nextStep()" :class="{'bg-tertiary': !validForm }" class="text-sm flex flex-row cursor-pointer justify-center items-center ml-auto bg-branding text-white rounded p-2">
                  <div>next step</div>
                  <svg class="ml-2 h-3 w-3 text-white" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <!--Generated by IJSVG (https://github.com/iconjar/IJSVG)-->
                    <g stroke-linecap="round" stroke-width="1.5" stroke="white" fill="none" stroke-linejoin="round">
                      <path d="M19,12h-14"></path>
                      <path d="M14,17l5,-5"></path>
                      <path d="M14,7l5,5"></path>
                    </g>
                    <path fill="none" d="M0,0h24v24h-24Z"></path>
                  </svg>
                </div>
              </div>
            </div>

            <div v-if="step == 2" class="ml-auto flex flex-row space-x-2 w-4/12">

              <div @click="prevStep()" class="mt-3 flex flex-row w-full">
                <div class="text-sm flex flex-row cursor-pointer justify-center items-center ml-auto bg-branding text-white rounded p-2">
                  <svg class="mr-2 h-3 w-3 text-white" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <!--Generated by IJSVG (https://github.com/iconjar/IJSVG)-->
                    <g stroke-linecap="round" stroke-width="1.5" stroke="white" fill="none" stroke-linejoin="round">
                      <path d="M5,12h14"></path>
                      <path d="M10,7l-5,5"></path>
                      <path d="M10,17l-5,-5"></path>
                    </g>
                    <path fill="none" d="M0,0h24v24h-24Z"></path>
                  </svg>
                  <div>Go Back</div>
                </div>
              </div>

              <div @click="submit()" class="mt-3 flex flex-row w-full">
                <div :class="{'bg-tertiary': busy}" class="text-sm flex flex-row cursor-pointer justify-center items-center ml-auto bg-branding text-white rounded p-2">
                  <div>Send reminders</div>
                  <svg class="ml-2 h-3 w-3 text-white" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <!--Generated by IJSVG (https://github.com/iconjar/IJSVG)-->
                    <g stroke-linecap="round" stroke-width="1.5" stroke="white" fill="none" stroke-linejoin="round">
                      <path d="M19,12h-14"></path>
                      <path d="M14,17l5,-5"></path>
                      <path d="M14,7l5,5"></path>
                    </g>
                    <path fill="none" d="M0,0h24v24h-24Z"></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </main>
  </div>
</template>

<script>
import auth from "@/helpers/auth";
import api from "@/helpers/api";

export default {
  name: "Dashboard",
  data() {
    return {
      step: 1,
      busy: false,
      answers: [],
      reminders: [],
      test: {
        mails: {
          origin: "",
          reminder: {
            subjectNL: "",
            messageNL: "",
            subjectEN: "",
            messageEN: "",
            subjectFR: "",
            messageFR: ""
          }
        }
      },
      user: {}
    };
  },
  methods: {
    async submit() {
      if (this.busy) return;
      this.busy = true;
      // make sure the changed reminder text is saved
      await api.put(`/tests/${this.$route.params.id}`, this.test);
      // trigger the mails
      await api.get(
        `/tests/${
          this.$route.params.id
        }/mails/reminder?reminderMails=${encodeURIComponent(
          this.reminders.join(",")
        )}`,
        {}
      );
      alert("Reminders sent!");
      this.$router.go(-1);
      this.busy = false;
    },
    toggleReminder(answer) {
      if (this.reminders.includes(answer.email))
        this.reminders = this.reminders.filter(el => el != answer.email);
      else this.reminders.push(answer.email);
    },
    nextStep() {
      if (this.validForm) this.step += 1;
      console.log(this.step);
    },
    prevStep() {
      if (this.step > 0) this.step -= 1;
    }
  },
  computed: {
    validForm() {
      const reminderMails = this.test.mails.reminder;
      return [("NL", "EN", "FR")].every(
        lang =>
          reminderMails["message" + lang] &&
          reminderMails["message" + lang].length > 0 &&
          reminderMails["subject" + lang] &&
          reminderMails["subject" + lang].length > 0
      );
    }
  },
  async mounted() {
    this.user = await auth.me().catch(() => {});
    this.test = await api.get(`/tests/${this.$route.params.id}`);
    this.answers = (await api.get(`/tests/${this.$route.params.id}`)).answers;
    this.reminders = this.answers
      .filter(el => !el.complete)
      .map(el => el.email);
  }
};
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.4s ease;
}

.slide-fade-leave-active {
  transition: all 0.4s ease;
}

.slide-fade-enter,
  .slide-fade-leave-to

  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}
</style>
