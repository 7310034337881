<template>
  <div>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div v-if="popups.delete" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div @click="popups.delete = false" class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <div class="sm:flex sm:items-start">
            <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <!-- Heroicon name: outline/exclamation -->
              <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
            </div>
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                Revoke email?
              </h3>
              <div class="mt-2">
                <p class="text-sm text-gray-500">
                  Are you sure you want to completely remove this email from the
                  database? The email will be permanently removed from our
                  servers forever. This action cannot be undone!
                </p>
              </div>
            </div>
          </div>
          <div class="mt-4 flex space-x-4 justify-center w-full text-gray-500">
            <p class="font-bold">Revoke from:</p>
            <div class="flex space-x-2 items-center">
              <label>Tests:</label>
              <input v-model="deleteFrom.tests" type="checkbox" />
            </div>
            <div class="flex space-x-2 items-center">
              <label>Companies:</label>
              <input v-model="deleteFrom.companies" type="checkbox" />
            </div>
            <div class="flex space-x-2 items-center">
              <label>Answers:</label>
              <input v-model="deleteFrom.answers" type="checkbox" />
            </div>
          </div>
          <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <button @click="confirmRemove" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
              Revoke
            </button>
            <button @click="popups.delete = false" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full min-h-screen z-0">
      <div class="container mx-auto flex flex-col p-10 z-10 h-full pb-32">
        <div class="flex flex-row items-center justify-between">
          <div>
            <h3 class="font-semibold text-3xl text-gray-800">
              {{ data.length }} Unique Emails
              <span v-if="query.q.length > 0">for: {{ query.q }}</span>
            </h3>
          </div>
        </div>

        <div class="w-full flex justify-end mt-8">
          <div class="max-w-lg w-full lg:max-w-xs">
            <label for="search" class="sr-only">Search</label>
            <div class="relative">
              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                </svg>
              </div>
              <input v-model="query.q" class="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="Search" type="search" />
            </div>
          </div>
        </div>

        <div class="mt-4">
          <!-- This example requires Tailwind CSS v2.0+ -->
          <div class="flex flex-col">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50">
                      <tr>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          #
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Email
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" style="width: 2%"></th>
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                      <tr class="cursor-pointer hover:bg-gray-200" v-for="(email, index) in data" :key="index">
                        <td class="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                          {{ index + 1 }}
                        </td>
                        <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                          {{ email }}
                        </td>
                        <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500 capitalize">
                          <div @click="remove(email)" class="btn-danger">
                            Delete
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import auth from "@/helpers/auth";
import api from "@/helpers/api";

export default {
  name: "Dashboard",
  data: () => ({
    user: {},
    data: [],
    deleteFrom: {
      tests: true,
      companies: true,
      answers: true
    },
    popups: {
      delete: false
    },
    mailToDelete: "",
    query: {
      q: ""
    },
    debounceTimeout: undefined
  }),
  methods: {
    async load() {
      const query = Object.keys(this.query).reduce(
        (query, key) => (query = `${query}&${key}=${this.query[key]}`),
        "?"
      );
      this.data = await api.get(`/gdpr${query}`);
    },
    remove(email) {
      this.popups.delete = true;
      this.mailToDelete = email;
    },
    async confirmRemove(showAlert = true) {
      const query = Object.keys(this.deleteFrom).reduce(
        (query, key) => (query = `${query}&${key}=${this.deleteFrom[key]}`),
        "?"
      );
      this.popups.delete = false;
      await api.delete(`/gdpr/${this.mailToDelete}${query}`);
      await this.load();
      this.mailToDelete = "";
      this.deleteFrom = {
        tests: true,
        companies: true,
        answers: true
      };
      if (showAlert) alert("mail revoked!");
    }
  },
  watch: {
    "query.q"() {
      if (this.debounceTimeout) clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => this.load(), 500);
    }
  },
  async mounted() {
    this.user = await auth.me().catch(function(e) {
      console.log("NOT LOGGEDIN", e);
    });
    this.load();
  }
};
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.4s ease;
}

.slide-fade-leave-active {
  transition: all 0.4s ease;
}

.slide-fade-enter,
  .slide-fade-leave-to

  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}
</style>
