<template>
  <div>
    <div class="w-full min-h-screen z-0 flex flex-col items-center bg-background">
      <div class="sm:overflow-x-hidden w-full sm:w-10/12 space-y-3 flex flex-col px-10 z-10 h-full pb-32">
        <div class="my-16 flex flex-col justify-between">
          <h3 class="font-semibold text-3xl">
            {{ data.length }} test{{ data.length > 1 ? "s" : "" }}
          </h3>
          <p>
            Currently you and your team has done {{ data.length }} test{{
              data.length > 1 ? "s" : ""
            }}. You'll find all the information below.
          </p>
        </div>

        <!--FILTER-->
        <div class="flex flex-col md:flex-row items-center space-x-3 p-4 bg-gray-50 shadow-md rounded-md">

          <div class="flex space-x-3 md:mx-4">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <title>Filter</title>
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z" />
            </svg>
            <svg @click="clearFilter" xmlns="http://www.w3.org/2000/svg" :class="{
                'opacity-50':
                  query.company == 'any' &&
                  query.survey == 'any' &&
                  query.active == 'any',
              }" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <title>Clear</title>
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
            </svg>
          </div>

          <div class="flex-1 w-full">
            <datalist id="autocomplete">
              <option :value="option.name" v-for="option in companies" :key="option._id"></option>
            </datalist>
            <label class="block text-sm font-medium text-gray-800">Company</label>
            <input type="text" list="autocomplete" v-model="query.company" class="w-full border-transparent rounded mt-1 shadow-sm bg-gray-200" />
          </div>

          <div class="flex-1 w-full">
            <label class="block text-sm font-medium text-gray-800">Surveys</label>
            <select v-model="query.survey" class="w-full border-transparent rounded mt-1 shadow-sm bg-gray-200">
              <option value="any">All</option>
              <option v-for="survey in surveys" :key="survey._id" :value="survey._id">
                {{ survey.name }}
              </option>
            </select>
          </div>
          <div class="flex-1 w-full">
            <label class="block text-sm font-medium text-gray-800">Active</label>
            <select v-model="query.active" class="w-full border-transparent rounded mt-1 shadow-sm bg-gray-200">
              <option value="any">All</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
        </div>

        <!--TABLE-->
        <div class="flex flex-col">
          <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div class="shadow-xl overflow-hidden border-b border-gray-200 sm:rounded-md mb-10">
                <div class="w-full divide-y divide-gray-100 overflow-hidden">
                  <div class="bg-gray-50">
                    <div class="flex flex-row justify-start items-center">
                      <div class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-4/12">
                        Company
                      </div>
                      <div class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-2/12">
                        Surveys
                      </div>
                      <div class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/12">
                        Active
                      </div>
                      <div class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/12">
                        Start
                      </div>
                      <div class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/12">
                        Deadline
                      </div>
                      <div class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-2/12">
                        Status
                      </div>
                      <div class="relative px-6 py-3 w-1/12">
                        <span class="sr-only">Edit</span>
                      </div>
                    </div>
                  </div>
                  <div class="bg-white divide-y divide-gray-100">
                    <!-- cursor-pointer hover:bg-gray-50 -->
                    <div @click="$router.push('/results/' + res._id)" class="hover:bg-gray-100 cursor-pointer transition duration-200 flex flex-row justify-between items-center" v-for="res in data" v-bind:key="res._id">

                      <div class="flex flex-row items-center justify-start px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 overflow-hidden w-4/12">
                        <div class="w-10 h-10 rounded-full mr-3 flex items-center justify-center uppercase text-gray-100 font-bold text-lg" :class="{'bg-gray-400': !(res && res.company && res.company.logo && res.company.logo.length > 0) }">
                          <img v-if="res && res.company && res.company.logo && res.company.logo.length > 0" :src="res.company.logo" alt="">
                          <div v-else-if="res && res.company">
                            {{ getFirstLetters(res.company.name) }}
                          </div>
                          <div v-else>??</div>
                        </div>
                        <div>
                          <div v-if="res && res.company" class="font-semibold text-gray-800 w-full truncate">
                            {{ res.company.name }}
                          </div>
                          <span class="whitespace-nowrap text-sm text-gray-300">ID: {{ res._id }}</span>
                        </div>
                      </div>

                      <div class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 flex flex-row w-2/12">
                        {{
                          ellipsize(
                            res.surveys
                              .reduce(
                                (acc, curr) => (acc += `${curr.name}, `),
                                ""
                              )
                              .replace(/, $/, ""),
                            30
                          )
                        }}
                      </div>

                      <div class="py-4 flex justify-center whitespace-nowrap text-sm text-gray-500 w-1/12">
                        <div :class="{
                            'bg-green-500':
                              res.remindersSent &&
                              res.remindersSent.includes('start') &&
                              (res.endDate
                                ? new Date(
                                    new Date(res.endDate).getTime() +
                                      24 * 60 * 60 * 1000
                                  )
                                : new Date(1, 1, 1)) >= new Date(),
                          }" class="w-4 h-4 rounded-full bg-red-500"></div>
                      </div>

                      <div class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 w-1/12">
                        {{
                          moment(res.startDate || res.createdAt).format(
                            "DD/MM/YY"
                          )
                        }}
                      </div>

                      <div class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 w-1/12">
                        {{
                          (res.endDate &&
                            moment(res.endDate).format("DD/MM/YY")) ||
                          "--/--/----"
                        }}
                      </div>

                      <div class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 w-2/12">
                        {{res.totalFinished}}/{{res.totalAnswers}}
                      </div>

                      <div class="mr-4 w-1/12 flex justify-end">
                        <button v-if="user.isGod" @click.stop="remove(res)" class="btn-danger">
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import auth from "@/helpers/auth";
import api from "@/helpers/api";

export default {
  name: "Dashboard",
  data() {
    return {
      user: {},
      data: [],
      companies: [],
      surveys: [],
      query: {
        company: "any",
        survey: "any",
        active: "any"
      },
      debounceTimer: undefined,
      companyQuery: ""
    };
  },
  methods: {
    updateQuery() {
      if (this.debounceTimer) clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(this.setCompanyQuery, 500);
    },
    setCompanyQuery() {
      console.log("set company");
      const companyRe = new RegExp(this.companyQuery);
      const company = this.companies.find(el => companyRe.test(el.name));
      if (!company || !company._id) return;
      this.$set(this.query, "company", company._id);
    },
    ellipsize(str, max) {
      if (str.length < max) return str;
      return str.substring(0, max) + "...";
    },
    getFirstLetters(el) {
      var res = String(el);
      return res.substring(0, 2);
    },
    clearFilter() {
      this.query = {
        company: "any",
        survey: "any",
        active: "any"
      };
    },
    async load() {
      const query = Object.keys(this.query).reduce(
        (query, key) => (query = `${query}&${key}=${this.query[key]}`),
        "?"
      );
      this.data = (await api.get(`/tests${query}`)).data;
      this.companies = (await api.get("/companies")).data;
      if (this.user.isAdmin) this.surveys = (await api.get("/surveys")).data;
    },
    async remove(res) {
      if (
        confirm(
          "Are you sure you want to delete this test and all its results?"
        )
      ) {
        await api.delete(`/tests/${res._id}`);
        await this.load();
      }
    }
  },
  watch: {
    "query.company": async function() {
      return this.load();
    },
    "query.survey": async function() {
      return this.load();
    },
    "query.active": async function() {
      return this.load();
    }
  },
  async mounted() {
    this.user = await auth.me().catch(function(e) {
      console.log("NOT LOGGEDIN", e);
    });

    await this.load();
  }
};
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.4s ease;
}

.slide-fade-leave-active {
  transition: all 0.4s ease;
}

.slide-fade-enter,
  .slide-fade-leave-to

  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}
</style>
