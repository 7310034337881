<script>
import { Pie, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Pie,
  mixins: [reactiveProp],
  props: ["options"],
  mounted() {
    this.addPlugin({
      id: "bg-plugin",
      beforeDraw: function(chart) {
        const x = chart.scales["x-axis-0"];
        const y = chart.scales["y-axis-0"];
        // abstraction of the canvas size to make it easyer to work with
        const canvas = {
          x: { start: x.margins.left, end: x.width },
          y: { start: y.margins.top, end: y.height, height: y.height }
        };

        const ctx = chart.canvas.getContext("2d");
        ctx.save();
        ctx.globalCompositeOperation = "destination-over";
        const colors = ["#72E24C", "#F0E34B", "#F0AF4B", "#F0794B"];
        const sizes = [2, 2.5, 2.5, 2];
        let lastHeight = 0;
        for (const i in colors) {
          console.log(sizes[i] * (canvas.y.height / 9));
          ctx.fillStyle = colors[i];
          ctx.fillRect(
            canvas.x.start,
            canvas.y.start,
            canvas.x.end,
            lastHeight + (canvas.y.height / 9) * sizes[i]
          );
          lastHeight = lastHeight + (canvas.y.height / 9) * sizes[i];
        }
        ctx.restore();
      }
    });

    this.renderChart(this.chartData, this.options);
  }
};
</script>
