<template>
  <div class="w-screen min-h-screen z-0">

    <div v-if="page.indexOf('/surveys/') == -1 && page.indexOf('/companies/') == -1 && page.indexOf('/emails/') == -1 && page.indexOf('/texts/') == -1" class="border-b border-tertiary relative h-14 bg-tertiary flex flex-row items-center w-screen">
      <div class="w-10/12 h-14 flex flex-row mx-auto">

        <router-link v-if="user.isGod" to="/admin/surveys" class="text-sm h-full flex flex-col justify-center w-24 cursor-pointer border-b-2 border-transparent	text-center" :class="page == '/admin/surveys' ? 'border-branding': ''">
          <div>Surveys</div>
        </router-link>

        <router-link to="/admin/companies" class="text-sm h-full flex flex-col justify-center w-24 cursor-pointer border-b-2 border-transparent	text-center" :class="page == '/admin/companies' ? 'border-branding': ''">
          <div>Companies</div>
        </router-link>

        <router-link v-if="user.isGod" to="/admin/texts" class="text-sm h-full flex flex-col justify-center w-24 cursor-pointer border-b-2 border-transparent	text-center" :class="page == '/admin/texts' ? 'border-branding': ''">
          <div>Texts</div>
        </router-link>

        <router-link v-if="user.isGod" to="/admin/emails" class="text-sm h-full flex flex-col justify-center w-24 cursor-pointer border-b-2 border-transparent	text-center" :class="page == '/admin/emails' ? 'border-branding': ''">
          <div>Emails</div>
        </router-link>

        <router-link v-if="user.isGod" to="/admin/gdpr" class="text-sm h-full flex flex-col justify-center w-24 cursor-pointer border-b-2 border-transparent	text-center" :class="page == '/admin/gdpr' ? 'border-branding': ''">
          <div>GDPR</div>
        </router-link>

      </div>
    </div>
    <router-view class="flex-1 overflow-y-auto focus:outline-none" tabindex="0">
    </router-view>
  </div>
</template>

<script>
import auth from "@/helpers/auth";

export default {
  name: "Dashboard",
  data() {
    return {
      page: "",
      user: {}
    };
  },
  methods: {},
  watch: {
    $route: function(prev, current) {
      console.log(current);
      this.page = this.$route.path;
    }
  },
  async mounted() {
    this.user = await auth.me().catch(() => {});

    // TODO: validate admin
    if (!this.user.isAdmin && !this.user.isGod) this.$router.push("/results");

    this.page = this.$route.path;
  }
};
</script>

<style>
.router-link-exact-active {
  background-color: transparent;
}
.router-link-active {
  background-color: transparent;
}

.slide-fade-enter-active {
  transition: all 0.4s ease;
}

.slide-fade-leave-active {
  transition: all 0.4s ease;
}

.slide-fade-enter,
  .slide-fade-leave-to

  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}
</style>
